import Cookies from 'js-cookie'
import { UtilitiesInstance, catchError } from '@/store'
import { setCookieDash, updateCookieDash } from '@/support'

const nps = {
  state: {
    showNps: false
  },
  mutations: {
    SET_NPS (state, showNps) {
      state.showNps = showNps
    }
  },
  getters: {
    showNps (state) {
      return state.showNps
    }
  },
  actions: {
    GET_NPS (context) {
      const envAcronym = UtilitiesInstance.envAcronym
      let cookieDash = Cookies.get(`${envAcronym}mp_dash`)
      if (cookieDash) {
        Promise.resolve(atob(cookieDash))
          .then(JSON.parse)
          .then(resCookieParse => {
            context.commit('toggleGlobalLoading', false)
            cookieDash = resCookieParse
            if (cookieDash.nps && Object.entries(cookieDash.nps).length) {
              if (cookieDash.nps.available) context.dispatch('VIEW_NPS')
              context.commit('SET_NPS', cookieDash.nps.available)
            } else {
              return UtilitiesInstance.nps
                .getMe()
                .then(res => {
                  if (res.available) context.dispatch('VIEW_NPS')
                  context.commit('SET_NPS', res.available)

                  setCookieDash({ nps: res })
                })
                .catch(err => catchError(err, context))
            }
          })
      } else {
        return UtilitiesInstance.nps
          .getMe()
          .then(res => {
            context.commit('toggleGlobalLoading', false)
            if (res.available) context.dispatch('VIEW_NPS')
            context.commit('SET_NPS', res.available)
            setCookieDash({ nps: res })
          })
          .catch(err => catchError(err, context))
      }
    },
    SET_ANSWER_NPS (context, body) {
      return UtilitiesInstance.nps
        .setAnswer(body)
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    VIEW_NPS (context) {
      return UtilitiesInstance.nps
        .view()
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    TOGGLE_NPS (context) {
      updateCookieDash('nps')
      context.commit('SET_NPS', !context.state.showNps)
    }
  }
}

export default nps
