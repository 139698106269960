import { DashInstance, catchError } from '@/store'
import { isNotFound } from '@/enums/http-status.enum'

const terminals = {
  state: {
    terminals: { content: [], pagination: {} },
    terminal: {},
    loadingList: false
  },
  mutations: {
    TOGGLE_LOADING_LIST (state, status) {
      state.loadingList = status
    },
    SET_TERMINALS (state, terminals) {
      state.terminals = {
        content: terminals?.items || [],
        pagination: terminals?.pagination
      }
    },
    SET_TERMINAL (state, terminal) {
      state.terminal = terminal
    }
  },
  getters: {
    terminals (state) {
      return state.terminals
    },
    terminal (state) {
      return state.terminal
    },
    loadingListTerminals (state) {
      return state.loadingList
    }
  },
  actions: {
    GET_TERMINALS (context, params = {}) {
      context.commit('TOGGLE_LOADING_LIST', true)

      params.query = { page: 1, ...params, size: 10 }

      if (params?.query?.serialNumber) {
        return DashInstance.account
          .getTerminalBySerialNumber(
            context.getters.merchantId,
            context.getters.accountId,
            params.query.serialNumber
          )
          .then(res => {
            return context.commit('SET_TERMINALS', {
              items: [res],
              pagination: {
                current_page: 1,
                total_page: 1
              }
            })
          })
          .catch(err => {
            if (isNotFound(err.status)) {
              context.commit('SET_TERMINALS', { content: [] })
              return Promise.resolve([])
            }
            catchError(err, context)
          })
          .finally(() => {
            return context.commit('TOGGLE_LOADING_LIST', false)
          })
      }

      return DashInstance.account
        .getTerminalsV2(
          context.getters.merchantId,
          context.getters.accountId,
          params.query
        )
        .then(res => {
          context.commit('SET_TERMINALS', res)
          return Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
        .finally(() => {
          return context.commit('TOGGLE_LOADING_LIST', false)
        })
    },
    FILTER_TERMINAL_LIST_BY_SERIAL_NUMBER (context, params = {}) {
      context.commit('TOGGLE_LOADING_LIST', true)
      return DashInstance.account
        .getTerminalBySerialNumber(
          context.getters.merchantId,
          context.getters.accountId,
          params.query.serialNumber
        )
        .then(res => {
          return context.commit('SET_TERMINALS', { content: [res] })
        })
        .catch(err => {
          // TODO: remove this code after the acceptance treating this error
          if (isNotFound(err.status)) {
            context.commit('SET_TERMINALS', { content: [] })
            return Promise.resolve([])
          }
          // TODO: remove this code after the acceptance treating this error
          catchError(err, context)
        })
        .finally(() => context.commit('TOGGLE_LOADING_LIST', false))
    },
    GET_TERMINAL_BY_SERIAL_NUMBER (context, params = {}) {
      return DashInstance.account
        .getTerminalBySerialNumber(
          context.getters.merchantId,
          context.getters.accountId,
          params.query.serialNumber
        )
        .then(res => {
          context.commit('SET_TERMINAL', res)
          return Promise.resolve(res)
        })
        .catch(err => {
          catchError(err, context)
        })
    },
    TOGGLE_LOADING_LIST ({ commit }, status) {
      commit('TOGGLE_LOADING_LIST', status)
    }
  }
}

export default terminals
