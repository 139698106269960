import { isPsP } from '../enums/business-model.enum'

export const RECIPIENTS_ACTIONS_ENUM = {
  search: 'recipients:search',
  get: 'recipients:get',
  create: 'recipients:create',
  view: 'recipients:view',
  edit: 'recipients:edit'
}

const recipientsViewEnabled = (
  permissionIsEnabledFn,
  componentIsEnabledFn,
  businessModel
) => {
  return (
    isPsP(businessModel) &&
    permissionIsEnabledFn(RECIPIENTS_ACTIONS_ENUM.view) &&
    componentIsEnabledFn('sidemenu', 'recipients')
  )
}

const recipientsSearchEnabled = (
  permissionIsEnabledFn,
  componentIsEnabledFn
) => {
  return (
    permissionIsEnabledFn(RECIPIENTS_ACTIONS_ENUM.search) &&
    componentIsEnabledFn('sidemenu', 'recipients')
  )
}

const recipientsGetEnabled = (permissionIsEnabledFn, componentIsEnabledFn) => {
  return (
    permissionIsEnabledFn(RECIPIENTS_ACTIONS_ENUM.get) &&
    componentIsEnabledFn('sidemenu', 'recipients')
  )
}

const recipientsCreateEnabled = (
  permissionIsEnabledFn,
  componentIsEnabledFn
) => {
  return (
    permissionIsEnabledFn(RECIPIENTS_ACTIONS_ENUM.create) &&
    componentIsEnabledFn('sidemenu', 'recipients')
  )
}

const recipientsEditEnabled = (permissionIsEnabledFn, componentIsEnabledFn) => {
  return (
    permissionIsEnabledFn(RECIPIENTS_ACTIONS_ENUM.edit) &&
    componentIsEnabledFn('sidemenu', 'recipients')
  )
}

export {
  recipientsViewEnabled,
  recipientsSearchEnabled,
  recipientsGetEnabled,
  recipientsCreateEnabled,
  recipientsEditEnabled
}
