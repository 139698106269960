import { DashInstance, catchError } from '@/store'

const affiliationsSettings = {
  state: {
    acquirerAffiliations: [],
    affiliationsLoading: false
  },
  getters: {
    acquirerAffiliations: state => state.acquirerAffiliations,
    affiliationsLoading: state => state.affiliationsLoading
  },
  mutations: {
    SET_ACQUIRER_AFFILIATIONS (state, acquirerAffiliations) {
      state.acquirerAffiliations = acquirerAffiliations
    },
    SET_AFFILIATIONS_LOADING (state, loading) {
      state.affiliationsLoading = loading
    }
  },
  actions: {
    GET_ACQUIRER_AFFILIATIONS (context) {
      context.commit('SET_AFFILIATIONS_LOADING', true)
      return DashInstance.account
        .getAcquirerAffiliations(
          context.getters.merchantId,
          context.getters.accountId
        )
        .then(res => {
          const data = res.items || []

          context.commit('SET_ACQUIRER_AFFILIATIONS', data)
          return Promise.resolve(data)
        })
        .catch(err => catchError(err, context))
        .finally(() => context.commit('SET_AFFILIATIONS_LOADING', false))
    },
    CREATE_ACQUIRER_CONSENT (context, params = {}) {
      return DashInstance.account
        .createAcquirerConsent(
          context.getters.merchantId,
          context.getters.accountId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    CONFIRM_ACQUIRER_CONSENT (context, params = {}) {
      return DashInstance.account
        .confirmAcquirerConsent(
          context.getters.merchantId,
          context.getters.accountId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_ACQUIRER_AFFILIATION (context, params = {}) {
      return DashInstance.account
        .updateAcquirerAffiliationStatus(
          context.getters.merchantId,
          context.getters.accountId,
          params.affiliationId,
          params.body
        )
        .then(() => Promise.resolve())
        .catch(err => catchError(err, context))
    }
  }
}

export default affiliationsSettings
