var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("emd-modal", {
    attrs: { size: "xlarge", open: "", scrollable: "" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _vm.isLoading
              ? _c("emd-loader", {
                  staticClass: "eu-align_center eu-align_center",
                  attrs: { type: "_subtle" },
                })
              : _c(
                  "div",
                  {
                    staticClass:
                      "eu-column eu-row_md eu-flex eu-justify_center eu-py_6 eu-px_6",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "eu-flex-item eu-flex eu-justify_center" },
                      [
                        _vm.isModalTextEqualStatus
                          ? _c("img", {
                              staticClass: "img-status",
                              attrs: {
                                src: _vm.statusScreen,
                                alt: "status-screen",
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: _vm.onboardingScreen,
                                alt: "onboarding-screen",
                              },
                            }),
                      ]
                    ),
                    _c("div", { staticClass: "eu-flex-item eu-ml_5_md" }, [
                      _c("h1", { staticClass: "emd-text _size_page eu-mb_5" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                `${_vm.modalTextType}-pending.${_vm.modalContentType}-test-account.title`
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "emd-text _size_body eu-mb_7" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                `${_vm.modalTextType}-pending.${_vm.modalContentType}-test-account.content.${_vm.translationType[0]}`,
                                {
                                  [_vm.placeName]:
                                    _vm.showAccountNameIfIsOnboarding,
                                }
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm.isTypeStatusAndHasTestAccounts ||
                      _vm.isTypeOnboardingAndHasTestAccounts
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "emd-text _size_body eu-mt_5 warning",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      `${_vm.modalTextType}-pending.${_vm.modalContentType}-test-account.content.${_vm.translationType[1]}`
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isTypeStatusAndHasTestAccounts
                        ? _c("p", { staticClass: "emd-text _size_body" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    `${_vm.modalTextType}-pending.${_vm.modalContentType}-test-account.content.${_vm.translationType[2]}`
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "eu-flex eu-mt_5" }, [
                        _c(
                          "button",
                          {
                            staticClass: "emd-btn _color_primary",
                            on: { click: _vm.redirectToUrl },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    `${_vm.modalTextType}-pending.${_vm.modalContentType}-test-account.button`
                                  )
                                ) +
                                " "
                            ),
                            _c("i", { staticClass: "uil uil-arrow-right" }),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }