import { isPsP } from '@/enums/business-model.enum.js'
import { isProductionAccount } from '@/enums/account-types.enum.js'
import { someActionsPermissionIsEnabled } from '.'

export const SETTINGS_ACCOUNT_ACTIONS_ENUM = {
  accountGet: 'settings.account:get',
  dataView: 'settings.account.data:view',
  generalDataView: 'settings.account.general_data:view',
  walletView: 'settings.account.wallet:view',
  feesView: 'settings.account.fees:view'
}

const SETTINGS_ACCOUNT_ACTIONS = [
  SETTINGS_ACCOUNT_ACTIONS_ENUM.dataView,
  SETTINGS_ACCOUNT_ACTIONS_ENUM.generalDataView,
  SETTINGS_ACCOUNT_ACTIONS_ENUM.walletView
]

// showAccountConfig
const isSettingsAccountEnabled = permissionIsEnabledFn => {
  return someActionsPermissionIsEnabled(
    SETTINGS_ACCOUNT_ACTIONS,
    permissionIsEnabledFn
  )
}

const isSettingsAccountDataEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_ACCOUNT_ACTIONS_ENUM.dataView)
}

const isSettingsAccountGeneralDataEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_ACCOUNT_ACTIONS_ENUM.generalDataView)
}

const isSettingsAccountWalletEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_ACCOUNT_ACTIONS_ENUM.walletView)
}

const isSettingsAccountGetEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_ACCOUNT_ACTIONS_ENUM.accountGet)
}

const isSettingsFeesEnabled = (
  permissionIsEnabledFn,
  businessModelFn,
  accounType
) => {
  return (
    permissionIsEnabledFn(SETTINGS_ACCOUNT_ACTIONS_ENUM.feesView) &&
    isPsP(businessModelFn) &&
    isProductionAccount(accounType)
  )
}

export {
  isSettingsAccountEnabled,
  isSettingsAccountDataEnabled,
  isSettingsAccountGeneralDataEnabled,
  isSettingsAccountWalletEnabled,
  isSettingsAccountGetEnabled,
  isSettingsFeesEnabled
}
