import { ScopeInstance, catchError } from '@/store'

const invite = {
  state: {
    invites: { items: [] },
    loadingList: true
  },
  getters: {
    invites (state) {
      return state.invites
    },
    loadingListInvites (state) {
      return state.loadingList
    }
  },
  mutations: {
    toggleLoadingList (state, status) {
      state.loadingList = status
    },
    SET_INVITES (state, invites) {
      state.invites = invites
    }
  },
  actions: {
    GET_INVITES (context, params = {}) {
      const { merchantId, accountId } = context.getters

      context.commit('toggleLoadingList', true)

      params = params.query ? { ...params.query } : params

      return ScopeInstance.invite
        .getAll({
          ...params,
          merchantId,
          accountId,
          inviteId: ''
        })
        .then(res => {
          context.commit('toggleLoadingList', false)
          context.commit('SET_INVITES', res.data)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return catchError(err, context)
        })
    },
    GET_INVITE (context, params = {}) {
      const { merchantId, accountId } = context.getters

      return ScopeInstance.invite
        .getById({
          merchantId,
          accountId,
          inviteId: params.inviteId
        })
        .then(res => {
          context.commit('SET_INVITES', res.data)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return catchError(err, context)
        })
    },
    CREATE_INVITE (context, params = {}) {
      const { merchantId, accountId } = context.getters
      return ScopeInstance.invite
        .createAsAccount(
          {
            merchantId,
            accountId
          },
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    RESEND_INVITE (context, params = {}) {
      const { merchantId, accountId } = context.getters
      const { inviteId } = params
      return ScopeInstance.invite
        .resendAsAccount(
          {
            merchantId,
            accountId,
            inviteId
          },
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    CANCEL_INVITE (context, params = {}) {
      const { merchantId, accountId } = context.getters

      const { inviteId } = params
      return ScopeInstance.invite
        .cancelAsAccount(
          {
            merchantId,
            accountId,
            inviteId
          },
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    toggleLoadingList ({ commit }, status) {
      commit('toggleLoadingList', status)
    }
  }
}

export default invite
