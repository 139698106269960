import store from '@/store'
import balanceEnabled from '../actions-permission/balance.permissions'

const commonTransfersBeforeEnter = next => {
  if (
    !balanceEnabled(
      store.getters.permissionIsEnabled,
      store.getters.componentIsEnabled,
      store.getters.businessModel
    )
  ) {
    return store.commit('toggleGlobalError', { status: true, code: 404 })
  }
  next()
}

const routeReceivablesRegister = store => ({
  path: 'receivables-register/:pg_recipient_id?',
  name: 'receivables-register',
  component: () =>
    import(
      /* webpackChunkName: "ReceivablesRegister" */ '@/views/ReceivablesRegister/index.vue'
    ),
  beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next),
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      current: 'breadcrumb.receivables-register.root'
    }
  }
})

const routeReceivablesRegisterDay = store => ({
  path: 'receivables-register/:recipientId/:day',
  name: 'receivables-register-day',
  component: () =>
    import(
      /* webpackChunkName: "ReceivablesRegister" */ '@/views/ReceivablesRegister/index.vue'
    ),
  beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next),
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      current: 'breadcrumb.receivables-register.day'
    }
  }
})

const routeReceivablesRegisterDayArrangement = store => ({
  path: 'receivables-register/:recipientId/:day/:arrangement',
  name: 'receivables-register-arragenment',
  component: () =>
    import(
      /* webpackChunkName: "ReceivablesRegister" */ '@/views/ReceivablesRegister/index.vue'
    ),
  beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next),
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      current: 'breadcrumb.receivables-register.arrangement'
    }
  }
})

export default [
  routeReceivablesRegister,
  routeReceivablesRegisterDay,
  routeReceivablesRegisterDayArrangement
]
