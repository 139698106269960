const routeLogs = store => ({
  path: 'logs',
  name: 'logs',
  component: () => import('@/views/Account/Logs.vue'),
  beforeEnter: (to, from, next) => {
    if (!store.getters.permissionIsEnabled('action_log:search')) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.team.root',
      current: 'breadcrumb.team.logs'
    }
  }
})

export default routeLogs
