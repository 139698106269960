export const TIMELINE_STEPS_ENUM = {
  ACTIVE: 'active',
  FINISHED: 'finished',
  DISABLED: 'disabled',
  NONE: ''
}

export const isStepTimelineActivationActive = step => {
  return step.toLowerCase() === TIMELINE_STEPS_ENUM.ACTIVE.toLowerCase()
}

export const isStepTimelineActivationFinished = step => {
  return step.toLowerCase() === TIMELINE_STEPS_ENUM.FINISHED.toLowerCase()
}
export const enableGetChargesPaid = (step = '') => {
  return [
    TIMELINE_STEPS_ENUM.NONE,
    TIMELINE_STEPS_ENUM.ACTIVE,
    TIMELINE_STEPS_ENUM.FINISHED
  ].includes(step.toLowerCase())
}

export const isValidStepTimelineActivation = step => {
  return Object.values(TIMELINE_STEPS_ENUM).includes(step.toLowerCase())
}

export default TIMELINE_STEPS_ENUM
