import { isSettingsGatewaysEnabled } from '@/actions-permission/settings-gateways.permission'

const routeGateways = store => ({
  path: 'gateways',
  name: 'gateways',
  component: () =>
    import(/* webpackChunkName: "gateways" */ '@/views/Settings/Gateways.vue'),
  beforeEnter: (to, from, next) => {
    if (!isSettingsGatewaysEnabled(store.getters.permissionIsEnabled)) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.settings.root',
      current: 'breadcrumb.settings.gateways'
    }
  }
})

export default routeGateways
