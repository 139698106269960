import { isKeysEnabled } from '@/actions-permission/keys.permissions'

const routeKeys = store => ({
  path: 'keys',
  name: 'keys',
  component: () => import('@/views/Account/Keys/index.vue'),
  beforeEnter: (to, from, next) => {
    if (!isKeysEnabled(store.getters.permissionIsEnabled)) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.settings.root',
      current: 'breadcrumb.settings.keys'
    }
  }
})

export default routeKeys
