import insightsMerchantEnabled from '@/actions-permission/insights.permissions'
import { EXTERNAL_ENDPOINTS } from '@/external-endpoints'
import { isAnalystRole, isDeveloperRole } from '@/enums/user-role.enum'

const routeDefault = store => ({
  path: '/',
  name: 'default',
  component: () => import('@/components/project/templates/Motherboard.vue'),
  beforeEnter: (to, from, next) => {
    if (store.getters.maintenance) {
      next({ name: 'maintenance' })
      return
    }
    const { code, state: meta } = to.query

    if (code && meta) {
      const [acquirer, merchantId, accountId, state] = meta.split('-')

      next({
        path: `/${merchantId}/${accountId}/settings/affiliations?code=${code}&state=${state}&acquirer=${acquirer}`
      })
      return
    }

    store.dispatch('GET_AUTHORIZATION', {}).then(({ merchant }) => {
      if (!merchant) {
        store.commit('toggleGlobalError', {
          status: true,
          code: 404
        })
        return
      }

      if (store.getters.isEmployee) {
        window.location.assign(process.env.VUE_APP_BACKOFFICE_URL)
        return
      }

      store.dispatch('GET_MERCHANT_SUMMARY', merchant.id).then(() => {
        store.commit('toggleGlobalLoading', false)
        const { permissionIsEnabled, componentIsEnabled } = store.getters

        if (insightsMerchantEnabled(permissionIsEnabled, componentIsEnabled)) {
          next({
            path: `/${merchant.id}/${merchant.account.id}`
          })
          return
        }

        const isRedirectCharges =
          isDeveloperRole(store.getters.userRole.key) ||
          isAnalystRole(store.getters.userRole.key)
        if (isRedirectCharges && permissionIsEnabled('charge:search')) {
          next({
            path: `/${merchant.id}/${merchant.account.id}/charges`
          })
          return
        }

        if (permissionIsEnabled('order:search')) {
          next({
            path: `/${merchant.id}/${merchant.account.id}/orders`
          })
          return
        }

        // seller
        if (permissionIsEnabled('shop.checkout:create')) {
          window.location.assign(
            EXTERNAL_ENDPOINTS.SHOP.url(merchant.id, merchant.account.id)
          )
          return
        }

        store.commit('toggleGlobalError', {
          status: true,
          code: 404
        })
      })
    })
  }
})

export default routeDefault
