import { isPsP } from '../enums/business-model.enum'

export const BALANCE_ACTIONS_ENUM = {
  view: 'balance:view'
}

const balanceEnabled = (
  permissionIsEnabledFn,
  componentIsEnabledFn,
  businessModel
) => {
  return (
    componentIsEnabledFn('sidemenu', 'balance') &&
    permissionIsEnabledFn(BALANCE_ACTIONS_ENUM.view) &&
    isPsP(businessModel)
  )
}

export default balanceEnabled
