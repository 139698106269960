import {
  isPending,
  isApproved,
  isDenied,
  isDeniedByRisk,
  isPartiallyDenied,
  KYC_STATUS_ENUM,
  KYC_ERROR_MESSAGES
} from '@/enums/kyc.enum'

import { isOwner, isOwnerOrAdmin } from '@/enums/merchant-type.enum'

class KycManager {
  lastKyc
  merchantType
  isOnboardingPending

  constructor ({ kycList, onboarding, merchantType }) {
    if (!kycList.length) throw new Error('Kyc array empty')
    this.lastKyc = kycList?.[0]
    this.isOnboardingPending = onboarding
    this.merchantType = merchantType
  }

  kycStatus () {
    return this.lastKyc?.kyc_status
  }

  static shouldRedirectToKyc (kycs, onboardingPending) {
    const lastKyc = kycs?.[0] || undefined

    if (!lastKyc) {
      throw new Error(KYC_ERROR_MESSAGES.KYC_NOT_FOUND)
    }

    const FEATURE_FLAG_KYC_STATUS_ENABLED = [
      KYC_STATUS_ENUM.APPROVED,
      KYC_STATUS_ENUM.DENIED,
      KYC_STATUS_ENUM.PENDING,
      KYC_STATUS_ENUM.PARTIALLY_DENIED,
      KYC_STATUS_ENUM.DENIED_BY_RISK
    ]

    if (
      !Object.values(FEATURE_FLAG_KYC_STATUS_ENABLED).includes(
        lastKyc.kyc_status
      )
    ) {
      throw new Error(KYC_ERROR_MESSAGES.KYC_STATUS_NOT_ENABLED)
    }

    const isAnyStatusWithoutApproved = !isApproved(lastKyc.kyc_status)
    const isApprovedAndOnboardingPending =
      isApproved(lastKyc.kyc_status) && !!onboardingPending

    return isAnyStatusWithoutApproved || isApprovedAndOnboardingPending
  }

  isStepperVisible () {
    return !(this.isKycInContactOwnerStatus() || this.isKycInDeniedStatus())
  }

  isStepperValidationActive () {
    return (
      this.isKycInPartiallyDeniedStatus() ||
      this.isKycInValidtaionStatus() ||
      this.isStepperPersonalizationActive()
    )
  }

  isStepperPersonalizationActive () {
    return (
      this.isKycInPersonalizationStatus() || this.isStepperConcludedActive()
    )
  }

  isStepperConcludedActive () {
    return this.isKycInConcludedStatus()
  }

  updatedAtDateTime () {
    const [date, time] = new Date(this.lastKyc.updated_at)
      .toLocaleString('pt-BR')
      .split(' ')

    return { date, time }
  }

  isKycInContactOwnerStatus () {
    const notIsOwnerAndNotInPersonalizationStatus =
      !isOwner(this.merchantType) && !this.isKycInPersonalizationStatus()

    const notIsOwnerOrAdminAndInPersonalizationStatus =
      !isOwnerOrAdmin(this.merchantType) && this.isKycInPersonalizationStatus()

    return (
      notIsOwnerAndNotInPersonalizationStatus ||
      notIsOwnerOrAdminAndInPersonalizationStatus
    )
  }

  isKycInValidtaionStatus () {
    return isPending(this.kycStatus())
  }

  isKycInPartiallyDeniedStatus () {
    return isPartiallyDenied(this.kycStatus())
  }

  isKycInPersonalizationStatus () {
    return isApproved(this.kycStatus()) && this.isOnboardingPending
  }

  isKycInConcludedStatus () {
    return isApproved(this.kycStatus()) && !this.isOnboardingPending
  }

  isKycInDeniedStatus () {
    return isDenied(this.kycStatus()) || isDeniedByRisk(this.kycStatus())
  }
}

export default KycManager
