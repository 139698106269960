const routeSubscriptions = store => ({
  path: 'subscriptions/',
  name: 'subscriptions',
  component: () =>
    import('@/views/Recurrences/Subscriptions/Subscriptions.vue'),
  beforeEnter: (to, from, next) => {
    if (
      store.getters.permissionIsEnabled('subscription:search') &&
      store.getters.componentIsEnabled('sidemenu', 'subscriptions')
    ) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.recurrence.root',
      current: 'breadcrumb.recurrence.subscriptions'
    }
  }
})

const routeSubscriptionsDetail = store => ({
  path: 'subscriptions/:id/:tab',
  name: 'subscriptionsDetail',
  component: () =>
    import(
      /* webpackChunkName: "subscriptionsdetail" */ '@/views/Recurrences/Subscriptions/SubscriptionsDetail.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (
      store.getters.permissionIsEnabled('subscription:get') &&
      store.getters.componentIsEnabled('sidemenu', 'subscriptions')
    ) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.recurrence.root',
      current: 'breadcrumb.recurrence.subscription-detail',
      parents: [
        {
          name: 'breadcrumb.recurrence.subscriptions',
          routeName: 'subscriptions'
        }
      ]
    }
  }
})

export default [routeSubscriptions, routeSubscriptionsDetail]
