const HTTP_STATUS_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  PRECONDITION_FAILED: 412,
  UNPROCESSABLE_ENTITY: 422,
  LOCKED: 423,
  PRECONDITION_REQUIRED: 428,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500
}

const isBadRequest = status => status === HTTP_STATUS_CODE.BAD_REQUEST
const isUnauthorized = status => status === HTTP_STATUS_CODE.UNAUTHORIZED
const isPaymentRequired = status => status === HTTP_STATUS_CODE.PAYMENT_REQUIRED
const isForbidden = status => status === HTTP_STATUS_CODE.FORBIDDEN
const isNotFound = status => status === HTTP_STATUS_CODE.NOT_FOUND
const isPreconditionFailed = status =>
  status === HTTP_STATUS_CODE.PRECONDITION_FAILED
const isUnprocessableEntity = status =>
  status === HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY
const isLocked = status => status === HTTP_STATUS_CODE.LOCKED
const isPreconditionRequired = status =>
  status === HTTP_STATUS_CODE.PRECONDITION_REQUIRED
const isTooManyRequests = status =>
  status === HTTP_STATUS_CODE.TOO_MANY_REQUESTS
const isInternalServerError = status =>
  status === HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR

export {
  HTTP_STATUS_CODE,
  isBadRequest,
  isUnauthorized,
  isPaymentRequired,
  isForbidden,
  isNotFound,
  isPreconditionFailed,
  isUnprocessableEntity,
  isLocked,
  isPreconditionRequired,
  isTooManyRequests,
  isInternalServerError
}
