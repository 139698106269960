import Vue from 'vue'

Vue.directive('click-outside', {
  bind (el, binding, vNode) {
    const bubble = binding.modifiers.bubble
    const handler = e => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e)
      }
    }
    el.__vueClickOutside__ = handler

    document.addEventListener('click', handler, { passive: true })
  },
  unbind (el, binding) {
    document.removeEventListener('click', el.__vueClickOutside__, {
      passive: true
    })
    el.__vueClickOutside__ = null
  }
})
