export const TYPES_ENUM = {
  SWAP: 'swap',
  INSTALL: 'install',
  UNINSTALL: 'uninstall',
  SUPPLY: 'supply'
}

export const STATUS_ENUM = {
  REALLOCATED: 'reallocated',
  INSTALLED: 'installed'
}

export const TERMINAL_ROUTES_ENUM = {
  TERMINAL: 'terminal',
  TERMINALS: 'terminals',
  TERMINAL_REQUEST: 'terminalRequest',
  TERMINAL_ROLL_REQUEST: 'terminalRollRequest',
  TERMINAL_UNINSTALL: 'terminalUninstall',
  TERMINAL_SWAP: 'terminalSwap',
  TERMINAL_DETAILS: 'terminalDetails'
}

const isPhysicalWorldTerminalRouteVisible = routeName =>
  Object.values(TERMINAL_ROUTES_ENUM).includes(routeName)

const isSupply = type => type === TYPES_ENUM.SUPPLY

const isReallocated = type => type === STATUS_ENUM.REALLOCATED

export { isPhysicalWorldTerminalRouteVisible, isSupply, isReallocated }
