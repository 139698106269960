import { DashInstance, catchError } from '@/store'

const plan = {
  state: {
    plans: { items: [], pagination: {} },
    loadingList: true,
    filterOptions: [
      {
        name: 'plan.filter.name',
        type: 'text',
        name_query: 'name'
      },
      {
        name: 'plan.filter.status',
        type: 'select',
        select_options: [
          {
            id: 'active',
            text: 'plan.filter.active'
          },
          {
            id: 'inactive',
            text: 'plan.filter.inactive'
          },
          {
            id: 'deleted',
            text: 'plan.filter.deleted'
          }
        ],
        name_query: 'status'
      },
      {
        name: 'plan.filter.billing-type',
        type: 'select',
        select_options: [
          {
            id: 'prepaid',
            text: 'plan.filter.prepaid'
          },
          {
            id: 'postpaid',
            text: 'plan.filter.postpaid'
          },
          {
            id: 'exact_day',
            text: 'plan.filter.exact-day'
          }
        ],
        name_query: 'billing_type'
      },
      {
        name: 'plan.filter.creation-period',
        type: 'select',
        select_options: [
          {
            id: 'specific_date',
            text: 'plan.filter.period-specific'
          },
          {
            id: 'date_filters',
            text: 'plan.filter.period-predefined'
          },
          {
            id: 'interval',
            text: 'plan.filter.period-interval'
          }
        ],
        name_query: 'period_type'
      },
      {
        name: 'plan.filter.specific-date',
        type: 'date',
        name_query: 'specific_date',
        period_type: 'specific_date'
      },
      {
        name: 'plan.filter.predefined-date',
        type: 'select',
        select_options: [
          {
            id: 'Today',
            text: 'plan.filter.predefined-today'
          },
          {
            id: 'Yesterday',
            text: 'plan.filter.predefined-yesterday'
          },
          {
            id: 'SevenDays',
            text: 'plan.filter.predefined-7-days'
          },
          {
            id: 'FifteenDays',
            text: 'plan.filter.predefined-15-days'
          },
          {
            id: 'OneMonth',
            text: 'plan.filter.predefined-30-days'
          }
        ],
        name_query: 'date_filters',
        period_type: 'date_filters'
      },
      {
        name: 'plan.filter.initial-date',
        type: 'date',
        name_query: 'since',
        period_type: 'interval'
      },
      {
        name: 'plan.filter.final-date',
        type: 'date',
        name_query: 'until',
        period_type: 'interval'
      }
    ]
  },
  mutations: {
    toggleLoadingList (state, status) {
      state.loadingList = status
    },
    SET_PLANS (state, plans) {
      state.plans = plans
    }
  },
  getters: {
    plans (state) {
      return state.plans
    },
    loadingListPlans (state) {
      return state.loadingList
    },
    filterOptionsPlans (state) {
      return state.filterOptions
    }
  },
  actions: {
    GET_PLANS (context, params = {}) {
      context.commit('toggleLoadingList', true)
      return DashInstance.plan
        .getAll(params.merchantId, params.accountId, params.query)
        .then(res => {
          context.commit('SET_PLANS', res)
          context.commit('toggleLoadingList', false)
          Promise.resolve(res)
        })
        .catch(err => {
          return catchError(err, context)
        })
    },
    GET_PLAN (context, params = {}) {
      return DashInstance.plan
        .getById(
          params.merchantId,
          params.accountId,
          params.planId,
          params.query
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    toggleLoadingListPlans ({ commit }, status) {
      commit('toggleLoadingList', status)
    }
  }
}

export default plan
