import {
  isSettingsWebhooksConfigEnabled,
  isSettingsWebhooksConfigEditEnabled
} from '@/actions-permission/settings-webhooks-config.permission'

const routeWebhookConfigRoot = store => ({
  path: 'webhook-config',
  name: 'webhook-config',
  component: () =>
    import(
      /* webpackChunkName: "webhookconfig" */ '@/views/Settings/WebhookConfig.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (
      !isSettingsWebhooksConfigEnabled(
        store.getters.permissionIsEnabled,
        store.getters.componentIsEnabled
      )
    ) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.settings.root',
      current: 'breadcrumb.settings.webhook.root'
    }
  }
})

const routeWebhookConfigAdd = store => ({
  path: 'webhook-config-form/create',
  name: 'webhook-config-form',
  component: () =>
    import(
      /* webpackChunkName: "webhookconfigform" */ '@/views/Settings/WebhookConfigForm.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (
      !isSettingsWebhooksConfigEditEnabled(store.getters.permissionIsEnabled)
    ) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.settings.root',
      parents: [
        {
          name: 'breadcrumb.settings.webhook.root',
          routeName: 'webhook-config'
        }
      ],
      current: 'breadcrumb.settings.webhook.add'
    }
  }
})

const routeWebhookConfigEdit = store => ({
  path: 'webhook-config-form/:id/edit',
  name: 'webhook-config-edit',
  component: () =>
    import(
      /* webpackChunkName: "webhookconfigform" */ '@/views/Settings/WebhookConfigForm.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (
      !isSettingsWebhooksConfigEditEnabled(store.getters.permissionIsEnabled)
    ) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.settings.root',
      parents: [
        {
          name: 'breadcrumb.settings.webhook.root',
          routeName: 'webhook-config'
        }
      ],
      current: 'breadcrumb.settings.webhook.edit'
    }
  }
})

export default [
  routeWebhookConfigRoot,
  routeWebhookConfigAdd,
  routeWebhookConfigEdit
]
