import { DashInstance, catchError } from '@/store'
import { DATE_PICKER_OPTIONS } from '../../enums/date-picker.enum'

const home = {
  state: {
    isLoading: true,
    data: null
  },
  mutations: {
    SET_LOADING_HOME (state, payload) {
      state.isLoading = payload
    },
    SET_HOME_DATA (state, payload) {
      state.data = payload
    }
  },
  getters: {
    HOME_DATA_IS_LOADING (state) {
      return state.isLoading
    },
    HOME_DATA (state) {
      return state.data
    }
  },
  actions: {
    GET_DATA_HOME (context, params = { query: {} }) {
      context.commit('SET_LOADING_HOME', true)

      const payload = JSON.parse(JSON.stringify(params)) ?? {}

      if (!payload?.query || Object.keys(payload?.query)?.length === 0) {
        payload.query = { date_filters: DATE_PICKER_OPTIONS.TODAY.id }
      }

      if (payload.query?.created_since) {
        payload.query = {
          start_date: payload.query.created_since,
          end_date: payload.query.created_until
        }

        delete payload.query.created_since
        delete payload.query.created_until
      }

      return DashInstance.insights
        .getById(payload.merchant_id, payload.account_id, payload.query)
        .then(res => {
          context.commit('SET_HOME_DATA', res.insights)
          context.commit('SET_LOADING_HOME', false)
          return Promise.resolve(res)
        })
        .catch(err => {
          context.commit('SET_LOADING_HOME', false)
          return catchError(err, context)
        })
    }
  }
}

export default home
