import { everyActionsPermissionIsEnabled } from '.'

export const INVITES_ACTIONS_ENUM = {
  search: 'account_staff.invite:search',
  create: 'account_staff.invite:create',
  get: 'account_staff.invite:get',
  resend: 'account_staff.invite:resend',
  remove: 'account_staff.invite:remove'
}

const INVITES_ACTIONS = [
  INVITES_ACTIONS_ENUM.search,
  INVITES_ACTIONS_ENUM.create,
  INVITES_ACTIONS_ENUM.get,
  INVITES_ACTIONS_ENUM.resend,
  INVITES_ACTIONS_ENUM.remove
]
const invitesEnabled = permissionIsEnabledFn => {
  return everyActionsPermissionIsEnabled(INVITES_ACTIONS, permissionIsEnabledFn)
}

const invitesSearchEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(INVITES_ACTIONS_ENUM.search)
}

const invitesCreateEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(INVITES_ACTIONS_ENUM.create)
}

const invitesResendEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(INVITES_ACTIONS_ENUM.resend)
}
const invitesRemoveEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(INVITES_ACTIONS_ENUM.remove)
}

const invitesGetEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(INVITES_ACTIONS_ENUM.get)
}

export {
  invitesEnabled,
  invitesSearchEnabled,
  invitesCreateEnabled,
  invitesGetEnabled,
  invitesResendEnabled,
  invitesRemoveEnabled
}
