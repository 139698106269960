const routePlans = store => ({
  path: 'plans/',
  name: 'plans',
  component: () => import('@/views/Recurrences/Plans/Plans.vue'),
  beforeEnter: (to, from, next) => {
    if (
      store.getters.permissionIsEnabled('plan:search') &&
      store.getters.componentIsEnabled('sidemenu', 'plans')
    ) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.recurrence.root',
      current: 'breadcrumb.recurrence.plans'
    }
  }
})

const routePlansDetail = store => ({
  path: 'plans/:id/:tab',
  name: 'plansDetail',
  component: () =>
    import(
      /* webpackChunkName: "plansdetail" */ '@/views/Recurrences/Plans/PlansDetail.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (
      store.getters.permissionIsEnabled('plan:get') &&
      store.getters.componentIsEnabled('sidemenu', 'plans')
    ) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.recurrence.root',
      current: 'breadcrumb.recurrence.plans-detail',
      parents: [
        {
          name: 'breadcrumb.recurrence.plans',
          routeName: 'plans'
        }
      ]
    }
  }
})

export default [routePlans, routePlansDetail]
