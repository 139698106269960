const CHARGEBACKS_PERMISSIONS_ENUM = {
  get: 'chargeback:get',
  search: 'chargeback:search',
  view: 'chargeback:view',
  acceptDispute: 'chargeback.dispute:accept',
  createDispute: 'chargeback.dispute:create',
  submitDispute: 'chargeback.dispute:submit',
  createUploadDispute: 'chargeback.dispute.upload:create',
  viewUploadDispute: 'chargeback.dispute.upload:view',
  createBatchUploadDispute: 'chargeback.dispute.batch_upload:create',
  viewReceipt: 'chargeback.receipt:view',
  exportReport: 'chargeback.report:export'
}

const chargebackGetEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(CHARGEBACKS_PERMISSIONS_ENUM.get)
}

const chargebackSearchEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(CHARGEBACKS_PERMISSIONS_ENUM.search)
}

const chargebackViewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(CHARGEBACKS_PERMISSIONS_ENUM.view)
}

const chargebackAcceptDisputeEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(CHARGEBACKS_PERMISSIONS_ENUM.acceptDispute)
}

const chargebackCreateDisputeEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(CHARGEBACKS_PERMISSIONS_ENUM.createDispute)
}

const chargebackSubmitDisputeEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(CHARGEBACKS_PERMISSIONS_ENUM.submitDispute)
}

const chargebackCreateUploadDisputeEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(CHARGEBACKS_PERMISSIONS_ENUM.createUploadDispute)
}

const chargebackViewUploadDisputeEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(CHARGEBACKS_PERMISSIONS_ENUM.viewUploadDispute)
}

const chargebackCreateBatchUploadDisputeEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(
    CHARGEBACKS_PERMISSIONS_ENUM.createBatchUploadDispute
  )
}

const chargebackViewReceiptEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(CHARGEBACKS_PERMISSIONS_ENUM.viewReceipt)
}

const chargebackExportReportEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(CHARGEBACKS_PERMISSIONS_ENUM.exportReport)
}

export {
  CHARGEBACKS_PERMISSIONS_ENUM,
  chargebackGetEnabled,
  chargebackSearchEnabled,
  chargebackViewEnabled,
  chargebackAcceptDisputeEnabled,
  chargebackCreateDisputeEnabled,
  chargebackSubmitDisputeEnabled,
  chargebackCreateUploadDisputeEnabled,
  chargebackViewUploadDisputeEnabled,
  chargebackCreateBatchUploadDisputeEnabled,
  chargebackViewReceiptEnabled,
  chargebackExportReportEnabled
}
