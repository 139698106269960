import { someActionsPermissionIsEnabled } from '.'

export const SETTINGS_PAYMENTS_ACTIONS_ENUM = {
  pixView: 'settings.payments.pix:view',
  creditCardView: 'settings.payments.credit_card:view',
  debitCardView: 'settings.payments.debit_card:view',
  boletoView: 'settings.payments.boleto:view',
  bankTransferView: 'settings.payments.bank_transfer:view',
  voucherView: 'settings.payments.voucher:view',
  cashView: 'settings.payments.cash:view',
  safetyPayView: 'settings.payments.safety_pay:view'
}

const SETTINGS_PAYMENTS_ACTIONS = [
  SETTINGS_PAYMENTS_ACTIONS_ENUM.pixView,
  SETTINGS_PAYMENTS_ACTIONS_ENUM.creditCardView,
  SETTINGS_PAYMENTS_ACTIONS_ENUM.debitCardView,
  SETTINGS_PAYMENTS_ACTIONS_ENUM.boletoView,
  SETTINGS_PAYMENTS_ACTIONS_ENUM.bankTransferView,
  SETTINGS_PAYMENTS_ACTIONS_ENUM.voucherView,
  SETTINGS_PAYMENTS_ACTIONS_ENUM.cashView,
  SETTINGS_PAYMENTS_ACTIONS_ENUM.safetyPayView
]

// showPaymentsConfig
const isSettingsPaymentsEnabled = permissionIsEnabledFn => {
  return someActionsPermissionIsEnabled(
    SETTINGS_PAYMENTS_ACTIONS,
    permissionIsEnabledFn
  )
}

const isSettingsPaymentsPixViewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_PAYMENTS_ACTIONS_ENUM.pixView)
}

const isSettingsPaymentsCreditCardViewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_PAYMENTS_ACTIONS_ENUM.creditCardView)
}

const isSettingsPaymentsDebitCardViewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_PAYMENTS_ACTIONS_ENUM.debitCardView)
}

const isSettingsPaymentsBoletoViewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_PAYMENTS_ACTIONS_ENUM.boletoView)
}

const isSettingsPaymentsBankTransferViewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_PAYMENTS_ACTIONS_ENUM.bankTransferView)
}

const isSettingsPaymentsVoucherViewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_PAYMENTS_ACTIONS_ENUM.voucherView)
}

const isSettingsPaymentsCashViewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_PAYMENTS_ACTIONS_ENUM.cashView)
}

const isSettingsPaymentsSafetyPayViewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_PAYMENTS_ACTIONS_ENUM.safetyPayView)
}

export {
  isSettingsPaymentsEnabled,
  isSettingsPaymentsPixViewEnabled,
  isSettingsPaymentsCreditCardViewEnabled,
  isSettingsPaymentsDebitCardViewEnabled,
  isSettingsPaymentsBoletoViewEnabled,
  isSettingsPaymentsBankTransferViewEnabled,
  isSettingsPaymentsVoucherViewEnabled,
  isSettingsPaymentsCashViewEnabled,
  isSettingsPaymentsSafetyPayViewEnabled
}
