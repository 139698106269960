import { isNotFound as isHttpNotFound } from '@/enums/http-status.enum'
import { isKycNotFound } from '@/enums/kyc.enum'
import KycManager from '../views/Kyc/kyc.manager'

const routeKyc = async ({ store, params, next }) => {
  const { isKycOnboardingPending, isEmployee, profile } = store.getters
  const { merchant_id: merchantId, account_id: accountId } = params

  try {
    const kyc = await store.dispatch('GET_KYC', params)

    if (
      !isEmployee &&
      KycManager.shouldRedirectToKyc(kyc, isKycOnboardingPending)
    ) {
      store.commit('SET_KYC_ROUTE_ENABLED', true)
      next({ path: `/${merchantId}/${accountId}` })
      return
    }
  } catch (err) {
    if (isKycNotFound(err?.message) || isHttpNotFound(err?.status)) {
      if (!isEmployee) {
        store.dispatch('SET_ONBOARDING_STATUS', profile.merchant)
      }
    } else {
      store.commit('toggleGlobalError', { status: true, code: 400 })
    }
  }
}

export default routeKyc
