const routeWebhooks = store => ({
  path: 'webhooks',
  name: 'webhooks',
  component: () =>
    import(/* webpackChunkName: "Webhooks" */ '@/views/Webhooks/Webhooks.vue'),
  beforeEnter: (to, from, next) => {
    if (!store.getters.permissionIsEnabled('webhook:search')) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      current: 'breadcrumb.webhooks.root'
    }
  }
})

const routeWebhooksDetail = store => ({
  path: 'webhooks/:id/:tab',
  name: 'webhooksDetail',
  component: () =>
    import(
      /* webpackChunkName: "webhooksdetail" */ '@/views/Webhooks/WebhooksDetail.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (!store.getters.permissionIsEnabled('webhook:get')) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      current: 'breadcrumb.webhooks.detail',
      parents: [
        {
          name: 'breadcrumb.webhooks.root',
          routeName: 'webhooks'
        }
      ]
    }
  }
})

export default [routeWebhooks, routeWebhooksDetail]
