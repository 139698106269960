import { recipientsBalanceEnabled } from '../actions-permission/recipients-balance.permissions'
import {
  recipientsCreateEnabled,
  recipientsGetEnabled,
  recipientsViewEnabled,
  recipientsEditEnabled
} from '../actions-permission/recipients.permissions'

const commonBalanceBeforeEnter = ({ getters, commit }, next) => {
  const { permissionIsEnabled, componentIsEnabled, businessModel } = getters
  if (
    !recipientsBalanceEnabled(
      permissionIsEnabled,
      componentIsEnabled,
      businessModel
    )
  ) {
    return commit('toggleGlobalError', { status: true, code: 404 })
  }
  next()
}

const commonRecipientsEditBeforeEnter = ({ getters, commit }, next) => {
  const { permissionIsEnabled, componentIsEnabled } = getters
  if (!recipientsEditEnabled(permissionIsEnabled, componentIsEnabled)) {
    return commit('toggleGlobalError', { status: true, code: 404 })
  }
  next()
}

const routeRecipientsData = store => ({
  path: ':id/recipient-info',
  name: 'recipientsData',
  component: () =>
    import(
      /* webpackChunkName: "recipientsDetail/recipient-info" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => commonRecipientsEditBeforeEnter(store, next),
  meta: {
    breadcrumb: {
      parents: [
        {
          name: 'breadcrumb.recipients.root',
          routeName: 'recipients'
        }
      ],
      current: 'breadcrumb.recipients.data'
    }
  }
})

const routeRecipientsSettings = store => ({
  path: ':id/recipient-setting',
  name: 'recipientsSettings',
  component: () =>
    import(
      /* webpackChunkName: "recipientsDetail/recipient-settings" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => commonRecipientsEditBeforeEnter(store, next),
  meta: {
    breadcrumb: {
      parents: [
        {
          name: 'breadcrumb.recipients.root',
          routeName: 'recipients'
        }
      ],
      current: 'breadcrumb.recipients.settings'
    }
  }
})

const routeRecipientsBalance = store => ({
  path: 'recipients/:id/balance/:pg_recipient_id?',
  name: 'recipientsBalance',
  alias: ['recipients/:id/withdraw/:pg_recipient_id?'],
  component: () =>
    import(
      /* webpackChunkName: "recipientsDetail/balance" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => commonBalanceBeforeEnter(store, next),
  meta: {
    breadcrumb: {
      parents: [
        {
          name: 'breadcrumb.recipients.root',
          routeName: 'recipients'
        }
      ],
      current: 'breadcrumb.recipients.balance'
    }
  }
})

const routeRecipientsBalanceDetails = store => ({
  path: 'recipients/:id/balance/:pg_recipient_id/details',
  name: 'recipientsBalance',
  component: () =>
    import(
      /* webpackChunkName: "usersdetail" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => commonBalanceBeforeEnter(store, next),
  meta: {
    breadcrumb: {
      parents: [
        {
          name: 'breadcrumb.recipients.root',
          routeName: 'recipients'
        }
      ],
      current: 'breadcrumb.recipients.detail'
    }
  }
})

const routeRecipientsTransfers = store => ({
  path: 'recipients/:id/transfers/:pg_recipient_id?/:transfer_id?',
  name: 'recipientsTransfers',
  component: () =>
    import(
      /* webpackChunkName: "recipientsDetail/transfers" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => commonBalanceBeforeEnter(store, next),
  meta: {
    breadcrumb: {
      parents: [
        {
          name: 'breadcrumb.recipients.root',
          routeName: 'recipients'
        }
      ],
      current: 'breadcrumb.recipients.transfers'
    }
  }
})

const routeRecipientsSettlements = store => ({
  path: 'recipients/:id/settlements/:pg_recipient_id?',
  name: 'recipientsSettlements',
  component: () =>
    import(
      /* webpackChunkName: "recipientsDetail/settlements" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => commonBalanceBeforeEnter(store, next),
  meta: {
    breadcrumb: {
      parents: [
        {
          name: 'breadcrumb.recipients.root',
          routeName: 'recipients'
        }
      ],
      current: 'breadcrumb.recipients.settlements'
    }
  }
})

const routeRecipientsAnticipations = store => ({
  path: 'recipients/:id/anticipations/:pg_recipient_id?',
  name: 'recipientsAnticipations',
  component: () =>
    import(
      /* webpackChunkName: "recipientsDetail/anticipations" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => commonBalanceBeforeEnter(store, next),
  meta: {
    breadcrumb: {
      parents: [
        {
          name: 'breadcrumb.recipients.root',
          routeName: 'recipients'
        }
      ],
      current: 'breadcrumb.recipients.anticipations'
    }
  }
})

const routeRecipientsAnticipationDetails = store => ({
  path: 'recipients/:id/anticipations/:pg_recipient_id/:pg_anticipation_id/details',
  name: 'recipientsAnticipationDetails',
  component: () =>
    import(
      /* webpackChunkName: "recipientsDetail/anticipation-details" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => commonBalanceBeforeEnter(store, next),
  meta: {
    breadcrumb: {
      parents: [
        {
          name: 'breadcrumb.recipients.root',
          routeName: 'recipients'
        },
        {
          name: 'breadcrumb.recipients.anticipations',
          routeName: 'recipientsAnticipations'
        }
      ],
      current: 'breadcrumb.recipients.detail'
    }
  }
})

const routeRecipientsAnticipation = store => ({
  path: 'recipients/:id/anticipation/:pg_recipient_id?',
  name: 'recipientsAnticipation',
  component: () =>
    import(
      /* webpackChunkName: "recipientsDetail/anticipation-request" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => commonBalanceBeforeEnter(store, next),
  meta: {
    breadcrumb: {
      parents: [
        {
          name: 'breadcrumb.recipients.root',
          routeName: 'recipients'
        }
      ],
      current: 'breadcrumb.recipients.balance'
    }
  }
})

const routeRecipientsReceivablesRegister = store => ({
  path: 'recipients/:id/receivables-register/:pg_recipient_id?',
  name: 'recipientsReceivablesRegister',
  alias: [
    'recipients/:id/receivables-register/:pg_recipient_id?/:day',
    'recipients/:id/receivables-register/:pg_recipient_id?/:day/:arrangement'
  ],
  component: () =>
    import(
      /* webpackChunkName: "recipientsDetail/receivables-register" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => commonBalanceBeforeEnter(store, next),
  meta: {
    breadcrumb: {
      parents: [
        {
          name: 'breadcrumb.recipients.root',
          routeName: 'recipients'
        }
      ],
      current: 'breadcrumb.recipients.receivables-register'
    }
  }
})

const routeRecipientsContracts = store => ({
  path: 'recipients/:id/contracts/:pg_recipient_id?',
  name: 'recipientsContracts',
  alias: [
    'recipients/:id/contracts/:pg_recipient_id/:tabActive',
    'recipients/:id/contracts/:pg_recipient_id/values/:day',
    'recipients/:id/contracts/:pg_recipient_id/institution/:contractHolder',
    'recipients/:id/contracts/:pg_recipient_id/contract/:contractKey',
    'recipients/:id/contracts/:pg_recipient_id/contract/:contractKey/contestation'
  ],
  component: () =>
    import(
      /* webpackChunkName: "recipientsDetail/contracts" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => commonBalanceBeforeEnter(store, next),
  meta: {
    breadcrumb: {
      parents: [
        {
          name: 'breadcrumb.recipients.root',
          routeName: 'recipients'
        }
      ],
      current: 'breadcrumb.recipients.contracts'
    }
  }
})

const routeRecipients = store => ({
  path: 'recipients',
  name: 'recipients',
  component: () =>
    import(
      /* webpackChunkName: "recipients" */ '@/views/Recipients/Recipients.vue'
    ),
  beforeEnter: (to, from, next) => {
    const { permissionIsEnabled, componentIsEnabled, businessModel } =
      store.getters

    if (
      !recipientsViewEnabled(
        permissionIsEnabled,
        componentIsEnabled,
        businessModel
      )
    ) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      current: 'breadcrumb.recipients.root'
    }
  }
})

const routeRecipientsDetails = store => ({
  path: ':id/recipient-details',
  name: 'recipientsDetail',
  component: () =>
    import(
      /* webpackChunkName: "recipientsDetail" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    const { permissionIsEnabled, componentIsEnabled } = store.getters

    if (!recipientsGetEnabled(permissionIsEnabled, componentIsEnabled)) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      parents: [
        {
          name: 'breadcrumb.recipients.root',
          routeName: 'recipients'
        }
      ],
      current: 'breadcrumb.recipients.detail'
    }
  }
})

const routeRecipientsCreate = store => ({
  path: 'create/recipient-create',
  name: 'recipientsCreate',
  component: () =>
    import(
      /* webpackChunkName: "recipientsCreate" */ '@/views/Recipients/RecipientCreate/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    const { permissionIsEnabled, componentIsEnabled } = store.getters

    if (!recipientsCreateEnabled(permissionIsEnabled, componentIsEnabled)) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      parents: [
        {
          name: 'breadcrumb.recipients.root',
          routeName: 'recipients'
        }
      ],
      current: 'breadcrumb.recipients.create'
    }
  }
})

export default [
  routeRecipients,
  routeRecipientsDetails,
  routeRecipientsCreate,
  routeRecipientsData,
  routeRecipientsSettings,
  routeRecipientsBalance,
  routeRecipientsBalanceDetails,
  routeRecipientsTransfers,
  routeRecipientsSettlements,
  routeRecipientsAnticipations,
  routeRecipientsAnticipationDetails,
  routeRecipientsReceivablesRegister,
  routeRecipientsContracts,
  routeRecipientsAnticipation
]
