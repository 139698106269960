const MERCHANTS_BYPASS_LIST = [
  'merch_banz7nzFdiYm7MGr',
  'merch_qp0Vg7KFwu0glZbQ',
  'merch_MOKbyrRfZHRy1daD',
  'merch_DBZ4Roqtepcpr5Xa',
  'merch_Zjy9DbfzAijo87Wx',
  'merch_DXRzy1guVtN79V6b',
  'merch_lDXkzLGh11IXX270',
  'merch_82G6Yjghp1HZZqbE',
  'merch_oMlKJbgIrmTykKQg',
  'merch_pndQN2whz3IbJbL0',
  'merch_RlQkwMcyEcz9dnDj',
  'merch_27qpx2XfBtq4G4z0',
  'merch_WKJMRqACweudXobE',
  'merch_nWbe8Z0s4Bu6PXwr',
  'merch_nzob8zWF7C2pjY3w',
  'merch_K2g7WKLCGYug57j4',
  'merch_6vg84vGS3uAbJXNn',
  'merch_m1QORZBF8C14OegK',
  'merch_GJ3OZ0mIktexyxjr',
  'merch_qVewdgMUK9hYXmxy',
  'merch_aAWrYxEhOUKnmpqJ',
  'merch_3YN2MZNHNLsrMo6a',
  'merch_zaKjmn6FOcaw1Xqn',
  'merch_M37loVJcAC8xoajm',
  'merch_aN1jY5FzbI3XjqlW',
  'merch_BDXvLDNHkKUXqPwa',
  'merch_AWBo9gt9VCW24ZrD',
  'merch_BPz4RVZC2afNx4gj',
  'merch_1AkdNY7CBu6ao6R5',
  'merch_yNeAVwDfvBhopBM0',
  'merch_bN2Annca2sWGAyDm',
  'merch_2xagxzei5Ef0dPRb',
  'merch_GD2QvqgEHEuVpzja',
  'merch_zE86DaZhMIQKm10O',
  'merch_An8lMRZuPofAgGLO',
  'merch_7gvAPRZC2MIoG8wk',
  'merch_zWmM1lNvs8T7x62G',
  'merch_xyAYvJTyoFKAYqn6',
  'merch_0nx4Z8wfanhXvRBj',
  'merch_bPM1OXlcWs9EOe0Q',
  'merch_3W2Bm1aI7kuDByEG',
  'merch_lnAW2dAFeFnPbMdB',
  'merch_gxoakG2u5C0KaL47',
  'merch_5BwlzpCpyuwv0xom',
  'merch_nQLjnnDtW2H1njg2',
  'merch_eZrqdEkpTrC0LME1',
  'merch_9qNezMaIjkFv5JEz',
  'merch_Ye6KpaQUB3t9lAaE',
  'merch_YP0xWoTGOSlzxgaj',
  'merch_3GVdBkbtyphNeKjJ',
  'merch_MQg8GN9FPIm1YxjB',
  'merch_vNp0xp1SEUYY02Yw',
  'merch_ErJjLQai5vIJKbNL',
  'merch_Q1RopoJjHYtyNqw8',
  'merch_zGLmPZJHV2HepX3a',
  'merch_QvkeaLDHysk5aL8B',
  'merch_GEXRKyasL1io242x',
  'merch_32dNEJWt87fAGqbV',
  'merch_9MgBLmkHKtb6E8y7',
  'merch_3VQmvNXUzSWxmPpg',
  'merch_PY3drKxhzJhmYX05',
  'merch_z3mE7ZDhgudLP4eR',
  'merch_yJA5M3s9rsbJ69W2',
  'merch_gKWVq67I8tdgbMyr',
  'merch_80rOYyDs8PCZOnDe',
  'merch_0mZGmDXHERTPWRXQ',
  'merch_69KMrQEohYUarxkn',
  'merch_lqNzOR7gHzCBDJGo',
  'merch_oNOQAZePHNIo29ba',
  'merch_kdw85OiQNslA2NxR',
  'merch_BK6WrL3F8ef5jQgP',
  'merch_pnV37Ou0JsVr3DKj',
  'merch_kro19ph8rSjJy30q',
  'merch_V2opRkjcjTbAYLgb',
  'merch_zvRmWqwSYS9Jy6kP',
  'merch_e4L8WJiQMSPNYDrZ',
  'merch_p3o59QRixFR85MW7',
  'merch_EarZR5qCXFvEdzPq',
  'merch_5kbRdNpUwaCr3z7y',
  'merch_YlyL1jouwMH0JPQN',
  'merch_ao01AvYHk3T3V953',
  'merch_MjP2gXphoLTzdVpw',
  'merch_4DKneZaupszY8Yr7',
  'merch_Bo2WZ56FxsnXKA6b',
  'merch_oEqlM1J0IxuZajzR',
  'merch_lv9863JI7IxN1WxZ',
  'merch_DZXKMxh11sWY2REY',
  'merch_n0QkNQF48svRmaGD',
  'merch_6qxQzxoAIwhPM7pw',
  'merch_VBpqzjBFvhP8qedD',
  'merch_gjLVP5DtknuW8xXG',
  'merch_dpqn1RZI5hP1oBe7',
  'merch_JMD24ZPcjnI8l4Zz',
  'merch_2JEkX2dptwueQWql',
  'merch_P93XYa3hYxf7eXW8',
  'merch_VmAWzmDH8KtwaG8R',
  'merch_mle7Lk2Cb1IKBd53'
]

export { MERCHANTS_BYPASS_LIST }
