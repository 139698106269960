<template>
  <emd-modal
    size="xlarge"
    open
    scrollable
  >
    <template v-slot:content>
      <emd-loader
        v-if="isLoading"
        type="_subtle"
        class="eu-align_center eu-align_center"
      />
      <div
        v-else
        class="eu-column eu-row_md eu-flex eu-justify_center eu-py_6 eu-px_6"
      >
        <div class="eu-flex-item eu-flex eu-justify_center">
          <img
            class="img-status"
            v-if="isModalTextEqualStatus"
            :src="statusScreen"
            alt="status-screen"
          />
          <img
            v-else
            :src="onboardingScreen"
            alt="onboarding-screen"
          />
        </div>
        <div class="eu-flex-item eu-ml_5_md">
          <h1 class="emd-text _size_page eu-mb_5">
            {{
              $t(
                `${modalTextType}-pending.${modalContentType}-test-account.title`
              )
            }}
          </h1>
          <p class="emd-text _size_body eu-mb_7">
            {{
              $t(
                `${modalTextType}-pending.${modalContentType}-test-account.content.${translationType[0]}`,
                { [placeName]: showAccountNameIfIsOnboarding }
              )
            }}
          </p>
          <p
            class="emd-text _size_body eu-mt_5 warning"
            v-if="
              isTypeStatusAndHasTestAccounts ||
              isTypeOnboardingAndHasTestAccounts
            "
          >
            {{
              $t(
                `${modalTextType}-pending.${modalContentType}-test-account.content.${translationType[1]}`
              )
            }}
          </p>
          <p
            class="emd-text _size_body"
            v-if="isTypeStatusAndHasTestAccounts"
          >
            {{
              $t(
                `${modalTextType}-pending.${modalContentType}-test-account.content.${translationType[2]}`
              )
            }}
          </p>
          <div class="eu-flex eu-mt_5">
            <button
              class="emd-btn _color_primary"
              @click="redirectToUrl"
            >
              {{
                $t(
                  `${modalTextType}-pending.${modalContentType}-test-account.button`
                )
              }}
              <i class="uil uil-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </template>
  </emd-modal>
</template>

<script>
import { EmdModal, EmdLoader } from 'emerald-vue/src/components'

export default {
  name: 'ModalPendingAccount',
  components: { EmdModal, EmdLoader },
  computed: {
    merchant () {
      return this.$store.getters.merchant
    },
    account () {
      return this.$store.getters.account
    },
    accountStatusIsPending () {
      return this.account.status.key.toLowerCase() === 'pending'
    },
    modalTextType () {
      return this.accountStatusIsPending ? 'status' : 'onboarding'
    },
    modalContentType () {
      return this.hasTestAccounts ? 'has' : 'no'
    },
    hasTestAccounts () {
      return this.$store.getters.testAccounts.items.length
    },
    isModalTextEqualStatus () {
      return this.modalTextType === 'status'
    },
    isModalTextEqualOnboarding () {
      return this.modalTextType === 'onboarding'
    },
    placeName () {
      return this.isModalTextEqualOnboarding ? 'account' : 'email'
    },
    showAccountNameIfIsOnboarding () {
      return this.isModalTextEqualOnboarding
        ? this.account.name
        : this.$t(
            `${this.modalTextType}-pending.${this.modalContentType}-test-account.email`
          )
    },
    translationType () {
      return this.isModalTextEqualOnboarding
        ? ['account-approved', 'access-test-account']
        : [
            'validating-your-information',
            'answer-in-one-hour',
            'explore-our-platform'
          ]
    },
    isTypeStatusAndHasTestAccounts () {
      return (
        this.hasTestAccounts &&
        this.isModalTextEqualStatus &&
        this.modalContentType === 'has'
      )
    },
    isTypeOnboardingAndHasTestAccounts () {
      return (
        this.hasTestAccounts &&
        this.isModalTextEqualOnboarding &&
        this.modalContentType === 'has'
      )
    },
    redirectToProfile () {
      return `${process.env.VUE_APP_PROFILE_URL}/merchants`
    },
    redirectToCompany () {
      return `${process.env.VUE_APP_COMPANY_URL}/${this.merchant.id}/accounts/test`
    },
    isLoading () {
      return this.$store.getters.localLoading
    },
    onboardingScreen () {
      return require('@/assets/pending/onboarding-screen.svg').default
    },
    statusScreen () {
      return require('@/assets/pending/status-screen.jpg').default
    }
  },
  methods: {
    redirectToUrl () {
      if (this.hasTestAccounts) window.location.href = this.redirectToCompany
      else window.location.href = this.redirectToProfile
    }
  }
}
</script>

<i18n>
  {
    "pt-BR": {
      "status-pending": {
      "has-test-account": {
        "title": "Seu Cadastro está quase pronto!",
        "email": "comercial@pagar.me",
        "content": {
          "validating-your-information": "Estamos validando os dados da sua conta e, em até 24 horas, você receberá mais informações por e-mail. Se quiser agilizar esse processo, acesse o nosso chat ou envie um e-mail para {email} com o assunto XXXXXXX.",
          "answer-in-one-hour": "Nosso time irá te responder em até uma hora, em horário comercial.",
          "explore-our-platform": "Enquanto isso, você pode continuar explorando nossa plataforma em modo de teste."
        },
        "button": "Acessar uma conta teste"
      },
      "no-test-account": {
        "title": "Seu cadastro está quase pronto!",
        "content": {
          "validating-your-information": "Estamos validando os dados da sua conta {account} e em breve você receberá mais informações no email do proprietário da conta."
        },
        "button": "Ir para meu perfil"
      }
    },
    "onboarding-pending": {
      "has-test-account": {
        "title": "Ops, existem algumas configurações pendentes",
        "content": {
          "account-approved": "A conta {account} já foi aprovada, mas precisamos que o proprietário da conta acesse a Dashboard para finalizar a configuração.",
          "access-test-account": "Enquanto isso, você pode acessar a conta de teste."
        },
        "button": "Acessar uma conta teste"
      },
      "no-test-account": {
        "title": "Ops, existem algumas configurações pendentes",
        "content": {
          "account-approved": "A conta {account} já foi aprovada, mas precisamos que o proprietário da conta acesse a Dashboard para finalizar a configuração."
        },
        "button": "Ir para meu perfil"
      }
    }
    },
    "en-US": {
      "status-pending": {
      "has-test-account": {
        "title": "Your registration is almost ready!",
        "email": "comercial@pagar.me",
        "content": {
          "validating-your-information": "We are validating your account data and, within 24 hours, you will receive more information by email. If you want to speed up this process, access our chat or send an email to {email} with the subject XXXXXXX.",
          "answer-in-one-hour": "Our team will answer you within an hour, during business hours.",
          "explore-our-platform": "In the meantime, you can continue exploring our platform in test mode."
        },
        "button": "Access a test account"
      },
      "no-test-account": {
        "title": "Your registration is almost ready!",
        "content": {
          "validating-your-information": "We are validating your account data {account} and you will soon receive more information by email from the account owner."
        },
        "button": "Go to my profile"
      }
    },
    "onboarding-pending": {
      "has-test-account": {
        "title": "Oops, there are some settings pending",
        "content": {
          "account-approved": "The account {account} has already been approved, but we need the account owner to access the Dashboard to finalize the setup.",
          "access-test-account": "Meanwhile, you can access the test account."
        },
        "button": "Access a test account"
      },
      "no-test-account": {
        "title": "Oops, there are some settings pending",
        "content": {
          "account-approved": "The account {account} has already been approved, but we need the account owner to access the Dashboard to finalize the setup."
        },
        "button": "Go to my profile"
      }
    }
    }
  }
</i18n>

<style>
.img-status {
  border-bottom-right-radius: 37%;
}

:is(.emailtext, .warning) {
  font-weight: 700;
}
</style>
