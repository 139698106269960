const MERCHANT_TYPES = {
  STAFF: 'Staff',
  ADMIN: 'Admin',
  OWNER: 'Owner'
}

/**
 * @param {String} type merchant.type.key
 * @returns Boolean
 */
const isStaffType = type => {
  return MERCHANT_TYPES.STAFF.toUpperCase() === type.toUpperCase()
}

/**
 * @param {String} type merchant.type.key
 * @returns Boolean
 */
const isAdmin = type => {
  return MERCHANT_TYPES.ADMIN.toUpperCase() === type.toUpperCase()
}

/**
 * @param {String} type merchant.type.key
 * @returns Boolean
 */
const isOwner = type => {
  return MERCHANT_TYPES.OWNER.toUpperCase() === type.toUpperCase()
}

/**
 * @param {String} type merchant.type.key
 * @returns Boolean
 */
const isOwnerOrAdmin = type => {
  return isOwner(type) || isAdmin(type)
}

export { MERCHANT_TYPES, isStaffType, isOwner, isAdmin, isOwnerOrAdmin }
