import { everyActionsPermissionIsEnabled } from '.'

export const PAYMENTS_LINKS_ACTIONS_ENUM = {
  view: 'payment_link:view',
  search: 'payment_link:search',
  get: 'payment_link:get',
  cancel: 'payment_link:cancel'
}

const PAYMENTS_LINKS_ACTIONS = [
  PAYMENTS_LINKS_ACTIONS_ENUM.view,
  PAYMENTS_LINKS_ACTIONS_ENUM.search,
  PAYMENTS_LINKS_ACTIONS_ENUM.get,
  PAYMENTS_LINKS_ACTIONS_ENUM.cancel
]
const paymentsLinksEnabled = (permissionIsEnabledFn, checkoutEnabled) => {
  return (
    checkoutEnabled &&
    everyActionsPermissionIsEnabled(
      PAYMENTS_LINKS_ACTIONS,
      permissionIsEnabledFn
    )
  )
}

const paymentsLinksViewEnabled = (permissionIsEnabledFn, checkoutEnabled) => {
  return (
    checkoutEnabled && permissionIsEnabledFn(PAYMENTS_LINKS_ACTIONS_ENUM.view)
  )
}

const paymentsLinksDetailEnabled = (permissionIsEnabledFn, checkoutEnabled) => {
  return (
    checkoutEnabled && permissionIsEnabledFn(PAYMENTS_LINKS_ACTIONS_ENUM.get)
  )
}

const paymentsLinksSearchEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(PAYMENTS_LINKS_ACTIONS_ENUM.search)
}

const paymentsLinksCancelEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(PAYMENTS_LINKS_ACTIONS_ENUM.cancel)
}

export {
  paymentsLinksEnabled,
  paymentsLinksViewEnabled,
  paymentsLinksDetailEnabled,
  paymentsLinksSearchEnabled,
  paymentsLinksCancelEnabled
}
