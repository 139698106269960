const app = {
  state: {
    toggleDrawer: false,
    toogleDrawerFeesCalculator: false
  },
  getters: {
    GET_DRAWER_HELP_CENTER (state) {
      return state.toggleDrawer
    },
    GET_DRAWER_FEES_CALCULATOR (state) {
      return state.toogleDrawerFeesCalculator
    }
  },
  mutations: {
    MUTATIONS_SET_DRAWER_HELP_CENTER (state, value) {
      state.toggleDrawer = value
    },
    MUTATIONS_TOGGLE_DRAWER_HELP_CENTER (state) {
      state.toggleDrawer = !state.toggleDrawer
    },
    MUTATIONS_TOGGLE_DRAWER_FEES_CALCULATOR (state) {
      state.toogleDrawerFeesCalculator = !state.toogleDrawerFeesCalculator
    },
    MUTATIONS_SET_DRAWER_FEES_CALCULATOR (state, value) {
      state.toogleDrawerFeesCalculator = value
    }
  },
  actions: {
    TOGGLE_DRAWER_HELP_CENTER (context) {
      context.commit('MUTATIONS_TOGGLE_DRAWER_HELP_CENTER')
    },
    SET_DRAWER_HELP_CENTER (context, value) {
      context.commit('MUTATIONS_SET_DRAWER_HELP_CENTER', value)
    },
    TOGGLE_DRAWER_FEES_CALCULATOR (context) {
      context.commit('MUTATIONS_TOGGLE_DRAWER_FEES_CALCULATOR')
    },
    SET_DRAWER_FEES_CALCULATOR (context, value) {
      context.commit('MUTATIONS_SET_DRAWER_FEES_CALCULATOR', value)
    }
  }
}

export default app
