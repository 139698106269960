import { DashInstance, catchError } from '@/store'
import filters from '@/utils/orders_new_filter.utils'

const order = {
  state: {
    orders: { items: [], pagination: {} },
    loadingList: true,
    filterOptions: filters
  },
  mutations: {
    toggleLoadingList (state, status) {
      state.loadingList = status
    },
    SET_ORDERS (state, orders) {
      state.orders = orders
    }
  },
  getters: {
    orders (state) {
      return state.orders
    },
    filterOptionsOrders (state) {
      return state.filterOptions
    },
    loadingListOrders (state) {
      return state.loadingList
    }
  },
  actions: {
    GET_ORDERS (context, params = {}) {
      context.commit('toggleLoadingList', true)
      const isTestAccount =
        context.rootGetters.account.account_type.value === 'test'
      return DashInstance.order
        .getAll(
          params.merchantId,
          params.accountId,
          params.query,
          isTestAccount
        )
        .then(res => {
          context.commit('SET_ORDERS', res)
          context.commit('toggleLoadingList', false)
          Promise.resolve(res)
        })
        .catch(err => {
          return catchError(err, context)
        })
    },
    GET_ORDER (context, params = {}) {
      return DashInstance.order
        .getById(
          params.merchantId,
          params.accountId,
          params.orderId,
          params.query
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    RESYNC_ORDER (context, params = {}) {
      return DashInstance.order
        .resync(params.merchantId, params.accountId, params.orderId)
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    toggleLoadingList ({ commit }, status) {
      commit('toggleLoadingList', status)
    },
    SEND_ORDER_REPORT_FILE (context, params = {}) {
      return DashInstance.order
        .reportFile(params.merchantId, params.accountId, params.body)
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    }
  }
}

export default order
