var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("emd-footer-internal", {
    staticClass: "flex flex-col items-center mt-10",
    attrs: {
      switchLanguage: _vm.switchLanguage,
      switchThemeMode: _vm.switchThemeMode,
      help: _vm.help,
      termsOfUse: _vm.termsOfUse,
      privacyPolicy: _vm.privacyPolicy,
      secureEnv: _vm.secureEnv,
      documentation: _vm.documentation,
      status: _vm.status,
      listStyle: _vm.listStyle,
    },
    on: {
      changeLanguage: _vm.changeLanguage,
      changeThemeMode: _vm.changeThemeMode,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }