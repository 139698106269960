import buildUrl from '@/utils/buildUrl.utils'

const EXTERNAL_ENDPOINTS = {
  CANNY_URL: {
    url: ({ merchantId, token = '' }) => {
      if (!merchantId) throw new Error('Missing merchant id')
      if (!token) throw new Error('Missing sso token')

      return buildUrl({
        url: 'https://dashspagarme.canny.io/feature-requests',
        queryString: {
          category: 'dash-pagarme-20',
          selectedCategory: 'dash-pagarme-20',
          companyID: merchantId,
          ssoToken: token
        }
      })
    }
  },
  SHOP: {
    subscription: {
      url: (merchantId, accountId) => {
        if (!merchantId) throw new Error('Missing merchantId')
        if (!accountId) throw new Error('Missing accountId')

        return buildUrl({
          url: process.env.VUE_APP_SHOP_URL,
          pathParams: `${merchantId}/${accountId}/subscription`
        })
      }
    },
    items: {
      url: (merchantId, accountId) => {
        if (!merchantId) throw new Error('Missing merchantId')
        if (!accountId) throw new Error('Missing accountId')

        return buildUrl({
          url: process.env.VUE_APP_SHOP_URL,
          pathParams: `${merchantId}/${accountId}/items`
        })
      }
    },
    url: (merchantId, accountId) => {
      if (!merchantId) throw new Error('Missing merchantId')
      if (!accountId) throw new Error('Missing accountId')

      return buildUrl({
        url: process.env.VUE_APP_SHOP_URL,
        pathParams: `${merchantId}/${accountId}`
      })
    }
  },
  HUB: {
    url: (merchantId, accountId) => {
      if (!merchantId) throw new Error('Missing merchantId')
      if (!accountId) throw new Error('Missing accountId')

      return buildUrl({
        url: process.env.VUE_APP_HUB_URL,
        queryString: {
          merchant_id: merchantId,
          account_id: accountId
        }
      })
    }
  },
  RISK: {
    url: (merchantId, accountId) => {
      if (!merchantId) throw new Error('Missing merchantId')
      if (!accountId) throw new Error('Missing accountId')

      return buildUrl({
        url: process.env.VUE_APP_URL_RISK,
        pathParams: `${merchantId}/${accountId}`
      })
    }
  },
  COMPANY: {
    url: merchantId => {
      if (!merchantId) throw new Error('Missing merchantId')

      return buildUrl({
        url: process.env.VUE_APP_COMPANY_URL,
        pathParams: `${merchantId}`
      })
    }
  },
  HELP: {
    url: (pathParams = '') => {
      return buildUrl({
        url: process.env.VUE_APP_URL_HELP,
        pathParams: pathParams
      })
    }
  },
  SITE: {
    blog: {
      url: (pathParams = '') => {
        return buildUrl({
          url: `${process.env.VUE_APP_URL_SITE}blog`,
          pathParams: pathParams
        })
      }
    },
    url: (pathParams = '') => {
      return buildUrl({
        url: process.env.VUE_APP_URL_SITE,
        pathParams: pathParams
      })
    }
  },
  DOCUMENTATION: {
    url: (pathParams = '') => {
      return buildUrl({
        url: `${process.env.VUE_APP_URL_DOCUMENTATION}docs`,
        pathParams: pathParams
      })
    }
  },
  CHARGEBACK_RECOMMENDATION_DOC: {
    url: () => {
      return buildUrl({
        url: `${process.env.VUE_APP_URL_CHARGEBACK_RECOMMENDATION_DOC}`
      })
    }
  },
  FAQ: {
    CONFIGURACOES_AUTENTICACAO_EM_DOIS_FATORES: {
      url: `${process.env.VUE_APP_FAQ_CONFIGURACOES_AUTENTICACAO_EM_DOIS_FATORES_URL}`
    },
    COMO_IDENTIFICAR_O_MOTIVO_DA_FALHA_EM_UMA_COBRANÇA: {
      url: `${process.env.VUE_APP_FAQ_COMO_IDENTIFICAR_O_MOTIVO_DA_FALHA_EM_UMA_COBRANCA_URL}`
    },
    COMO_CONSULTAR_A_CHAVE_SECRETA_E_CHAVE_PUBLICA: {
      url: `${process.env.VUE_APP_FAQ_COMO_CONSULTAR_A_CHAVE_SECRETA_E_CHAVE_PUBLICA_URL}`
    },
    SHOP_GERACAO_DE_LINK_DO_CHECKOUT: {
      url: `${process.env.VUE_APP_FAQ_SHOP_GERACAO_DE_LINK_DO_CHECKOUT_URL}`
    },
    CONFIGURACOES_USUARIOS: {
      url: `${process.env.VUE_APP_FAQ_CONFIGURACOES_USUARIOS_URL}`
    },
    DASHBOARD_COMO_ESTORNAR_UMA_COBRANCA: {
      url: `${process.env.VUE_APP_FAQ_DASHBOARD_COMO_ESTORNAR_UMA_COBRANCA_URL}`
    },
    FATURAMENTO_QUAIS_SAO_AS_MINHAS_TAXAS: {
      url: `${process.env.VUE_APP_FAQ_FATURAMENTO_QUAIS_SAO_AS_MINHAS_TAXAS_URL}`
    }
  },
  CATEGORYS: {
    MODULOS_E_PLATAFORMAS: {
      url: `${process.env.VUE_APP_CATEGORY_MODULOS_E_PLATAFORMAS_URL}`
    },
    FATURAMENTO: {
      url: `${process.env.VUE_APP_CATEGORY_FATURAMENTO_URL}`
    },
    MANUAL_DASHBOARD: {
      url: `${process.env.VUE_APP_CATEGORY_MANUAL_DASHBOARD_URL}`
    },
    SAQUES_RECEBIMENTOS_E_ANTECIPACAO: {
      url: `${process.env.VUE_APP_CATEGORY_SAQUES_RECEBIMENTOS_E_ANTECIPACAO_URL}`
    },
    FUNCIONALIDADES: {
      url: `${process.env.VUE_APP_CATEGORY_FUNCIONALIDADES_URL}`
    },
    SEGURANCA: {
      url: `${process.env.VUE_APP_CATEGORY_SEGURANCA_URL}`
    }
  },
  PHYSICAL_TRACKING: {
    MOBI: { url: 'https://mobi.com.br/entrega' },
    'LOGGI NACIONAL': { url: 'https://www.loggi.com/rastreador/' },
    CORREIOS: { url: 'https://rastreamento.correios.com.br/app/index.php' }
  }
}

export { EXTERNAL_ENDPOINTS }
