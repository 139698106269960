import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import routeDefault from './default.route'
import routeMaintenance from './maintenance.route'
import routeMerchant from './merchant.route'
import routeMain from './main.route'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkExactActiveClass: '_active',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: [
    routeMerchant,
    routeMain,
    routeDefault,
    routeMaintenance,
    () => ({
      path: '*',
      redirect: { name: 'home' }
    })
  ].map(item => item(store))
})

export default router
