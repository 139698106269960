import { isSellerUserRole } from '@/enums/user-role.enum'

const routeOrders = store => ({
  path: 'orders/',
  name: 'orders',
  component: () =>
    import(
      /* webpackChunkName: "orders" */ '@/views/Payments/Orders/Orders.vue'
    ),
  beforeEnter: (to, from, next) => {
    const { merchant_id, account_id } = to.params

    if (isSellerUserRole(store.getters.userRole.key)) {
      // eslint-disable-next-line
      next({ path: `/${merchant_id}/${account_id}/payment-links` })
      return
    }

    if (!store.getters.permissionIsEnabled('order:search')) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }

    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.sales.root',
      current: 'breadcrumb.sales.orders'
    }
  }
})

const routeOrdersDetail = store => ({
  path: 'orders/:id',
  name: 'ordersDetail',
  component: () =>
    import(
      /* webpackChunkName: "ordersdetail" */ '@/views/Payments/Orders/OrdersDetail.vue'
    ),
  beforeEnter: (to, from, next) => {
    const { merchant_id, account_id } = to.params

    if (isSellerUserRole(store.getters.userRole.key)) {
      // eslint-disable-next-line
      next({ path: `/${merchant_id}/${account_id}/payment-links` })
      return
    }

    if (!store.getters.permissionIsEnabled('order:get')) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }

    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.sales.root',
      parents: [
        {
          name: 'breadcrumb.sales.orders',
          routeName: 'orders'
        }
      ],
      current: 'breadcrumb.sales.orders-detail'
    }
  }
})

export default [routeOrders, routeOrdersDetail]
