const routeTrf = store => ({
  path: 'trf/',
  name: 'trf',
  component: () =>
    import(/* webpackChunkName: "trf" */ '@/views/Payments/TRF/TRF.vue'),
  beforeEnter: (to, from, next) => {
    if (store.getters.permissionIsEnabled('trf:search')) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.sales.root',
      current: 'breadcrumb.sales.trf'
    }
  }
})

export default routeTrf
