import { someActionsPermissionIsEnabled } from '.'
import {
  isSettingsAccountEnabled,
  isSettingsAccountGetEnabled
} from '@/actions-permission/settings-account.permission'
import { isSettingsGatewaysEnabled } from '@/actions-permission/settings-gateways.permission'
import { isSettingsPaymentsEnabled } from '@/actions-permission/settings-payments.permission'
import { isSettingsWebhooksConfigEnabled } from '@/actions-permission/settings-webhooks-config.permission'

export const SETTINGS_ACTIONS_ENUM = {
  notificationsView: 'settings.notifications:view',
  guaranteedCancelationView: 'settings.guaranteed_cancelation:view',
  checkoutView: 'settings.checkout:view',
  splitView: 'settings.split:view',
  subscriptionsView: 'settings.subscriptions:view',
  attemptsSearch: 'settings.attempts:search',
  cardUpdaterview: 'settings.card_updater:view',
  chargeAttemptView: 'settings.charge_attempt:view',
  ordersView: 'settings.orders:view'
}

const SETTINGS_FUNCTIONALITIES_ACTIONS = [
  SETTINGS_ACTIONS_ENUM.notificationsView,
  SETTINGS_ACTIONS_ENUM.guaranteedCancelationView,
  SETTINGS_ACTIONS_ENUM.checkoutView,
  SETTINGS_ACTIONS_ENUM.splitView
]

const SETTINGS_RECURRENCE_ACTIONS = [
  SETTINGS_ACTIONS_ENUM.subscriptionsView,
  SETTINGS_ACTIONS_ENUM.attemptsSearch,
  SETTINGS_ACTIONS_ENUM.cardUpdaterview
]

const SETTINGS_ORDER_CONFIG_ACTIONS = [
  SETTINGS_ACTIONS_ENUM.chargeAttemptView,
  SETTINGS_ACTIONS_ENUM.ordersView
]

// FunctionalitiesConfig

const isSettingsFunctionalitiesEnabled = permissionIsEnabledFn => {
  return someActionsPermissionIsEnabled(
    SETTINGS_FUNCTIONALITIES_ACTIONS,
    permissionIsEnabledFn
  )
}

const isSettingsFunctionalitiesNotificationsViewEnabled =
  permissionIsEnabledFn => {
    return permissionIsEnabledFn(SETTINGS_ACTIONS_ENUM.notificationsView)
  }

const isSettingsFunctionalitiesGuaranteedCancelationViewEnabled =
  permissionIsEnabledFn => {
    return permissionIsEnabledFn(
      SETTINGS_ACTIONS_ENUM.guaranteedCancelationView
    )
  }

const isSettingsFunctionalitiesCheckoutViewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_ACTIONS_ENUM.checkoutView)
}

const isSettingsFunctionalitiesSplitViewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_ACTIONS_ENUM.splitView)
}

// RecurrenceConfig

const isSettingsRecurrenceEnabled = permissionIsEnabledFn => {
  return someActionsPermissionIsEnabled(
    SETTINGS_RECURRENCE_ACTIONS,
    permissionIsEnabledFn
  )
}

const isSettingsRecurrencesubscriptionsViewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_ACTIONS_ENUM.subscriptionsView)
}

const isSettingsRecurrenceAttemptsSearchEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_ACTIONS_ENUM.attemptsSearch)
}

const isSettingsRecurrenceCardUpdaterviewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_ACTIONS_ENUM.cardUpdaterview)
}

// OrdersConfig

const isSettingsOrderConfigEnabled = permissionIsEnabledFn => {
  return someActionsPermissionIsEnabled(
    SETTINGS_ORDER_CONFIG_ACTIONS,
    permissionIsEnabledFn
  )
}

const isSettingsOrderConfigChargeAttemptViewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_ACTIONS_ENUM.chargeAttemptView)
}

const isSettingsOrderConfigOrdersViewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_ACTIONS_ENUM.ordersView)
}

// showSettingsSection

const isSettingsSectionEnabled = (
  permissionIsEnabledFn,
  componentIsEnabledFn
) => {
  return (
    ((isSettingsAccountEnabled(permissionIsEnabledFn) ||
      isSettingsGatewaysEnabled(permissionIsEnabledFn) ||
      isSettingsPaymentsEnabled(permissionIsEnabledFn) ||
      isSettingsFunctionalitiesEnabled(permissionIsEnabledFn) ||
      isSettingsRecurrenceEnabled(permissionIsEnabledFn) ||
      isSettingsOrderConfigEnabled(permissionIsEnabledFn)) &&
      componentIsEnabledFn('sidemenu', 'settings') &&
      isSettingsAccountGetEnabled(permissionIsEnabledFn)) ||
    (isSettingsWebhooksConfigEnabled(permissionIsEnabledFn) &&
      componentIsEnabledFn('sidemenu', 'settings'))
  )
}

export {
  isSettingsFunctionalitiesEnabled,
  isSettingsFunctionalitiesNotificationsViewEnabled,
  isSettingsFunctionalitiesGuaranteedCancelationViewEnabled,
  isSettingsFunctionalitiesCheckoutViewEnabled,
  isSettingsFunctionalitiesSplitViewEnabled,
  isSettingsRecurrenceEnabled,
  isSettingsRecurrencesubscriptionsViewEnabled,
  isSettingsRecurrenceAttemptsSearchEnabled,
  isSettingsRecurrenceCardUpdaterviewEnabled,
  isSettingsOrderConfigEnabled,
  isSettingsOrderConfigChargeAttemptViewEnabled,
  isSettingsOrderConfigOrdersViewEnabled,
  isSettingsSectionEnabled
}
