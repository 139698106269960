export const SETTINGS_WEBHOOKS_CONFIG_ACTIONS_ENUM = {
  view: 'settings.webhooks:view',
  create: 'settings.webhooks:create',
  edit: 'settings.webhooks:edit'
}

const isSettingsWebhooksConfigEnabled = (
  permissionIsEnabledFn,
  componentIsEnabledFn
) => {
  return (
    permissionIsEnabledFn(SETTINGS_WEBHOOKS_CONFIG_ACTIONS_ENUM.view) &&
    componentIsEnabledFn('sidemenu', 'webhookConfig')
  )
}
const isSettingsWebhooksConfigCreateEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_WEBHOOKS_CONFIG_ACTIONS_ENUM.create)
}

const isSettingsWebhooksConfigEditEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_WEBHOOKS_CONFIG_ACTIONS_ENUM.edit)
}
export {
  isSettingsWebhooksConfigEnabled,
  isSettingsWebhooksConfigCreateEnabled,
  isSettingsWebhooksConfigEditEnabled
}
