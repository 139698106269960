const FILE_VALIDATION_ERROR_ENUM = {
  TRANSACTION_FILE_ERROR: 'TRANSACTION_FILE_ERROR',
  EXTENSION_FILE_ERROR: 'EXTENSION_FILE_ERROR',
  SIZE_FILE_ERROR: 'SIZE_FILE_ERROR',
  TRANSACTION_FILE_ERROR_COMPANY: 'TRANSACTION_FILE_ERROR_COMPANY',
  DISPUTE_BLOCKED_ERROR: 'DISPUTE_BLOCKED_ERROR'
}

const CHARGEBACK_FIELDS_ENUM = {
  TRANSACTION_ID: 'transactionId',
  AMOUNT: 'amount',
  CUSTOMER_DOCUMENT: 'customerDocument',
  TRANSACTION_DATE: 'transactionDate',
  EMAIL: 'email',
  CARD_HOLDER_NAME: 'cardHolderName',
  CARD_FIRST_DIGITS: 'cardFirstDigits',
  CARD_LAST_DIGITS: 'cardLastDigits',
  CARD_BRAND: 'cardBrand'
}

const CHARGEBACK_STATUS_ENUM = {
  MERCHANT_EVIDENCE_UNDER_REVIEW: {
    key: 'MERCHANT_EVIDENCE_UNDER_REVIEW',
    label: 'Documentação em análise',
    code: 20
  },
  MERCHANT_EVIDENCE_DEADLINE_EXPIRED: {
    key: 'MERCHANT_EVIDENCE_DEADLINE_EXPIRED',
    label: 'Prazo excedido',
    code: 40
  },
  WAITING_ACQUIRER_SECOND_PRESENTMENT_PROCESSING: {
    key: 'WAITING_ACQUIRER_SECOND_PRESENTMENT_PROCESSING',
    label: 'Em disputa',
    code: 30
  },
  WAITING_MERCHANT_EVIDENCES: {
    key: 'WAITING_MERCHANT_EVIDENCES',
    label: 'Aguardando documentação',
    code: 10
  },
  DEADLINE_EXPIRED: {
    key: 'DEADLINE_EXPIRED',
    label: 'Prazo excedido',
    code: 91
  },
  WON: {
    key: 'WON',
    label: 'Disputa ganha',
    code: 90
  },
  LOST: {
    key: 'LOST',
    label: 'Disputa perdida',
    code: 92
  }
}

const FINALIZED_STATUS_ENUM = {
  [CHARGEBACK_STATUS_ENUM.WON.code]: CHARGEBACK_STATUS_ENUM.WON.label,
  [CHARGEBACK_STATUS_ENUM.LOST.code]: CHARGEBACK_STATUS_ENUM.LOST.label,
  [CHARGEBACK_STATUS_ENUM.DEADLINE_EXPIRED.code]:
    CHARGEBACK_STATUS_ENUM.DEADLINE_EXPIRED.label,
  [CHARGEBACK_STATUS_ENUM.MERCHANT_EVIDENCE_DEADLINE_EXPIRED.code]:
    CHARGEBACK_STATUS_ENUM.MERCHANT_EVIDENCE_DEADLINE_EXPIRED.label
}

const isFinalizedStatus = value => !!FINALIZED_STATUS_ENUM[value]

const isTransactionFileError = value =>
  value.toLowerCase() ===
  FILE_VALIDATION_ERROR_ENUM.TRANSACTION_FILE_ERROR.toLowerCase()

const isExtensionFileFileError = value =>
  value.toLowerCase() ===
  FILE_VALIDATION_ERROR_ENUM.EXTENSION_FILE_ERROR.toLowerCase()

const isSizeFileError = value =>
  value.toLowerCase() ===
  FILE_VALIDATION_ERROR_ENUM.SIZE_FILE_ERROR.toLowerCase()

const isTransactionFileErrorCompany = value =>
  value.toLowerCase() ===
  FILE_VALIDATION_ERROR_ENUM.TRANSACTION_FILE_ERROR_COMPANY.toLowerCase()

const isBlockedError = value =>
  value.toLowerCase() ===
  FILE_VALIDATION_ERROR_ENUM.DISPUTE_BLOCKED_ERROR.toLowerCase()

const isDisputeStatusWon = value => value === CHARGEBACK_STATUS_ENUM.WON.code

const isDisputeStatusLost = value => value === CHARGEBACK_STATUS_ENUM.LOST.code

const isDisputeStatusWaitingForEvidences = value =>
  value === CHARGEBACK_STATUS_ENUM.WAITING_MERCHANT_EVIDENCES.code

const isDisputeStatusDeadlineExpired = value =>
  value === CHARGEBACK_STATUS_ENUM.DEADLINE_EXPIRED.code

const isDisputeStatusInProgress = value =>
  value ===
  CHARGEBACK_STATUS_ENUM.WAITING_ACQUIRER_SECOND_PRESENTMENT_PROCESSING.code

const isDisputeStatusFailedToSendValidDocument = value =>
  value === CHARGEBACK_STATUS_ENUM.MERCHANT_EVIDENCE_DEADLINE_EXPIRED.code

const isDisputeStatusUnderReview = value =>
  value === CHARGEBACK_STATUS_ENUM.MERCHANT_EVIDENCE_UNDER_REVIEW.code

export {
  FILE_VALIDATION_ERROR_ENUM,
  CHARGEBACK_STATUS_ENUM,
  CHARGEBACK_FIELDS_ENUM,
  FINALIZED_STATUS_ENUM,
  isFinalizedStatus,
  isTransactionFileError,
  isExtensionFileFileError,
  isSizeFileError,
  isTransactionFileErrorCompany,
  isBlockedError,
  isDisputeStatusWon,
  isDisputeStatusLost,
  isDisputeStatusWaitingForEvidences,
  isDisputeStatusDeadlineExpired,
  isDisputeStatusInProgress,
  isDisputeStatusFailedToSendValidDocument,
  isDisputeStatusUnderReview
}
