const KYC_STATUS_REASON_ENUM = {
  WAITING_ANALYSIS: 'waiting_analysis',
  IN_ANALYSIS: 'in_analysis',
  ADDITIONAL_DOCUMENTS_REQUIRED: 'additional_documents_required',
  OK: 'ok',
  REJECTED_BY_RISK: 'rejected_by_risk',
  FULLY_DENIED: 'fully_denied'
}

const KYC_STATUS_ENUM = {
  PENDING: 'pending',
  APPROVED: 'approved',
  PARTIALLY_DENIED: 'partially_denied',
  DENIED: 'denied',
  DENIED_BY_RISK: 'denied_by_risk'
}

const KYC_STATUS_PRIORITY_LIST = [
  KYC_STATUS_ENUM.DENIED,
  KYC_STATUS_ENUM.DENIED_BY_RISK,
  KYC_STATUS_ENUM.PARTIALLY_DENIED,
  KYC_STATUS_ENUM.PENDING
]

const KYC_ERROR_MESSAGES = {
  KYC_NOT_FOUND: 'KYC_NOT_FOUND',
  KYC_STATUS_NOT_ENABLED: 'KYC_STATUS_NOT_ENABLED'
}

const isWaitingAnalysis = value =>
  value?.toLowerCase() === KYC_STATUS_REASON_ENUM.WAITING_ANALYSIS.toLowerCase()
const isAdditionalDocumentsRequired = value =>
  value?.toLowerCase() ===
  KYC_STATUS_REASON_ENUM.ADDITIONAL_DOCUMENTS_REQUIRED.toLowerCase()
const isInAnalysis = value =>
  value?.toLowerCase() === KYC_STATUS_REASON_ENUM.IN_ANALYSIS.toLowerCase()
const isOk = value =>
  value?.toLowerCase() === KYC_STATUS_REASON_ENUM.OK.toLowerCase()
const isRejectedByRisk = value =>
  value?.toLowerCase() === KYC_STATUS_REASON_ENUM.REJECTED_BY_RISK.toLowerCase()
const isFullyDenied = value =>
  value?.toLowerCase() === KYC_STATUS_REASON_ENUM.FULLY_DENIED.toLowerCase()

const isPending = value =>
  value?.toLowerCase() === KYC_STATUS_ENUM.PENDING.toLowerCase()
const isApproved = value =>
  value?.toLowerCase() === KYC_STATUS_ENUM.APPROVED.toLowerCase()
const isDenied = value =>
  value?.toLowerCase() === KYC_STATUS_ENUM.DENIED.toLowerCase()
const isPartiallyDenied = value =>
  value?.toLowerCase() === KYC_STATUS_ENUM.PARTIALLY_DENIED.toLowerCase()
const isDeniedByRisk = value =>
  value?.toLowerCase() === KYC_STATUS_ENUM.DENIED_BY_RISK.toLowerCase()

const isKycNotFound = value => value === KYC_ERROR_MESSAGES.KYC_NOT_FOUND
const isKycStatusNotEnabled = value =>
  value === KYC_ERROR_MESSAGES.KYC_STATUS_NOT_ENABLED

export {
  KYC_STATUS_ENUM,
  KYC_STATUS_REASON_ENUM,
  KYC_ERROR_MESSAGES,
  KYC_STATUS_PRIORITY_LIST,
  isWaitingAnalysis,
  isAdditionalDocumentsRequired,
  isInAnalysis,
  isOk,
  isRejectedByRisk,
  isDeniedByRisk,
  isFullyDenied,
  isPartiallyDenied,
  isPending,
  isApproved,
  isDenied,
  isKycNotFound,
  isKycStatusNotEnabled
}
