const routeMerchant = store => ({
  path: '/:merchant_id',
  name: 'merchant',
  component: () => import('@/components/project/templates/Motherboard.vue'),
  beforeEnter: async (to, from, next) => {
    if (store.getters.maintenance) {
      next({ name: 'maintenance' })
      return
    }

    const { merchant_id } = to.params
    const params = { merchant_id }

    if (!merchant_id.includes('merch_')) {
      next({ name: 'default' })
      return
    }

    if (!store.getters.merchantSummary) {
      await store.dispatch('GET_MERCHANT_SUMMARY', merchant_id)
    }

    if (!store.getters.profile) {
      const { merchant } = await store.dispatch('GET_AUTHORIZATION', params)

      if (!merchant) {
        store.commit('toggleGlobalError', {
          status: true,
          code: 404
        })
        return
      }

      return next({
        path: `/${params.merchant_id}/${merchant.account.id}/`
      })
    }
  }
})

export default routeMerchant
