export const CHARGEBACK_COMPANIES = [
  '627bea38da6662ea342b0443', // STG  - Account test BFF
  '6331fa388d0a4b0afe2ba0b8', // PROD - ADG Ferreira Negócios LTDA
  '632caba281b4660828117b12', // PROD - ESSENTIAL PARFUMS
  '63a1bb9ea0128c547e28a4cb', // PROD - A potiguar - site
  '627c117a30c4e4002afd253d', // PROD - Use Shop Ju
  '61b8da33630b8d00135972ed', // PROD - Conxa
  '62826fe80f1b880029944a2e', // PROD - reconhece.vc
  '61a92fa8d5a41e001d2b4928', // PROD - GRAMMD LOJA
  '621699e798e95e00134dcb4c', // PROD - ROCHA SOLUÇÕES TECNOLOGICAS
  '61b0ac46148f2000198dbd36', // PROD - Agua de praia
  '617c416cde75aa0012eb5a04', // PROD - CDB Design
  '629ff91bc750a7001d4ecab9', // PROD - Lucca customs
  '622a49c3bf1932001980bbae', // PROD - People Fit
  '5f6a244f36075642cfb01762', // PROD - mhkcloset
  '61143a234a460200185bb5e8', // PROD - AM LUX LTDA
  '632bac1e59afb0e1a2bde57e', // PROD - Panigale Jeans
  '62852bc34cb2f5001dd4c079', // PROD - Tchica Shopping Guararapas
  '61ae08281009080018232a5e', // PROD - NICHOOS TECNOLOGIA LTDA
  '6036b7a6fc5fa000191ebee7', // PROD - VFARM COSMÉTICOS PRODUTOS NATURAIS EIRELI
  '6268484db5bede0011844792', // PROD - MMC INDUSTRIA DE PRODUTOS NUTRACEUTICOS LTDA
  '624715941c5b620019c7bac0', // PROD - DOCILE SUDESTE - COMERCIO DE PRODUTOS ALIMENTICIOS LTDA
  '62e41d803ce3f400292f787e', // PROD - PATIO SAO PAULO INTERMEDIACAO DE NEGOCIOS ELETRONICOS LTDA
  '628d2076837a88001df430bf', // PROD - LAYLLA LORRANA CRUZ DE CARVALHO 01240752156
  '6220f552eb2b6b0018e62642', // PROD - Platinum indústria de confecções LTDA
  '6182747774fd4f001978f8fe', // PROD - Galpao oriental eireli
  '62f6bf897241ff001d4eade8', // PROD - S.A.F.BOTAFOGO
  '6228b072829a5000171bdcea', // PROD - DEISE GONCALES DE LIMA 22331737835
  '609e8d3723a6df0012433ead', // PROD - DECOR COLORS TINTAS LTDA
  '5890ea90896bf69a61592137', // PROD - DTC TREINAMENTOS & CONSULTORIA LTDA - EPP
  '5f74f61e9a966c4829a21676', // PROD - VALEMOBI CONSULTORIA EMPRESARIAL SA
  '628bcdde183a45001dcc80de', // PROD - JEAN LUCAS SANTOS XAVIER
  '621fc0e242aa9a0019d3ac95', // PROD - MARIA DAS BLUSINHAS LTDA
  '628be3cd946b40001d9de239', // PROD - W A DISTRIBUIDORA LTDA
  '634055b623b5841a4635c401', // PROD - DR PACK EMBALAGENS EIRELI
  '62d5c5a917c7a7001dadf2d1', // PROD - RITA NARIKAWA
  '614206916b80080018aad5e6', // PROD - ANDREA ALMEIDA DA SILVA SANTOS 37567521865
  '6290e7cbf1200f001d36c4b9', // PROD - FELIPE PINHEIRO DE SOUZA ME
  '6324e73fe4c6cd16233559d7', // PROD - NAYARA PEREIRA DA SILVA
  '6155d662a17f000012d8fde3', // PROD - EBENEZER COMERCIO ATACADISTA E VAREJISTA LTDA
  '622f96734484f50011253cba', // PROD - KAPEH COSMETICOS LTDA
  '63207ed9f910df2c39e2b21d', // PROD - STERILY BRASIL INTERMEDIACAO DE NEGOCIOS ELETRONICOS LTDA
  '6352dd348615ec788865b7e0', // PROD - LETICIA MATEUS LEMES
  '626fda82ac6fcf001954f3df', // PROD - PICO SHOP LTDA
  '61ae696aba936800194ee2ef', // PROD - WEV EMPREENDIMENTOS COMERCIAIS EIRELI
  '6227c351347b7b00133175f2', // PROD - PATIO SAO PAULO INTERMEDIACAO DE NEGOCIOS ELETRONICOS LTDA
  '62bb5a2ac67995001e9ec970', // PROD - JONATHAS SCARPIONI DO LAGO LTDA
  '61a7b29310e33200196ea45b', // PROD - DFP SOLUCOES EM TI LTDA
  '63038b14cdd3b5001eb623f9', // PROD - CASA & VIDEO RIO DE JANEIRO SA
  '604fa47a2681610018d0c367', // PROD - MOTOROLA MOBILITY COMERCIO DE PRODUTOS ELETRONICOS LTDA
  '62433dd330a03300198afd57', // PROD - AFEET FRANQUIAS LTDA
  '614c897cadfa35001c96691d', // PROD - MUNDO INFANTIL STORE
  '6290e90cb7b95a001d1f0709', // PROD - SHOP DELLA LTDA
  '615f094b89614b0011ba6d7b', // PROD - LESEN LIVRARIA LTDA
  '62dff50573070c002947466c', // PROD - EFICE SOLUCOES LTDA
  '62d802ef02e197001e58ea5d', // PROD - VIRTUAL TICKET LTDA
  '623c7e4921038600117aaba3', // PROD - Lojas Le biscuit S / A
  '62db1d8f746556001d45b95c', // PROD - JMF COMERCIO DE ROUPAS LTDA
  '5fab004675993f001038043a', // PROD - NOVELINO ATACADO DE ESTIVAS E CEREAIS EIRELI
  '62fe8d73cdd3b5001eb61b33', // PROD - YEAPPS DESENVOLVIMENTO DE SOFTWARE LTDA
  '62ebc598c713e4001dcbd983', // PROD - REBORN SERVICOS DE TECNOLOGIA DA INFORMACAO LTDA
  '627139c2b70b9e0012df2b68', // PROD - PENSA ENTRETENIMENTO LTDA
  '5ef211be1e16f2739e5fc28d', // PROD - RCVL PORTAL DE CONTEÚDO E DESENVOLVIMENTO WEB LTDA
  '62ec2a1df41bf6001e332a50', // PROD - B2B SPORTS COMERCIO DE PRODUTOS LTDA
  '62139f9bb9aeb30019c8285b', // PROD - Daniel Ruiz Rissato Me
  '62fce5ddc24d6d001dbe6540', // PROD - CAVI MODAS EIRELI
  '58d55c71997ad51a632fc8d2', // PROD - Banco Nacional de Empregos
  '610c51cb3f03f20012e24b13', // PROD - Katarze Comércio Digital Ltda
  '5f15efa9528d7c11fb0f89b9', // PROD - EI BELEZA SERVIÇOS EIRELI
  '5f3d544af736b868026b66ed', // PROD - LA GANEXA PRODUTOS ALIMENTÍCIOS EPP
  '61e9d3173a4a7b0011463b0b', // PROD - O PRIMO RICO MIDIA EDUCACIONAL E PARTICIPACOES LTDA
  '614b6ee7b30cd40019706bc4', // PROD - GOKURSOS INOVACOES EDUCACIONAIS LTDA
  '5faec8d7c020b70016ffba5c', // PROD - Banco Nacional de Empregos
  '61fd897df83977001282b4bd', // PROD - Rei Treinamentos LTDA
  '5f4667f96e359633e3d584c1', // PROD - Adoorei Solucoes para pagamentos e cashback ltda
  '5b29277766ac75f13050cabb', // PROD - STREET FORCE COMERCIO DE ROUPAS LTDA
  '632b41953fec9f6c99b6e6d7', // PROD - Saraiva e Siciliano S / A - Em Recuperação Judicial
  '629dfcbc634c2f0028ee0116', // PROD - SERBET - SISTEMA DE ESTACIONAMENTO VEICULAR DO BRASIL S.A
  '624c3c230d57b60019289805', // PROD - RODRIGO MARTINS EBERSOL
  '626c448c1e14e70019d4c8e5', // PROD - TRES CORACOES ALIMENTOS SA
  '60a8091e854ea000181b5c4c', // PROD - BUMBUM CREAM BRASIL & BARRIGUINHA CREAM COSMETICOS LTDA
  '624715941c5b620019c7bac0', // PROD - DOCILE SUDESTE - COMERCIO DE PRODUTOS ALIMENTICIOS LTDA
  '5eb00e1a6eceaf389bda7431', // PROD - bruna edini machado ramos me
  '61f2f0057ae71300127742d6', // PROD - AKDEMICA CURSOS E TREINAMENTOS LTDA
  '6228b072829a5000171bdcea', // PROD - DEISE GONCALES DE LIMA 22331737835
  '6036b7a6fc5fa000191ebee7', // PROD - VFARM COSMÉTICOS PRODUTOS NATURAIS EIRELI
  '626fedbff2d6f30018f4ef44', // PROD - VASTA DESIGN E ADMINISTRACAO WEB LTDA
  '6149f3f280d9b30012685910', // PROD - VONO TELECOM LTDA
  '623b318cfb889e00114a8a80', // PROD - Hanesbrands Brasil Têxtil Ltda
  '6182747774fd4f001978f8fe', // PROD - Galpao oriental eireli
  '6268484db5bede0011844792', // PROD - MMC INDUSTRIA DE PRODUTOS NUTRACEUTICOS LTDA
  '617716a4749bef00187caac6', // PROD - Food941 Alimentos LTDA
  '61f92332bb18000012809b78', // PROD - Lumine
  '60bfd92e22d7640013bc0c1f', // PROD - DRY COMPANY DO BRASIL TECNOLOGIA LTDA
  '62f13735ab8d3600285298ff', // PROD - G.DOS SANTOS MIRANDA COMERCIO VAREJISTA DE CALCADOS E CONFECCOE
  '61ddb2bbdb6d880012f55fff', // PROD - WIX BARTENDERS LTDA
  '622f37d3ef97300018071155', // PROD - Velotax Serviços de Tecnologia da Informação Ltda.
  '60da0749faa9ec00187554b7', // PROD - AN COMERCIO DIGITAL LTDA
  '628d2076837a88001df430bf', // PROD - LAYLLA LORRANA CRUZ DE CARVALHO 01240752156
  '61af4cf9b868a900195c508e', // PROD - HI GROUP LTDA
  '62322592fa3fd60018991528', // PROD - DR LAVA TUDO PRESTACAO DE SERVICOS LTDA
  '5a5795305eadd20f1a4e43cb', // PROD - ATOM TRADERS S.A.
  '627e64f3412f78001f1ff3c9', // PROD - DAYTONA ATIVIDADES DE INTERNET LTDA.
  '61771c06ad0f19001858f01a', // PROD - Macla Alimentos LTDA
  '601dc84fea82e60012e72d29', // PROD - Ferretto Cursos On line LTDA
  '62f3f3dd6d61d6001d4d3a70', // PROD - CRM INDUSTRIA E COMERCIO DE ALIMENTOS LTDA
  '6173077bcd0dc4001115a408', // PROD - RL Multimarcas Eireli
  '61d4b3fddf1f840013a92d8e', // PROD - EMPRESA BRASILEIRA DE COMERCIALIZACAO DE INGRESSOS S.A.
  '61faac0fae715600118df5d9', // PROD - VEGAN DO BRASIL INDUSTRIA DE COSMETICOS LTDA
  '5fca99f1ae51ab0016970b58', // PROD - Famix Distribuidora de Produtos Eireli
  '6183e0b3e83b8d00194f1202', // PROD - SHUI STORE LTDA
  '5c926ecd7ec3a67b025bd549', // PROD - WORC PLATAFORMA DIGITAL LTDA
  '62a3a5e8eb2cc1001eab72d8', // PROD - Waid Tecnologia da Informacao LTDA
  '60a80c39977a9b00190aef25', // PROD - HIDRATEI COSMETICOS LTDA
  '61b8a07d30f0a0001155c1dc', // PROD - Tiago Vinivius Nunes da Silva ME
  '5f10907a9971d95bbcb2e8a9', // PROD - VITASCIENCE FOODS LTDA
  '614ce72a82a47500125c6dd9', // PROD - G.C.Amorim Confecções
  '5e7e6dd0afa5275e41255c2f', // PROD - INCI SERVICOS DE APERFEICOAMENTO PROFISSIONAL LTDA
  '61af6feecaf1030019c99f08', // PROD - MONUMENTO SHOPPING CAR COMERCIO DE AUTOPEÇAS LTDA.
  '628bdd93dba634001e6bdd78', // PROD - SHOTGUN BRAZIL LTDA
  '5ace1b495a6fa6351ace9177', // PROD - Andreia dos Santos Pola - ME
  '57cf1c216f38d7f1242ae988', // PROD - FKV COMERCIO DE CALCADOS LTDA - ME
  '62e3e71817dedc00284f4f27', // PROD - LRC DA SILVA SERVICOS ADMINISTRATIVOS
  '5ed93765ae313644ac8e1852', // PROD - EmAudio Cursos para Concursos Ltda
  '62cdd97bdcf4ed001d6802b9', // PROD - SELIA - SERVICOS DE GESTAO EMPRESARIAL LTDA
  '62388e71e7ec580018748054', // PROD - CRM INDUSTRIA E COMERCIO DE ALIMENTOS LTDA
  '623daaaca83bc30012642104', // PROD - FCOM COMERCIO DE CALCADOS E ACESSORIOS LTDA
  '617714c84ed44c00185d7399', // PROD - Genco Alimentos LTDA
  '6173405db9b85c0019b67af9', // PROD - J.R dos Santos modas
  '62a9df66d68fa200296ee9c8', // PROD - GIOVANI MAGALHAES BARROS 52314264827
  '627550a555cd6e00111d81de', // PROD - Lf Comprinhas Confeccao e Comercio de Roupas Ltda
  '58209d41e94cc83f1b93317c', // PROD - JOÃO VANDERLEI VALLE EPP
  '5f6a244f36075642cfb01762', // PROD - MHK MODAS EIRELI
  '62791e84da720b001913f7fe', // PROD - PARA O ALTO E AVANTE MARKETING LTDA
  '61ddffd4419d580018ac31b4', // PROD - Davi Sousa de oliveira 39913731810
  '623b2fb037eb180012e40dac', // PROD - AMAR ASSISTENCIA EM SEGUROS S.A
  '610c4511396a6e0011421602', // PROD - JKN
  '61095d92e8bebe0019c8d425', // PROD - Rafael São José Vagliante - ME
  '617719d7cf471c0018a33864', // PROD - Food941 Alimentos LTDA
  '62b0aba407517e0028d73c59', // PROD - TECHMALHAS CONFECCOES LTDA
  '5fa30929faddc70010ca95e6', // PROD - N.R.BENNESBY COMÉRCIO DE UTENSÍLIOS DOMÉSTICOS LTDA
  '62d9cc791e0a29001ed7d8ea', // PROD - WBR INDUSTRIA E COMERCIO DE VESTUARIO LTDA.
  '61f80ce71e4d730011f1a42a', // PROD - CENTER EDUCACIONAL LTDA
  '61c4eaa78171220012491219', // PROD - Supremo TV Ensino Juridico Ltda
  '62ed74ffd5d0d5001f2ed387', // PROD - Cuidamos Farma Ltda
  '62324328ba6d8c0019a0a57a', // PROD - Resort farol das dunas eireli
  '61b762226538d90019af6e37', // PROD - Waid Tecnologia da Informacao LTDA
  '5f318f3a685cd85949cb3dba', // PROD - BORDADOS FREITAS & MUNIZ LTDA
  '61dc8592dc74d400114a2581', // PROD - Felipe Rafael da Cunha Araujo
  '62eabd65b36086001e3fa873', // PROD - J C F INDUSTRIA E COMERCIO LTDA
  '625d5d3ee656d90018d7eb3e', // PROD - MELVER SA
  '627d0db80a51930028d0b87b', // PROD - Empreendimentos JDS Ltda
  '62b4da23ee82c0001d9f27a5', // PROD - B.A.PLATAFORMA DE MARK.DIGITAL INT DE NEG.LTDA
  '6238bbbd638a110018f3227d', // PROD - R A PORFIRIO MARTINS CALCADOS EIRELI
  '61f18a177b21a800157a12eb', // PROD - CAPTAR TECNOLOGIA LTDA
  '6260677c05b47a0018229bd6', // PROD - CITRIKA ON LINE COMERCIO DE ROUPAS E CALCADOS LTDA
  '620a45683a03c2001813b9ea', // PROD - BELLE ARTI INDUSTRIA E COMERCIO LTDA
  '615c9f765085fe001130ad6a', // PROD - VISHFI SKATEBOARDS & STREETWEAR LTDA
  '6335c683a1be18128edf6b44', // PROD - 4SELET TECNOLOGIA SERVICOS E PAGAMENTOS LTDA
  '62de98c9766bfc002821cf46', // PROD - LSMC CURSOS E TREINAMENTOS LTDA
  '5fc7a4e5dcf189001016f1a5', // PROD - Abelha Rainha
  '5ebc577c4b27251f46644e1b', // PROD - Loja Dupla Carioca Comércio de Roupas e Acessórios Ltda
  '6284f67a0364a6001e842ae6', // PROD - DIFERENTE BRASIL LTDA
  '5bbfa1187b0a62a11f236167', // PROD - KONATURE COSMETICOS E PRODUTOS NATURAIS EIRELI
  '623b25b3a3f4090011376d52', // PROD - Select Comercio de Roupas LTDA
  '615b3dd57b51cf0018b1623c', // PROD - Ipsis Gráfica e Editora
  '617c575f6ab16100188c7322', // PROD - ART COCO E - COMMERCE COMÉRCIO DE JOIAS E BIJUTERIAS LTDA
  '60d0f485605f7a00183eacb7', // PROD - ONIVINO COMÉRCIO E DISTRIBUIÇÃO DE VINHOS LTDA
  '61b9e73105efe90018873c9c', // PROD - José victor de Assis martini
  '6206cb58f2bd44001170207f', // PROD - RML EVENTOS ESPORTIVOS LTDA
  '61d60800dc6c920011a606dd', // PROD - ROCHA E MACHADO REPRESENTACOES LTDA
  '6203cc71381e1f00111a1293', // PROD - FARMACIA NOSSA SENHORA DO ROSARIO LTDA
  '624b2d862cd1c20011737d5c', // PROD - DAMANNU COMERCIO CALÇADOS LTDA
  '618ebcc14aa24200191a8a58', // PROD - DIMAK APOIO ADMINISTRATIVO E CONSULTORIA EMPRESARIAL LTDA - EPP
  '5ada69bc887979721ee5f553', // PROD - Presc Viagens E Turismo Ltda.
  '62e2f3a94b1133001e52aa15', // PROD - WICK COMERCIO E CONFECCOES DE ROUPAS LTDA
  '6204213fcc6ed20012beaa90', // PROD - 4SELET TECNOLOGIA SERVICOS E PAGAMENTOS LTDA
  '61f92cecef2e4a001116781c', // PROD - MR OCKY COMERCIO E CONFECCAO LTDA
  '61718e1a4ec73c0012cee193', // PROD - PAY365 SERVIÇOS DE COBRANÇAS LTDA
  '62cdd55ac08f55001d379046', // PROD - ARTNARI LTDA
  '63052e673fc35e001e0f37dd', // PROD - CARTORIZE SERVICOS DIGITAIS LTDA
  '6039559ac53ef40019ad82ae', // PROD - Rjp Serafim Confeccoes EIRELI
  '622b6265c6ee680018968332', // PROD - LATTE COMERCIO DE ALIMENTOS EIRELI
  '624f3c9248701b0012eff034', // PROD - IB E - COMMERCE COMERCIO DE ARTIGOS E VESTUÁRIO LTDA
  '5ce7f51a7859b14b141fb06f', // PROD - LUCIANA RICCIO VAZ ME
  '622a639bb6fb5e001811cad1', // PROD - G P MACEDO COMERCIO DE PESCA
  '627bb36f0a51930028d0b094', // PROD - URB LAB COMERCIO DE ARTIGOS ESPORTIVOS LTDA
  '61e734994fd858001283baf9', // PROD - Arca soltec Soluções tecnologicas SA
  '6154e24d2993ce0012a5201a', // PROD - SURF SKATE COMERCIO VIRTUAL LTDA
  '62967932a61aff001e6a3316', // PROD - RSDECORE COMERCIO DE ARTIGOS DECORATIVOS LTDA
  '629e432d8f15bc001dbfdda1', // PROD - GABRIELA MADEIRA DE OLIVEIRA MEI
  '6286591cc0937f0029788b0f', // PROD - PERFUME SHOPPING BRASIL LTDA
  '624c8891992394001105e60b', // PROD - BRASIL PET COMERCIO E SERVICOS DE PET SHOP S / A
  '5c6de742ce3c2f26033b928f', // PROD - COMERCIO DE COSMÉTICOS E PERFUMES IMPORTADOS DE LUXO ZAILO LTDA
  '5f19de0322ca5017db58331b', // PROD - PREORDER COMERCIO DE ROUPAS LTDA
  '62cd67a6703982001e44be91', // PROD - CULLIGAN LATAM LTDA
  '568ac3763f4e27121c8c8799', // PROD - IT ART TECNOLOGIA LTDA
  '5e332a26e7001452f1178509', // PROD - C & P Consultoria Empresarial Eireli
  '5ed132c64136ba0a6ce82397', // PROD - Martins & Reis Comercialização Varejista de Calçados LTDA
  '61771b5c55c9d90018ae3e53', // PROD - Izamac Alimentos LTDA
  '619ff4ef4d31190014d24074', // PROD - CASA DO SOLDADOR LTDA
  '62557e79d9e85300110901ad', // PROD - A.C.COMERCIO DE CALCADOS LTDA
  '5f7e7391cb0eee3dbc91556d', // PROD - MANUAL INTERMEDIACAO DE NEGOCIOS LTDA
  '61717ed06c74640011690222', // PROD - Carolina Maimone Araújo ME
  '6244a551e115b60019470c52', // PROD - KORAL BRASIL CONFECÇÃO EIRELLI
  '61f2a29dec9a6e00112dfdd4', // PROD - RUDINEI CARDOSO
  '6228b35205973e001297551c', // PROD - Connectas Comércio de Software LTDA
  '6039091e0fcab000119a3a3e', // PROD - E - BOOKS FANTASTICOS LTDA
  '623cd797330b360019f1c774', // PROD - K2 COMERCIO DE CONFECCOES LTDA
  '62309de9fb07b7001135b31a', // PROD - Plátano Brasil Distribuidora e Exportadora Ltda
  '6266ac578f482b0018b952b0', // PROD - EVOLUTION COFFEE - COMERCIO DE PRODUTOS NATURAIS EIRELI
  '60f87c59864b35001153e5c9', // PROD - Gideon Pereira Barbosa Eireli
  '611d5b1c8b0ff00013dfb3bf', // PROD - GG BEAUTY E - COMMERCE DE BELEZA LTDA
  '61c1e4e5170bde001acded71', // PROD - GUILHERME GARCIA RENNO PEREIRA
  '61ae5dd64729ea001a678f6d', // PROD - EDi Brasil LLC
  '62a0c06021d47d0029480a08', // PROD - NADAES CONSULTORIA LTDA
  '616dd76c92c2f300128da7d3', // PROD - Luciano Trindade Cavalcante
  '621f991e390e120019a877d0', // PROD - Luana Machado Gomes ME
  '6295237254e50400287f7e17', // PROD - Drogaria Iguatemi LTDA
  '620aa73990e0230018814687', // PROD - BIG MIDIA EIRELI
  '614b652f6f13b40018372d05', // PROD - KAUE FREIRE DOS REIS
  '61780dc66822e20018fb9caf', // PROD - MVB COMERCIO DE BEBIDAS LTDA
  '629f972f125f4b001e68af2f', // PROD - EXPANDA VIAGENS E TURISMO LTDA
  '5e7592a60b76d639103b7a77', // PROD - MACLE Pagamentos
  '5d826028e5951844634b326d', // PROD - FEED S.A.
  '62fab6e592f9b5001df2fd2c', // PROD - CAIXA COLONIAL MARKETING E COMERCIO LTDA
  '628e410a352e3c001d9c188e', // PROD - All closet
  '61e5735e204959001867a45b', // PROD - PLASTICMB C DE MOVEIS ESTRUTURAS METALICAS E INFLAVEIS LTDA
  '53e8f5b5c01d5b9d5900007d', // PROD - BACKPACKER EDUCACAO E ENTRETENIMENTO E TECNOLOGIA LTDA
  '5f088941b2c3ec793d527ed1', // PROD - SQUARE 011 COMERCIO DE PRODUTOS E SERVICOS LTDA
  '61a11aa27931e00019d793f0', // PROD - MARIAS
  '61fd7e01f3aa490012bc6e3a', // PROD - MARIANA PERDOMO CONFEITARIA GOURMET EIRELI
  '62587871b9a6400019f96322', // PROD - RI TECNOLOGIA E SISTEMAS LTDA
  '616ebc1fcbfd490011ea8a9c', // PROD - HKM FARMACIA DE MANIPULACAO LTDA
  '61f2de5604d6aa0012cfa899', // PROD - FLAME COMERCIO DE CONFECCOES LTDA
  '62cf17e29d69da002950494e', // PROD - S S MENDES COMERCIO ATACADISTA DE COSMETICOS EIRELI
  '62e1460312961f002a983abc', // PROD - DAMATTA LTDA
  '5ef5026ad126e261bf2eec12', // PROD - Gabriela Briques Garcia 43024687858
  '6356d7c5fea6ae10810f78e0', // PROD - LCR COMÉRCIO DE MÓVEIS LTDA
  '6352dd348615ec788865b7e0', // PROD - LETICIA MATEUS LEMES
  '62c74b9a2d9e0e001ef6b0ff', // PROD - KB INVEST PLATAFORMA DE EDUCACAO E ANALISE DE INVESTIMENTOS LTDA
  '5ef4bd0a95a9b24ef92f206f', // PROD - Enkanto Cosméticos - LTDA
  '63053c43690663001e85f7f4', // PROD - JESSICA LEAL COMERCIO DE CALCADOS LTDA
  '62051d917400e70018eb45a5', // PROD - ZEEPLO TECNOLOGIA E SERVICOS S.A.
  '622be7881e56560018eb81f0', // PROD - GOOD VI COMERCIO E SERVICOS LTDA
  '618eb16715e8eb0018f093ff', // PROD - Produzza Textil
  '616f1ae63e776f0011eb4196', // PROD - LM INGRESSOS
  '5da8bfc1f26d287da57c6554', // PROD - DB Genética Serviços Laboratoriais
  '616d94c32f726700197e7dfb', // PROD - Hello Nutrition Comercio de Alimentos e Bebidas Eireli
  '625f13f81108bd0018a16c0e', // PROD - GUSTAVO HENRIQUE STACENCO 43665006805
  '5fbbb322ccdd67001778fd6f', // PROD - BETTERPB COMERCIO VAREJISTA DE PRODUTOS ALIMENTICIOS LTDA
  '624e04396e050c0012fb06c9', // PROD - PEALE INDUSTRIA E COMERCIO DE MOVEIS S / A
  '608b092021a8860018c124bc', // PROD - M2R comércio de variedades
  '61951b4e7006a300190d3e1e', // PROD - G.B.STRAPASSON & CIA LTDA
  '62974b82407973001ec6b3d7', // PROD - Khronos Industria comercio e serviço em eletrônica LTDA
  '620d73a5c21c5d0018f65951', // PROD - FEMINISCES BOUTIQUE LTDA
  '6171b94f82e23d0012d324c2', // PROD - KADURA POWER BANK COMERCIAL ELETRONICOS LTDA
  '62979b8afb84c0001ec375f3', // PROD - P3MA SERVICOS DE INTERNET LTDA
  '629e3ebb349a54001e3afdd7', // PROD - AMSTERDAM INDUSTRIA TEXTIL EIRELI
  '63178e08a1da10913a042b66', // PROD - BLKT COMERCIO DE CONFECCOES LTDA
  '617055313fa69d0011683f8a', // PROD - METRO & MEIO TEXTIL COMERCIO DE TECIDO LTDA
  '6168405505619200128d7dcf', // PROD - Relaflex Ind e Com de Colchoes Ltda
  '617718bac3348e0018fa1155', // PROD - Food941 Alimentos LTDA
  '590ba15cc0f4c4331759ec11', // PROD - DROGAMAXI LTDA ME
  '62ed283ada2258001db5ae83', // PROD - DAVID ROCHA EDUCACAO EIRELI
  '62a3abe4dd3999001e141424', // PROD - Conectabem Tecnologia Ltda
  '62a7956c347939001e0e55bc', // PROD - JULIANA MTS COMERCIO DE BIJUTERIAS E TREINAMENTOS LTDA
  '61e966994157c200195097e6', // PROD - Taggo Digital LTDA
  '5f8f45ba8fa73a0011adda9f', // PROD - GIDEON PEREIRA BARBOSA EIRELI
  '6169911ba024d20011ac63fc', // PROD - BRUNA TAVARES COSMETICOS LTDA
  '618bb325dbf8450018cd2199', // PROD - Spazzio Jeans Eireli ME
  '60c8bff6585af800132076d4', // PROD - R.R.Comercial Ltda
  '596952125dd42a4d0c446466', // PROD - YBR INTERNET EIRELI EPP
  '614b8847530332001161c6f5', // PROD - Pedro Guilherme Carusso Antunes
  '624d98a4c62fea0011eb6b15', // PROD - BARBA BRASIL EIRELI
  '5adf0cc27fa3249921c36733', // PROD - Instituto Neurosaber - Eireli
  '6331957ab2bb8187c2c02a2f', // PROD - STOTZ INDUSTRIA E COMERCIO DE CONFECCOES LTDA
  '61a0ed8cd65e6600199e33af', // PROD - CIA DO JEANS INDUSTRIA COMERCIO E IMPORTACAO LTDA
  '61293deaba6e680012ae4c77', // PROD - AIA COMERCIO VAREJISTA DE ROUPAS E CALCADOS LTDA
  '60fabb1378d8430011ca35e9', // PROD - Henrique pietzarka
  '622612e7d615920012663858', // PROD - COLOK JOIAS
  '6178345a45b92c0019ed49af', // PROD - VEGAN FARMÁCIA DE MANIPULAÇÃO LTDA
  '6169fb1720294c001802d396', // PROD - GoMakers Empreendimento Digitais
  '628bb780807c460029044607', // PROD - WAR SUNGLASSES EIRELI
  '5eb31e28062bca2a19c7681d', // PROD - GRAFFITI ART Eireli
  '628e8a50b1b94d001ef24b51', // PROD - Zion Farm Ltda
  '6176defbcd77770019399c85', // PROD - GENCO ALIMENTOS LTDA
  '62cf2ad2c80e880029cfe5d3', // PROD - BR KIDS COMERCIO LTDA
  '63207ed9f910df2c39e2b21d', // PROD - STERILY BRASIL INTERMEDIACAO DE NEGOCIOS ELETRONICOS LTDA
  '62e4569117dedc00284f5102', // PROD - BLK Performance
  '61a11698c37ff90018e8189c', // PROD - Lasso Lingerie Ind e Com de Confecção LTDA
  '623374811c22e80011af8129', // PROD - RHEROONLINE CONFECCOES LTDA
  '5f173500bbff67675affda15', // PROD - Arte Urbana Brasil Comércio e Importação Ltda
  '595a7822f05a2d8d75c8c368', // PROD - Bela Vista Enxovais LTDA
  '62445aade115b6001947093e', // PROD - Nathalia Huch Duarte
  '5db1ffefffe0056e5ebe6077', // PROD - COMERCIO DE INGREDIENTES ONLINE NEOVITA LTDA
  '601061bc1bf3140011bffa85', // PROD - ROGERIO TADEU BION JUNIOR ME
  '6169a9cda183550013f4d358', // PROD - Another Place Comercio de Roupas LTDA
  '60218aca79ef16001853e95c', // PROD - Andreia Alves de Souza
  '61a4ec787ad25d00196e4ac3', // PROD - Edson capelin
  '6102fba0ad38710011a57e19', // PROD - NMZ SHOP COMERCIO DE CONFECCOES LTDA
  '62698a43395528001253ca20', // PROD - INDÚSTRIA DE CALÇADOS JACOB
  '5eff808d342a6b165924bfaf', // PROD - jj modas fiaiz ltda
  '62b52d224951ed001e45bbb1', // PROD - J C LEGALIZAÇÃO DE EMPRESAS LTDA
  '60860e8212377c001a2c3ff2', // PROD - RBS CONSULTING LTDA
  '5f21d7a318aada12d90c9f53', // PROD - BE YOUR FUTURE - SAUDE E BEM ESTAR LTDA
  '629918e0ea5763001ddef9bd', // PROD - PRIME NET SPORTS LTDA
  '6303a3f71c3b07001d18c621', // PROD - BEAUTYGLAM COMERCIO DE COSMETICOS LTDA
  '609e5165a2434100125113a4', // PROD - WEMERSON SOUTO SILVA MEI
  '622110ec63b29a0019debc06', // PROD - Ville
  '5f9c554be68ede00115078a5', // PROD - Iguassu web soluçoes ltda
  '618d46ecb230e500184aec3b', // PROD - INFINITAMENTE MAIS DE DEUS TREINAMENTOS LTDA
  '62c457bb963d4d00285c661d', // PROD - VIA CAPUTO COMERCIO LTDA
  '5efea72ee33a1a2984744573', // PROD - Danilo Rodrigues Queiroz
  '6023f67af6269800199d2a8a', // PROD - Lexmar Ind.e Com.de Malhas Ltda.
  '621525a9e808d80018851b92', // PROD - ASTI SHOP LTDA
  '57765835c246e87f067f1a1d', // PROD - Instituto Cultural Aruanda
  '62ffc69d0de4ad001dac4670', // PROD - LINCOHN AGNER CURSOS ONLINE LTDA
  '624f1b5548701b0012efeea0', // PROD - KISS ONLINE PRODUTOS DE BELEZA LTDA
  '60d605ba82462000120e7b66', // PROD - One Way Store Comércio de Vestuário Ltda.
  '622127fb880f71001138f940', // PROD - D FREITAS CONFECÇÕES E COMÉRCIO DE ROUPAS ESPORTIVAS
  '5caf7106df1a6f612c09aca3', // PROD - Nafa Comercio de Produtos Naturais Ltda
  '6202da3f729f8b0019592670', // PROD - LOJA HIPSTER STORE VESTUARIO LTDA
  '61cc6ba553e2990012fe6e8d', // PROD - TECNOMOTOR DISTRIBUIDORA S.A.
  '626010fc0f073f0018892963', // PROD - Mirian dos Santos e Santos 07264274543
  '60cce49ff16f590011a6e310', // PROD - Alltribe Comercio de Roupas e Acessorios LTDA
  '607f16077ba889001157483b', // PROD - ESPELHO MEU BY ALYCIA COMERCIO DE ROUPAS LTDA
  '629a4297e6a034001d23c74b', // PROD - WEST FASHION ARTIGOS ESPORTIVOS LTDA
  '628e81da4e97b40029fa07a7', // PROD - NO BREAK AUTO EQUIPAMENTOS LTDA
  '623b7adad4a65b0011c18d3a', // PROD - Postei Software LTDA
  '6255c443344bbd0019f0c021', // PROD - MS Negócios e Companhia Digital Ltda
  '62d7f8665f48e9001dee2f10', // PROD - Dinamica Carioca Roupas e Acessórios EIRELI
  '5f21fe71e2c47741b2d39124', // PROD - Paula Veronica Ferrali Confecções
  '61434ba9a1477700129b34c7', // PROD - NELCI PEREIRA ALVES 74437160878
  '57bda0c2ae687eb006e7623c', // PROD - Zass E - commerce Ltda
  '633d97deff0b730b504ff266', // PROD - FRANQUIAS DOCG COMERCIO E IMPORTACAO DE PRODUTOS VETERINARIOS
  '61dee20d2895510019d24f38', // PROD - VERSATIL GLASSES COMERCIO DE OCULOS LTDA
  '623dfa084192e800125c0c6e', // PROD - Le Loyn Comercio de Cosmeticos LTDA
  '604a6eb737da2300132e25bd', // PROD - MATEUS AUGUSTO 07741060694
  '625f02d75781e2001bdd2abc', // PROD - MTL MODAS GYN LTDA
  '5feccbb1ee470000101bab85', // PROD - Collection LTDA
  '5db31117dfb515457fb6a70f', // PROD - Aliance Comércio de Cosméticos e Acessórios Eireli.
  '6154d9642e3ac80012312f9a', // PROD - Júlia da Silva Martins
  '615f6fe86ae4570011503c40', // PROD - Ronan Bringhenti Mello 12942005777
  '60ca542c5ae0e9001889c62f', // PROD - Morumby Hoteis Ltda
  '61eaec41e5f6a00012dac7d5', // PROD - NAPRATICAFIT INTERMEDIADOR DE NEGOCIOS LTDA
  '5c6c76e63929ea7577bd8663', // PROD - BRECHO DO FUTEBOL CAMISAS DE COLECAO LTDA
  '60f9a0368ef6e2001968ec9b', // PROD - HL Digital Comercio Eletronico Ltda
  '62f13a4754135700286f41d7', // PROD - R R GESTAO ADMINISTRATIVA LTDA
  '614c98fc853c2e001236479d', // PROD - Frad Indústria é Comercio de Calcados Ltda
  '5db2d9bcdfb515457fb6a644', // PROD - Farmácia Koerich LTDA
  '618d5f63c8c736001821c689', // PROD - MR EDUCACAO TECNOLOGIA LTDA
  '61859649af72220011c30200', // PROD - OLIVIA CHOI KIM CONFECCOES - EIRELI
  '61118d8015c0af0011e625b9', // PROD - JB ETIQUETAS EIRELI
  '619cfdfbe3ffd900124ce84b', // PROD - Casinha Bela Comércio e Importação Ltda
  '61e72ad43adc980011e7f53f', // PROD - UNIVERSO MEI SOLUÇÕES LTDA
  '62e8450b4b1133001e52b278', // PROD - INSTITUTO FLAKES LTDA
  '6297586e5f100f001e66752d', // PROD - COREBRANDS MODA LTDA
  '6233512b7b4a1400191a39c6', // PROD - TOUR OQFC INTERMEDIACOES LTDA
  '625475fa37445a00124e97c5', // PROD - ELITE SUPLEMENTOS NUTRICIONAIS
  '615f5705384d8b00196bb2ab', // PROD - CATTANI SUL TRANSPORTES E TURISMO LTDA
  '625d86a4acd02f0019ae964d', // PROD - Conectecenter Ltda
  '62680d6d3630c8001197d2d5', // PROD - MKX TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL LTDA
  '5dadf7ac4fedfe655e824b2a', // PROD - PRIT GESTAO DE NEGOCIOS LTDA.
  '5e8b97f1a62baf29a48c2f25', // PROD - HOOYO SERVICOS DE MARKETING EIRELI
  '6154cc36a467d30012b699c8', // PROD - RAQUEL SANTOS DE OLIVEIRA
  '6282b0224b38b00029d14a30', // PROD - Bh Servers Tecnologia LTDA
  '6090134bdd96490018b2e947', // PROD - PROSOCKS COMERCIO DIGITAL
  '5ec5b7d321add20613e3d176', // PROD - ELOISA COLA RIBEIRO DE OLIVEIRA
  '61fc3e0cb916f1001141fce2', // PROD - VL CURSOS PARA CONCURSOS LTDA
  '5f876a15f7000c3938a06aca', // PROD - Lord Franquias e Participações Ltda.
  '624ed3a3b20f120011b4ee7e', // PROD - Helena Tessaro & Cia EPP
  '5aea32ea0ea8e1cd222bdef6', // PROD - OPTARIUM COMERCIO DE ARTIGOS DE OPTICA EIRELI - ME
  '6287e85074d0c1001d3e1e3f', // PROD - Katia Rosa Comércio de Roupas e Acessórios Ltda
  '620184320b769700123fb171', // PROD - CRP COMERCIO DE ARTIGOS ESPORTIVOS LTDA
  '6176dc89d7084b0019f19bf2', // PROD - GENCO ALIMENTOS LTDA
  '62017c450afd580011551097', // PROD - Voitto Treinamento Desenvolvimento e Editora Ltda
  '61e1a3d91371e000116b1d1d', // PROD - PHISALIA DISTRIBUIDORA LTDA
  '60edb52fed705200197b2627', // PROD - Krug Bier Industria Ltda
  '62c34316450289001ddbfcd5', // PROD - ESPECIALIZADOS BRASIL - CURSOS DE CAPACITACAO PROFISSIONAL LTDA
  '612d2333dfdbc0001201c72e', // PROD - Carlos Eduardo Crivelaro
  '623a34194f8458001196a7b3', // PROD - INSTITUTO LILAS DE PESQUISAS DE COSMETICOS LTDA
  '620d5f717fe7040019f1c410', // PROD - SISI COSMÉTICOS - IMPORTACAO E EXPORTACAO EIRELI
  '5ebda9d9e2e1ab2f446a2bee', // PROD - CDS Comércio de Variedades Eireli
  '6215250d62ab810019769a9d', // PROD - Leticia Riccio Vaz Eireli
  '594c1ce92e0e630106212b6d', // PROD - Pro Educacional
  '60ef35ab7960530011227168', // PROD - E P DO C FERREIRA
  '628e884f5b67d1001e1ed856', // PROD - FABIO MARTINS DE ANDRADE 38625029884
  '62154f267fa1bd0011f9bf33', // PROD - NOVO PORTAL CONCURSOS LTDA
  '61bd058d9dbfd100118ce220', // PROD - 31.508.062 /0001 - 57
  '628ba085b9371d0029203578', // PROD - Insecta Shoes Comercio Artigos LTDA
  '6197b31117812900123fa5e3', // PROD - ALWAYSFIT SUPLEMENTOS ALIMENTICIOS
  '5c9a838dda32aeb853acc72f', // PROD - EVOLUTION COFFEE - COMERCIO DE PRODUTOS NATURAIS EIRELI
  '6244bde2482cb90013f98b47', // PROD - Dermabeauty Comercio Varejista de Alimentos, Cosmeticos e Acessorios para Estetica Ltda
  '62a73d160b410500281657d6', // PROD - DIETA NA MAO COMERCIO DE ALIMENTOS LTDA.
  '60db6c8297511c00128daa3f', // PROD - CICLOVIA INDUSTRIA E COMERCIO DE BICICLETAS EIRELI
  '5e0a0256d9d52a271763b7b3', // PROD - EI BELEZA SERVICOS EIRELI
  '61aa3297704a5500195f2ab0', // PROD - LE MEDICAL INSTRUMENTS@GMAIL.COM
  '6089e170f304810019c8628f', // PROD - Larissa Neris Cardoso ME
  '63239fe8f6f3dc8542354001', // PROD - EDER ESPINDOLA DIAS 22418481824
  '6182d3184406110019df876f', // PROD - AUTOCONHECIMENTO PARA TODOS NEGOCIOS DIGITAIS LTDA
  '6177101711d42d00183bb7c4', // PROD - NEF CONFECÇÕES LTDA - ME
  '62e01da81181e6002877bd0a', // PROD - ARLETE DA COSTA RIGAUD
  '62855c485800df001f290152', // PROD - G.I.R.MATIAS REIPRATAS
  '61a91f122d53700018fc164f', // PROD - Vanessa Vasconcelos sens ME
  '5734b31297f5ebcf04a0fd7d', // PROD - NERD STICKERS - PRODUCAO DE ADESIVOS ESPECIAIS DO BRASIL EIRELI - ME
  '60ca323db5a1670018d8a211', // PROD - Open Internet Comercio e Importacao Ltda
  '618a722ba1c01b0018103d72', // PROD - Nação Detail Comércio varejista eireli
  '62139de7839b3e0018788398', // PROD - Éder José Araújo de Oliveira
  '62600b97e7257100195b9867', // PROD - TIPSTER TRADE SERVICOS DIGITAIS LTDA
  '6347f73c924fa473cda0cc7a', // PROD - JASPER COMERCIO E IMPORTACAO DE ARTIGOS ESPORTIVOS LTDA
  '5ec1c402e31a692321d4c43a', // PROD - LAVINT MODA E CONFECÇAO LTDA
  '624ecf17a2996b00117ca217', // PROD - J.M.S SERVIÇOS FINANCEIROS EIRELI
  '6238c5960ac4f00019248ef9', // PROD - DBGL CABELOS E PRODUTOS LTDA
  '62fe2aece9be92001d137979', // PROD - CSC MODA E DECORACAO LTDA
  '6155d662a17f000012d8fde3', // PROD - EBENEZER COMERCIO ATACADISTA E VAREJISTA LTDA
  '624206eb003dfc001993345d', // PROD - comercial de ferragens cofebral ltda
  '624c88a71765d50012643aa0', // PROD - Selia Serviços e Gestão Empresarial LTDA
  '61bccbcad55d6c00129f795b', // PROD - TRIAD ind e com Lmtd
  '618e59091ff1cc0018da1a96', // PROD - THAÍS PENAFORTE CAETANO 06485515399
  '5f1602c9bb1059616f23e437', // PROD - BRUNO PINHEIRO SILVA
  '61d4418a6e509600117eae89', // PROD - FARMACIAS FLEMING LTDA
  '628672aa75c4f3001dd72b62', // PROD - FRANKLIN ATAIDE MOURAO 74762257249
  '6144c9d44b56640018a028f2', // PROD - Bazar Ana e Helena
  '614cdeef135428001275e33e', // PROD - ESPAÇO E - COMMERCE LTDA EPP
  '619ff72fb8d92500114ab61c', // PROD - Lehnen e Bernardino Boutique
  '605236694cdfa2001a21905f', // PROD - UNIAO VEGETAL SUPLEMENTOS NUTRICIONAIS LTDA ME
  '6238f068d471fd0018e6f56a', // PROD - L3L TECNOLOGIA E ASSESSORIA LTDA
  '622f4fd7e0a5d0001b111c30', // PROD - AVILA E ALVES COMERCIO DE ROUPAS LTDA
  '62e03e779d898a00290c6007', // PROD - Izalacewigs
  '623f92d48089b90019045c65', // PROD - Fique rico Ltda
  '5efcc8cad843611eba9bb0af', // PROD - Fix Comércio de Artesanato Ltda
  '632079458ccf38b54d619da2', // PROD - HENRIQUE BENIGNO GIROTTO 50291910890
  '62c3a3c12a49160029a8ed05', // PROD - CILZA ELAINE DE OLIVEIRA ME
  '619d3e73cc9bf100117cc7be', // PROD - FANATICO.STORE COMERCIO ESPORTIVO LTDA
  '6230eb1ef7d960001927a628', // PROD - Marco Antonio Rowe Buzzo
  '60a698a91578ce0019dfbd3a', // PROD - Pamella piccinato de mesquita
  '5ea65571bd0574394df68c83', // PROD - JSC Comércio Eletrônico Eireli
  '6033ca2fc1617900186c632e', // PROD - Gisela Kuasnei Alianças Eireli
  '6323340b801f4d1abc4f7e56', // PROD - AUTOAMERICA IMPORTACAO, EXPORTACAO
  '60f190998bf9e300119b295b', // PROD - Estilosa kids
  '61d58aa66a70700011468a08', // PROD - E.FERREIRA - CURSOS EVENTOS E TURISMO
  '5c519054b83b968635f60ef6', // PROD - Recorre Ref.e Cons.de Refrig.Ltda.
  '60f84291e597b30018d66177', // PROD - JLS CONFECÇAO E COMERCIO DE ROUPAS EIRELI
  '5de52d5f034c36251b70f27c', // PROD - Associação Brasileira de Sommeliers
  '608975f23a8a380011879cee', // PROD - MULTISPORT INDUSTRIA COMERCIO E REPRESENTAÇÕES LTDA
  '61e9536df0178e0018831447', // PROD - WESKLEY GOMES DE SOUZA 35643422816
  '6189995004598500137e13c5', // PROD - Lookdobebe
  '612905c817045a001247fb31', // PROD - Universe Brasil Distribuição Suplementos Alimentares Ltda
  '61435b7d287608001274b6e6', // PROD - Go Roller Skate Shop
  '627a9fb248162f001e33910f', // PROD - SIMEL STORE
  '5edecd045dc13558375cec1c', // PROD - Cobype Softwares
  '606c98af16a2560019124a57', // PROD - Solar Comércio e Agroindústria Ltda
  '628d2e088eea1d001e5ad8db', // PROD - KARINE FERNANDA PRATES BIAOBOCK 06523305929
  '62261455095d6a00182c1e24', // PROD - LUANA OLIVEIRA ALVES CAMPOS 16510056720
  '618d6f4601031b00194ff7df', // PROD - LAERDSON SAMPAIO ALMEIDA 05648533306
  '61afb89646cff8001958f518', // PROD - PH NOGUEIRA BRAGA COMERCIO DE AVIAMENTOS
  '61b0a7001f62630019650a8f', // PROD - W S G CONFECCOES LTDA
  '629f51e773dce1001d2ecfcd', // PROD - Sk Comercio de Suplementos Alimentares
  '5efcd7b05897170d1941b2cc', // PROD - HDA Comércio de Artigos de Decoração e Presentes Eireli
  '628551dfc5b8fa001ea485c9', // PROD - Nala Culture Comercial Ltda
  '614206916b80080018aad5e6', // PROD - ANDREA ALMEIDA DA SILVA SANTOS 37567521865
  '626fda82ac6fcf001954f3df', // PROD - PICO SHOP LTDA
  '62d18056d99f12001f7befcc', // PROD - LEONARDO ALVES DE MEDEIROS
  '613df8fedef1830019df8436', // PROD - Bora Nutrition Comercio de Produtos Naturais LTDA
  '617bfe3208796900113b98c8', // PROD - Pittas Board Games ltda
  '613a3319d95b720012055530', // PROD - Allugator Serviços Digitais LTDA
  '6221240fe12b540012517ffb', // PROD - Prescrita Medicamentos
  '630d0b2202d8bd967d63678b', // PROD - XEiRO DE XICLETE EIRELLE
  '624d944f26c0eb0012696718', // PROD - Pro Subscriber Tecnologia LTDA
  '62bde0044abf97002856e543', // PROD - BASTET DESIGN LTDA
  '5da89c5cc6979a2bbc853cbd', // PROD - INGRID F G MARTINS
  '6254418960299e00116e1e17', // PROD - BARRIND INDUSTRIA E COMERCIO
  '616ef3e0129ff80012bb0918', // PROD - THE BURGER STORE
  '5deabe6532a8ab0ef8676ce2', // PROD - Hana Beauty Importação Exportação Eireli
  '619bbe03d0070b0012941952', // PROD - R.GONCALVES SUPRIMENTOS MEDICOS LTDA
  '62606aeb5653a10018e0c204', // PROD - TSO COMERCIO DE ROUPAS LTDA
  '624ee9bd7b3a5c0012019165', // PROD - TRACES ESTACIONAMENTOS E PARTICIPAÇÕES EIRELI
  '62b49f5ff777230028762615', // PROD - MARLENE GOMES DE LIMA 45050864100
  '613f58b194a744001170d8f5', // PROD - be fancy moda feminina ltda
  '6140e3051245fd00187174ae', // PROD - QUANTICO TECNOLOGIA E SOLUCOES DIGITAIS LTDA
  '61fd5ee0a1f1d00012aa624e', // PROD - INGRESSOS.COM.BR COMERCIO DE VOUCHERS LTDA
  '622a4ba17a3822001a1f09f7', // PROD - Luís Henrique Gomes dores 04156537030
  '62eac8468fcfc2001eaaf8f8', // PROD - TIP TOP FRANQUIAS E LICENCIAMENTO LTDA
  '6217cc1dfabc8f0016951ad1', // PROD - EBR Comercio Varejista de Eletrodom ltda
  '61a5248704a4a00018f3ac48', // PROD - LENDARY COMERCIO SERVIÇOS E CONFECÇOES EIRELI
  '618ac7bec7872e0018dbb33a', // PROD - ANNA COMÉRCIO DE CALÇADOS LTDA
  '62ffd8e8dbecb9001d3814fe', // PROD - AJA COMERCIO ELETRONICO DE ROUPAS E ACESSORIOS LTDA
  '62b3059641e0f20028df3faa', // PROD - BRASFILTER INDUSTRIA E COMERCIO LTDA
  '629e555530a516001e21ca1a', // PROD - SHALOM DRIVER INTERMEDIACAO DE SERVICO DE TRANSPORTE POR APLICATIVO LTDA
  '61a0e03b5e373700187af37d', // PROD - CUSTOMIZE INDUSTRIA DO VESTUARIO LTDA
  '628406db84dec9001dad6f34', // PROD - SOMOS SISTEMAS DE ENSINO S.A.
  '62693e2a72b6d5001855b39a', // PROD - NOVA SANTA FE CONFC
  '60a4173b4aa20f00181adaec', // PROD - C A Pereira Com de Vest e Acess Eireli
  '633737575539bd5b6276b8a0', // PROD - OFFICINA 7 COMÉRCIO DE PRODUTOS OTICOS EIRELI
  '628670f12e71a4001efd54bd', // PROD - DAZ ROUPAZ - DIGITAL LTDA
  '5f7fa5403f49e0438085442e', // PROD - MAGA COMERCIO DE PRODUTOS COSMETICOS LTDA
  '61b0cbc27b8f1e0018a9d816', // PROD - Karla Serpa bolsas
  '5eff6094d077a44708810c1a', // PROD - Amanda C Esmeraldo Confecção de Roupa
  '62ed31db9bf3df001eec5571', // PROD - CONFEITARIA COMERCIO DE ALIMENTOS EIRELI
  '611eb16eacece40012cf5d71', // PROD - Khanto Reservas LTDA
  '622f96734484f50011253cba', // PROD - KAPEH COSMETICOS LTDA
  '606f0b380547cc00120bf2c4', // PROD - CHG COMERCIO DE SEMIJOIAS LTDA
  '616709af2bb5450018469727', // PROD - TAMIRES SANTIAGO DE SOUSA ME
  '61e873564819990011b9264d', // PROD - CONFECCOES MARDU LTDA
  '617c416cde75aa0012eb5a04', // PROD - Cdb design comércio varejista de papelaria Ltda
  '610970b37cc1b40018f1cdb5', // PROD - FELIPE ALVES PASTE SERVICOS MEDICOS
  '6168645776c746001271d53e', // PROD - Camila Figueiredo Lilli 40473212811
  '6061ff65402af3001929f873' // PROD - DLY INDUSTRIA E COMERCIO DE CONFECCOES LTDA
]
