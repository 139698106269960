const routeCharges = store => ({
  path: 'charges/',
  name: 'charges',
  component: () =>
    import(
      /* webpackChunkName: "charges" */ '@/views/Payments/Charges/Charges.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (store.getters.permissionIsEnabled('charge:search')) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.sales.root',
      current: 'breadcrumb.sales.charges'
    }
  }
})

const routeChargesDetail = store => ({
  path: 'charges/:id',
  name: 'chargesDetail',
  component: () =>
    import(
      /* webpackChunkName: "chargesdetail" */ '@/views/Payments/Charges/ChargesDetail.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (!store.getters.permissionIsEnabled('charge:get')) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    const params = {
      chargeId: to.params.id,
      query: {
        ...to.query,
        created_at: to.query.created_at,
        is_test_account: store.getters.isTestAccount
      },
      accountId: to.params.account_id,
      merchantId: to.params.merchant_id
    }
    store.dispatch('GET_CHARGE', params).then(() => {
      next()
    })
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.sales.root',
      parents: [
        {
          name: 'breadcrumb.sales.charges',
          routeName: 'charges'
        }
      ],
      current: 'breadcrumb.sales.charges-detail'
    }
  }
})

export default [routeCharges, routeChargesDetail]
