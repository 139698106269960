import { DashInstance, catchError } from '@/store'

const subscription = {
  state: {
    subscriptions: { items: [], pagination: {} },
    loadingList: true
  },
  mutations: {
    toggleLoadingList (state, status) {
      state.loadingList = status
    },
    SET_SUBSCRIPTIONS (state, subscriptions) {
      state.subscriptions = subscriptions
    }
  },
  getters: {
    subscriptions (state) {
      return state.subscriptions
    },
    loadingListSubscriptions (state) {
      return state.loadingList
    }
  },
  actions: {
    GET_SUBSCRIPTIONS (context, params = {}) {
      context.commit('toggleLoadingList', true)
      return DashInstance.subscription
        .getAll(params.merchantId, params.accountId, params.query)
        .then(res => {
          context.commit('SET_SUBSCRIPTIONS', res)
          context.commit('toggleLoadingList', false)
          Promise.resolve(res)
        })
        .catch(err => {
          return catchError(err, context)
        })
    },
    GET_SUBSCRIPTION (context, params = {}) {
      return DashInstance.subscription
        .getById(
          params.merchantId,
          params.accountId,
          params.subscriptionId,
          params.query
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    CANCEL_SUBSCRIPTION (context, params = {}) {
      return DashInstance.subscription
        .cancel(
          params.merchantId,
          params.accountId,
          params.subscriptionId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    toggleLoadingListSubscription ({ commit }, status) {
      commit('toggleLoadingList', status)
    }
  }
}

export default subscription
