import Cookies from 'js-cookie'
import DashCore from 'dash-js'

const DashInstance = new DashCore({
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME
})
const envAcronym = DashInstance.envAcronym

export const setCookieDash = (data = {}) => {
  const cookieData = Cookies.get(`${envAcronym}mp_dash`)

  if (cookieData) {
    Promise.resolve(atob(cookieData))
      .then(JSON.parse)
      .then(resCookieParse => {
        Cookies.set(
          `${envAcronym}mp_dash`,
          btoa(JSON.stringify({ ...resCookieParse, ...data })),
          { expires: 0.5 }
        )
      })
  } else {
    Cookies.set(`${envAcronym}mp_dash`, btoa(JSON.stringify({ ...data })), {
      expires: 0.5
    })
  }
}

export const updateCookieDash = key => {
  const cookieData = Cookies.get(`${envAcronym}mp_dash`)
  if (cookieData) {
    Promise.resolve(atob(cookieData))
      .then(JSON.parse)
      .then(resCookieParse => {
        delete resCookieParse[key]
        if (Object.entries(resCookieParse).length) {
          Cookies.set(
            `${envAcronym}mp_dash`,
            btoa(JSON.stringify({ ...resCookieParse })),
            { expires: 0.5 }
          )
        } else {
          Cookies.remove(`${envAcronym}mp_dash`)
        }
      })
  }
}
