import { DashInstance, catchError } from '@/store'
import { isNotFound } from '@/enums/http-status.enum'

const physicalWorldOrders = {
  state: {
    physicalWorldOrders: { content: [], pagination: {}, total_items: 0 },
    physicalWorldOrder: {},
    loadingList: true,
    openHours: []
  },
  mutations: {
    TOGGLE_LOADING_LIST (state, status) {
      state.loadingList = status
    },
    SET_PHYSICAL_WORLD_ORDERS (state, orders) {
      state.physicalWorldOrders = {
        content: orders?.items || [],
        pagination: orders?.pagination
      }
    },
    SET_PHYSICAL_WORLD_ORDER (state, order) {
      state.physicalWorldOrder = order
    },
    SET_OPEN_HOURS (state, hours) {
      state.openHours = hours
    }
  },
  getters: {
    physicalWorldOrders (state) {
      return state.physicalWorldOrders
    },
    loadingListServiceOrders (state) {
      return state.loadingList
    },
    physicalWorldOrder (state) {
      return state.physicalWorldOrder
    },
    openHours (state) {
      return state.openHours
    }
  },
  actions: {
    GET_PHYSICAL_WORLD_ORDERS (context, params = {}) {
      context.commit('TOGGLE_LOADING_LIST', true)

      params.query = { size: 10, page: 1, ...params }

      if (params.query?.id) {
        return DashInstance.account
          .getPhysicalWorldOrderById(
            context.getters.merchantId,
            context.getters.accountId,
            params.query.id
          )
          .then(res => {
            context.commit('SET_PHYSICAL_WORLD_ORDERS', {
              items: [res],
              pagination: {
                current_page: 1,
                total_page: 1
              }
            })
            Promise.resolve(res)
          })
          .catch(err => {
            if (isNotFound(err.status)) {
              context.commit('SET_PHYSICAL_WORLD_ORDERS', { content: [] })
              return Promise.resolve([])
            }
            catchError(err, context)
          })
          .finally(() => {
            context.commit('TOGGLE_LOADING_LIST', false)
          })
      }

      return DashInstance.account
        .getPhysicalWorldOrdersV2(
          context.getters.merchantId,
          context.getters.accountId,
          params.query
        )
        .then(res => {
          context.commit('SET_PHYSICAL_WORLD_ORDERS', res)
          Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
        .finally(() => {
          context.commit('TOGGLE_LOADING_LIST', false)
        })
    },
    GET_PHYSICAL_WORLD_ORDER_BY_ID (context, params = {}) {
      const id = params.id ? params.id : params.query.id
      return DashInstance.account
        .getPhysicalWorldOrderById(params.merchantId, params.accountId, id)
        .then(res => {
          const dateFormatted =
            res.recipient.location.open_hours.days_of_the_week
          context.commit('SET_OPEN_HOURS', dateFormatted)
          context.commit('SET_PHYSICAL_WORLD_ORDER', res)
          Promise.resolve(res)
        })
        .catch(err => {
          catchError(err, context)
        })
        .finally(() => {
          context.commit('TOGGLE_LOADING_LIST', false)
        })
    },
    CREATE_PHYSICAL_WORLD_SWAP_ORDER (context, params) {
      return DashInstance.account
        .createPhysicalWorldOrderSwap(
          context.getters.merchantId,
          context.getters.accountId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    CREATE_PHYSICAL_WORLD_ORDER (context, params = {}) {
      return DashInstance.account
        .createPhysicalWorldOrder(
          context.getters.merchantId,
          context.getters.accountId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    TOGGLE_LOADING_LIST ({ commit }, status) {
      commit('TOGGLE_LOADING_LIST', status)
    }
  }
}

export default physicalWorldOrders
