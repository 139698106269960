export const INSIGHTS_ACTIONS_ENUM = {
  view: 'insights:view'
}

const insightsMerchantEnabled = (
  permissionIsEnabledFn,
  componentIsEnabledFn
) => {
  return (
    componentIsEnabledFn('sidemenu', 'home') &&
    permissionIsEnabledFn(INSIGHTS_ACTIONS_ENUM.view)
  )
}

export default insightsMerchantEnabled
