const routeMaintenance = store => ({
  path: '/maintenance',
  name: 'maintenance',
  component: () =>
    import(
      /* webpackChunkName: "maintenance/Motherboard" */ '@/components/project/templates/Motherboard.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (store.getters.maintenance) {
      store.commit('toggleGlobalLoading', false)
      next()
    } else {
      next({ name: 'default' })
    }
  }
})

export default routeMaintenance
