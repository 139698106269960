import store from '@/store'

const applyColorMode = (colorStyle = 'fontColor') => {
  const activeMode = store.getters.pref.appearance.mode || 'dark'
  if (colorStyle === 'fontColor') {
    if (activeMode === 'dark') return '#E5E5E5'
    else if (activeMode === 'light') return '#0B1D29'
    else return '#E5E5E5'
  } else {
    if (activeMode === 'dark') return '#444444'
    else if (activeMode === 'light') return '#ddd'
    else return '#444444'
  }
}

export default applyColorMode
