import { DashInstance, catchError } from '@/store'

const customer = {
  state: {
    customers: { items: [], pagination: {} },
    cards: { items: [], pagination: {} },
    addresses: { items: [], pagination: {} },
    loadingList: true,
    loadingCard: true,
    loadingAddress: true,
    filterOptions: [
      {
        name: 'customer.filter.id',
        type: 'text',
        name_query: 'id'
      },
      {
        name: 'customer.filter.name',
        type: 'text',
        name_query: 'name'
      },
      {
        name: 'customer.filter.document',
        type: 'number',
        name_query: 'document'
      },
      {
        name: 'customer.filter.email',
        type: 'email',
        name_query: 'email'
      }
    ]
  },
  mutations: {
    toggleLoadingList (state, status) {
      state.loadingList = status
    },
    SET_CUSTOMERS (state, customers) {
      state.customers = customers
    },
    toggleLoadingCard (state, status) {
      state.loadingCard = status
    },
    SET_CARDS (state, cards) {
      state.cards = cards
    },
    toggleLoadingAddress (state, status) {
      state.loadingAddress = status
    },
    SET_ADDRESSES (state, addresses) {
      state.addresses = addresses
    }
  },
  getters: {
    customers (state) {
      return state.customers
    },
    loadingListCustomers (state) {
      return state.loadingList
    },
    customersCards (state) {
      return state.cards
    },
    loadingCustomersCard (state) {
      return state.loadingCard
    },
    customersAddresses (state) {
      return state.addresses
    },
    loadingCustomersAddress (state) {
      return state.loadingAddress
    },
    filterOptionsCustomers (state) {
      return state.filterOptions
    }
  },
  actions: {
    toggleLoadingList ({ commit }, status) {
      commit('toggleLoadingList', status)
    },
    GET_CUSTOMERS (context, params = {}) {
      context.commit('toggleLoadingList', true)
      return DashInstance.customer
        .getAll(params.merchantId, params.accountId, params.query)
        .then(res => {
          context.commit('SET_CUSTOMERS', res)
          Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
        .finally(() => context.commit('toggleLoadingList', false))
    },
    GET_CUSTOMER (context, params = {}) {
      return DashInstance.customer
        .getById(params.merchantId, params.accountId, params.customerId)
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    GET_CUSTOMER_ADDRESSES (context, params = {}) {
      context.commit('toggleLoadingAddress', true)
      return DashInstance.customer
        .getAddresses(
          params.merchantId,
          params.accountId,
          params.customerId,
          params.query
        )
        .then(res => {
          context.commit('SET_ADDRESSES', res)
          Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
        .finally(() => context.commit('toggleLoadingAddress', false))
    },
    GET_CUSTOMER_CARDS (context, params = {}) {
      context.commit('toggleLoadingCard', true)
      return DashInstance.customer
        .getCards(
          params.merchantId,
          params.accountId,
          params.customerId,
          params.query
        )
        .then(res => {
          context.commit('SET_CARDS', res)
          Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
        .finally(() => context.commit('toggleLoadingCard', false))
    }
  }
}

export default customer
