import { someActionsPermissionIsEnabled } from '.'

export const SETTINGS_GATEWAYS_ACTIONS_ENUM = {
  mundipaggView: 'settings.gateways.mundipagg:view',
  pagarmeView: 'settings.gateways.pagarme:view'
}

const SETTINGS_GATEWAYS_ACTIONS = [
  SETTINGS_GATEWAYS_ACTIONS_ENUM.mundipaggView,
  SETTINGS_GATEWAYS_ACTIONS_ENUM.pagarmeView
]

// showGatewaysConfig
const isSettingsGatewaysEnabled = permissionIsEnabledFn => {
  return someActionsPermissionIsEnabled(
    SETTINGS_GATEWAYS_ACTIONS,
    permissionIsEnabledFn
  )
}

const isSettingsGatewaysMundipaggEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_GATEWAYS_ACTIONS_ENUM.mundipaggView)
}

const isSettingsGatewaysPagarme = permissionIsEnabledFn => {
  return permissionIsEnabledFn(SETTINGS_GATEWAYS_ACTIONS_ENUM.pagarmeView)
}

export {
  isSettingsGatewaysEnabled,
  isSettingsGatewaysMundipaggEnabled,
  isSettingsGatewaysPagarme
}
