import {
  usersGetEnabled,
  usersSearchEnabled
} from '@/actions-permission/users.permissions'

const routeUsers = store => ({
  path: 'users',
  name: 'users',
  component: () => import('@/views/Team/Users/Users.vue'),
  beforeEnter: (to, from, next) => {
    if (!usersSearchEnabled(store.getters.permissionIsEnabled)) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.team.root',
      current: 'breadcrumb.team.users'
    }
  }
})

const routeUsersDetail = store => ({
  path: 'users/:id',
  name: 'usersDetail',
  component: () =>
    import(
      /* webpackChunkName: "usersdetail" */ '@/views/Team/Users/UsersDetail.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (!usersGetEnabled(store.getters.permissionIsEnabled)) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.team.root',
      parent: [
        {
          name: 'breadcrumb.team.users',
          routeName: 'users'
        }
      ],
      current: 'breadcrumb.team.users-detail'
    }
  }
})

export default [routeUsers, routeUsersDetail]
