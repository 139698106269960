import components from '@/utils/components.utils'

const roles = {
  state: {
    components: components
  },
  getters: {
    permissionIsEnabled: (state, getters) => action => {
      return getters.profile.actions.includes(action)
    },
    componentIsEnabled: (state, getters) => (section, component) => {
      return state.components[section][component]?.includes('pagarme')
    }
  }
}
export default roles
