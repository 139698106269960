import store from '@/store'
import balanceEnabled from '../actions-permission/balance.permissions'

const commonTransfersBeforeEnter = next => {
  if (
    !balanceEnabled(
      store.getters.permissionIsEnabled,
      store.getters.componentIsEnabled,
      store.getters.businessModel
    )
  ) {
    return store.commit('toggleGlobalError', { status: true, code: 404 })
  }
  next()
}

const routeContracts = store => ({
  path: 'contracts/:pg_recipient_id?',
  name: 'contracts',
  component: () =>
    import(/* webpackChunkName: "Contracts" */ '@/views/Contracts/index.vue'),
  beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next),
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      current: 'breadcrumb.contracts.root'
    }
  }
})

const routeContractsTabActive = store => ({
  path: 'contracts/:pg_recipient_id/:tabActive',
  name: 'contractsTabActive',
  component: () =>
    import(/* webpackChunkName: "Contracts" */ '@/views/Contracts/index.vue'),
  beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next),
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      current: 'breadcrumb.contracts.root'
    }
  }
})

const routeContractsValues = store => ({
  path: 'contracts/:pg_recipient_id/values/:day',
  name: 'contractsValues',
  component: () =>
    import(/* webpackChunkName: "Contracts" */ '@/views/Contracts/index.vue'),
  beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next),
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      current: 'breadcrumb.contracts.day'
    }
  }
})

const routeContractHolder = store => ({
  path: 'contracts/:pg_recipient_id/institution/:contractHolder',
  name: 'ContractHolder',
  component: () =>
    import(/* webpackChunkName: "Contracts" */ '@/views/Contracts/index.vue'),
  beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next),
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      current: 'breadcrumb.contracts.institution'
    }
  }
})

const routeContractKey = store => ({
  path: 'contracts/:pg_recipient_id/contract/:contractKey',
  name: 'ContractKey',
  component: () =>
    import(/* webpackChunkName: "Contracts" */ '@/views/Contracts/index.vue'),
  beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next),
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      current: 'breadcrumb.contracts.details'
    }
  }
})

const routeContractContestation = store => ({
  path: 'contracts/:pg_recipient_id/contract/:contractKey/contestation',
  name: 'ContractContestation',
  component: () =>
    import(/* webpackChunkName: "Contracts" */ '@/views/Contracts/index.vue'),
  beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next),
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      current: 'breadcrumb.contracts.contestation'
    }
  }
})

export default [
  routeContracts,
  routeContractsTabActive,
  routeContractsValues,
  routeContractHolder,
  routeContractKey,
  routeContractContestation
]
