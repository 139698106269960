import { DashInstance, catchError } from '@/store'

const invoice = {
  state: {
    invoices: { items: [], pagination: {} },
    loadingList: true,
    filterOptions: [
      {
        name: 'invoice.filter.id',
        type: 'text',
        name_query: 'id'
      },
      {
        name: 'invoice.filter.code',
        type: 'text',
        name_query: 'code'
      },
      {
        name: 'invoice.filter.customer-id',
        type: 'text',
        name_query: 'customer_id'
      },
      {
        name: 'invoice.filter.subscription-id',
        type: 'text',
        name_query: 'subscription_id'
      },
      {
        name: 'invoice.filter.status',
        type: 'select',
        select_options: [
          {
            id: 'pending',
            text: 'invoice.filter.pending'
          },
          {
            id: 'paid',
            text: 'invoice.filter.paid'
          },
          {
            id: 'scheduled',
            text: 'invoice.filter.scheduled'
          },
          {
            id: 'canceled',
            text: 'invoice.filter.canceled'
          },
          {
            id: 'failed',
            text: 'invoice.filter.failed'
          }
        ],
        name_query: 'status'
      },
      {
        name: 'invoice.filter.due-period',
        type: 'select',
        select_options: [
          {
            id: 'Today',
            text: 'invoice.filter.due-today'
          },
          {
            id: 'Yesterday',
            text: 'invoice.filter.due-yesterday'
          },
          {
            id: 'SevenDays',
            text: 'invoice.filter.due-7-days'
          },
          {
            id: 'FifteenDays',
            text: 'invoice.filter.due-15-days'
          },
          {
            id: 'OneMonth',
            text: 'invoice.filter.due-30-days'
          }
        ],
        name_query: 'due_date_filters'
      },
      {
        name: 'invoice.filter.due-since',
        type: 'date',
        name_query: 'due_date'
      },
      {
        name: 'invoice.filter.amount',
        type: 'currency',
        name_query: 'amount'
      },
      {
        name: 'invoice.filter.creation-period',
        type: 'select',
        select_options: [
          {
            id: 'specific_date',
            text: 'invoice.filter.period-specific'
          },
          {
            id: 'date_filters',
            text: 'invoice.filter.period-predefined'
          },
          {
            id: 'interval',
            text: 'invoice.filter.period-interval'
          }
        ],
        name_query: 'period_type'
      },
      {
        name: 'invoice.filter.specific-date',
        type: 'date',
        name_query: 'specific_date',
        period_type: 'specific_date'
      },
      {
        name: 'invoice.filter.predefined-date',
        type: 'select',
        select_options: [
          {
            id: 'Today',
            text: 'invoice.filter.predefined-today'
          },
          {
            id: 'Yesterday',
            text: 'invoice.filter.predefined-yesterday'
          },
          {
            id: 'SevenDays',
            text: 'invoice.filter.predefined-7-days'
          },
          {
            id: 'FifteenDays',
            text: 'invoice.filter.predefined-15-days'
          },
          {
            id: 'OneMonth',
            text: 'invoice.filter.predefined-30-days'
          }
        ],
        name_query: 'date_filters',
        period_type: 'date_filters'
      },
      {
        name: 'invoice.filter.initial-date',
        type: 'date',
        name_query: 'since',
        period_type: 'interval'
      },
      {
        name: 'invoice.filter.final-date',
        type: 'date',
        name_query: 'until',
        period_type: 'interval'
      }
    ]
  },
  mutations: {
    toggleLoadingList (state, status) {
      state.loadingList = status
    },
    SET_INVOICES (state, invoices) {
      state.invoices = invoices
    }
  },
  getters: {
    invoices (state) {
      return state.invoices
    },
    loadingListInvoices (state) {
      return state.loadingList
    },
    filterOptionsInvoices (state) {
      return state.filterOptions
    }
  },
  actions: {
    GET_INVOICES (context, params = {}) {
      context.commit('toggleLoadingList', true)
      return DashInstance.invoice
        .getAll(params.merchantId, params.accountId, params.query)
        .then(res => {
          context.commit('SET_INVOICES', res)
          context.commit('toggleLoadingList', false)
          Promise.resolve(res)
        })
        .catch(err => {
          return catchError(err, context)
        })
    },
    GET_INVOICE (context, params = {}) {
      return DashInstance.invoice
        .getById(params.merchantId, params.accountId, params.invoiceId)
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    toggleLoadingList ({ commit }, status) {
      commit('toggleLoadingList', status)
    }
  }
}

export default invoice
