import routePaymentsLinks from './payment-links.route'
import routeOrders from './orders.route'
import routeCharges from './charges.route'
import routeTrf from './trf.route'
import routeChargebacks from './chargebacks.route'

export default [
  ...routePaymentsLinks,
  ...routeOrders,
  ...routeCharges,
  ...routeChargebacks,
  routeTrf
]
