function checkAppcues () {
  return window && window.Appcues
}

/**
 * Identify User in Appcues
 *
 * @param {number} userId user id
 * @param {string} userEmail user email
 * @param {string} userName user name
 * @param {string} merchanId merchanId
 */

export const identify = ({ userId, userEmail, userName, merchanId }) => {
  if (checkAppcues()) {
    window.Appcues.identify(userId, {
      userEmail,
      userName,
      merchanId
    })
  }
}

/**
 * Set Company data in Appcues session
 *
 * @param {number} userId user id
 * @param {string} merchanId merchan id
 * @param {string} accountId account id
 * @param {string} accountName account name
 * @param {string} businessModel business Model
 * @param {string} accountType account Type
 */

export const setAccountInAppcues = ({
  userId,
  merchanId,
  accountId,
  accountName,
  businessModel,
  accountType
}) => {
  if (checkAppcues()) {
    window.Appcues.identify(userId, {
      accountId,
      accountName,
      businessModel,
      accountType,
      merchanId
    })
  }
}

/**
 * Triggers appcues page change
 */
export const appcuesPage = () => {
  if (checkAppcues()) {
    window.Appcues.page()
  }
}
