export const darkColors = [
  '#32A0D2',
  '#00D2BB',
  '#D4BBFF',
  '#FFDCC9',
  '#6FDC8C',
  '#FF81CC'
]
export const lightColors = [
  '#082558',
  '#009F8F',
  '#A41453',
  '#CE6F00',
  '#A56EFF',
  '#F9006D'
]

export const pieChartColors = {
  dark: {
    pending: '#fbf65b',
    paid: '#29d663',
    failed: '#e65787',
    canceled: '#a4a6a8',
    processing: '#877cfb'
  },
  light: {
    pending: '#eec40d',
    paid: '#00a94e',
    failed: '#ff5457',
    canceled: '#9eb6ad',
    processing: '#695cfa'
  }
}

export const orderedPieChartColors = (themeStr, labelsArr) =>
  labelsArr.map(label => pieChartColors[themeStr][label])
