import { DashInstance } from '@/store'
import { isOwnerOrAdmin } from '@/enums/merchant-type.enum'
import { isPsP } from '@/enums/business-model.enum'

const kyc = {
  state: {
    loadingKyc: false,
    loadingKycQrCode: false,
    kycQrCode: null,
    kyc: null,
    isKycOnboardingPending: false,
    isKycRouteEnabled: false
  },
  getters: {
    isKycRouteEnabled (state) {
      return state.isKycRouteEnabled
    },
    loadingKycQrCode (state) {
      return state.loadingKycQrCode
    },
    loadingKyc (state) {
      return state.loadingKyc
    },
    kyc (state) {
      return state.kyc
    },
    lastKyc (state) {
      return state?.kyc?.[0]
    },
    kycQrCode (state) {
      return state.kycQrCode
    },
    isKycOnboardingPending (state, getters) {
      const { userType, account } = getters
      const accountStatusIsPending = account.status.key === 'pending'

      return (
        isOwnerOrAdmin(userType.key) &&
        isPsP(account.business_model) &&
        account.onboarding &&
        !accountStatusIsPending
      )
    }
  },
  mutations: {
    SET_KYC (state, payload) {
      state.kyc = payload
    },
    SET_KYC_QR_CODE (state, payload) {
      state.kycQrCode = payload
    },
    SET_LOADING_KYC (state, payload) {
      state.loadingKyc = payload
    },
    SET_LOADING_KYC_QR_CODE (state, payload) {
      state.loadingKycQrCode = payload
    },
    SET_KYC_ROUTE_ENABLED (state, payload) {
      state.isKycRouteEnabled = payload
    }
  },
  actions: {
    CREATE_KYC_QR_CODE (context) {
      context.commit('SET_LOADING_KYC_QR_CODE', true)
      return DashInstance.account
        .createKycQrCode(context.getters.merchantId, context.getters.accountId)
        .then(res => {
          context.commit('SET_KYC_QR_CODE', res)
          return Promise.resolve(res)
        })
        .catch(err => Promise.reject(err))
        .finally(() => {
          context.commit('SET_LOADING_KYC_QR_CODE', false)
        })
    },
    GET_KYC (context, { merchant_id, account_id }) {
      context.commit('SET_LOADING_KYC', true)
      return DashInstance.account
        .getKycStatus(merchant_id, account_id)
        .then(res => {
          context.commit('SET_KYC', res)
          return Promise.resolve(res)
        })
        .catch(err => Promise.reject(err))
        .finally(() => {
          context.commit('SET_LOADING_KYC', false)
        })
    }
  }
}

export default kyc
