const USER_ROLE_TYPES = {
  ADMIN: 'admin',
  ANALYST: 'analyst',
  CONTROLLER: 'controller',
  DEVELOPER: 'developer',
  FINANCE: 'finance',
  MANAGER: 'manager',
  OBSERVER: 'observer',
  OWNER: 'owner',
  SALES: 'sales',
  SELLER: 'seller',
  SUPPORT: 'support'
}

/**
 * @param {String} type user.role.type.key
 * @returns Boolean
 */
const isSellerUserRole = type =>
  USER_ROLE_TYPES.SELLER.toUpperCase() === type.toUpperCase()
/**
 * @param {String} type user.role.type.key
 * @returns Boolean
 */
const isSalesUserRole = type =>
  USER_ROLE_TYPES.SALES.toUpperCase() === type.toUpperCase()
/**
 * @param {String} type user.role.type.key
 * @returns Boolean
 */
const isSellerOrSalesUserRole = type =>
  [USER_ROLE_TYPES.SELLER, USER_ROLE_TYPES.SALES].includes(type.toLowerCase())
/**
 * @param {String} type user.role.type.key
 * @returns Boolean
 */
const isAdminRole = type =>
  USER_ROLE_TYPES.ADMIN.toUpperCase() === type.toUpperCase()
/**
 * @param {String} type user.role.type.key
 * @returns Boolean
 */
const isAnalystRole = type =>
  USER_ROLE_TYPES.ANALYST.toUpperCase() === type.toUpperCase()
/**
 * @param {String} type user.role.type.key
 * @returns Boolean
 */
const isControllerRole = type =>
  USER_ROLE_TYPES.CONTROLLER.toUpperCase() === type.toUpperCase()
/**
 * @param {String} type user.role.type.key
 * @returns Boolean
 */
const isDeveloperRole = type =>
  USER_ROLE_TYPES.DEVELOPER.toUpperCase() === type.toUpperCase()
/**
 * @param {String} type user.role.type.key
 * @returns Boolean
 */
const isFinanceRole = type =>
  USER_ROLE_TYPES.FINANCE.toUpperCase() === type.toUpperCase()
/**
 * @param {String} type user.role.type.key
 * @returns Boolean
 */
const isManagerRole = type =>
  USER_ROLE_TYPES.MANAGER.toUpperCase() === type.toUpperCase()
/**
 * @param {String} type user.role.type.key
 * @returns Boolean
 */
const isObserverRole = type =>
  USER_ROLE_TYPES.OBSERVER.toUpperCase() === type.toUpperCase()
/**
 * @param {String} type user.role.type.key
 * @returns Boolean
 */
const isOwnerRole = type =>
  USER_ROLE_TYPES.OWNER.toUpperCase() === type.toUpperCase()
/**
 * @param {String} type user.role.type.key
 * @returns Boolean
 */
const isSupportRole = type =>
  USER_ROLE_TYPES.SUPPORT.toUpperCase() === type.toUpperCase()

export {
  USER_ROLE_TYPES,
  isSellerUserRole,
  isSalesUserRole,
  isSellerOrSalesUserRole,
  isAdminRole,
  isAnalystRole,
  isControllerRole,
  isDeveloperRole,
  isManagerRole,
  isObserverRole,
  isOwnerRole,
  isSupportRole,
  isFinanceRole
}
