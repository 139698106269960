var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isIE
    ? _c(
        "div",
        { staticClass: "jade pagarme", attrs: { id: "app" } },
        [
          (_vm.isLoading && !_vm.hasError) || _vm.redirectOwner
            ? _c(
                "div",
                { staticClass: "el-section el-centered eu-align-items_center" },
                [
                  _c("emd-pagarme-loader", {
                    staticClass: "fade-in",
                    attrs: { color: "secondary" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.modalConfigMfaIsOpen
            ? _c("modal-default-config-mfa", {
                attrs: {
                  isOpen: _vm.modalConfigMfaIsOpen,
                  title: _vm.$t("mfa-modal.title"),
                  textParagraph: [
                    _vm.$t("mfa-modal.paragraph.first"),
                    _vm.$t("mfa-modal.paragraph.second"),
                    _vm.$t("mfa-modal.paragraph.third"),
                  ],
                  textSubimit: _vm.$t("mfa-modal.submit"),
                },
                on: {
                  onCancel: _vm.closeConfigMfaModal,
                  onSubmit: _vm.openConfigMFa,
                },
              })
            : _vm._e(),
          !_vm.isLoading && _vm.hasError
            ? _c("global-error", {
                attrs: {
                  theme: "pagarme",
                  showLogo: _vm.componentIsEnabled("globalError", "logo"),
                  code: _vm.code,
                },
              })
            : !_vm.isLoading && _vm.isDelinquent
            ? _c("delinquent")
            : !_vm.isLoading && !_vm.hasError && !_vm.isDelinquent
            ? [
                !_vm.redirectOwner &&
                _vm.hasPendingStatus &&
                !_vm.isKycRouteEnabled
                  ? _c("modal-pending-account")
                  : _vm._e(),
                _c("router-view"),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }