import store from '@/store'
import balanceEnabled from '../actions-permission/balance.permissions'

const commonTransfersBeforeEnter = next => {
  if (
    !balanceEnabled(
      store.getters.permissionIsEnabled,
      store.getters.componentIsEnabled,
      store.getters.businessModel
    )
  ) {
    return store.commit('toggleGlobalError', { status: true, code: 404 })
  }
  next()
}

const routeTransfers = store => ({
  path: 'transfers/:pg_recipient_id?',
  name: 'transfers',
  component: () =>
    import(/* webpackChunkName: "transfers" */ '@/views/Transfers/index.vue'),
  beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next),
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      current: 'breadcrumb.financial.transfers'
    }
  }
})

const routeTransfersRequest = store => ({
  path: 'transfers/:pg_recipient_id/request',
  name: 'transfersRequest',
  component: () =>
    import(/* webpackChunkName: "transfers" */ '@/views/Transfers/index.vue'),
  beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next),
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      current: 'breadcrumb.financial.transfers'
    }
  }
})

const routeTransfersDetail = store => ({
  path: 'transfers/:pg_recipient_id/:transferID',
  name: 'transfersDetail',
  component: () =>
    import(/* webpackChunkName: "transfers" */ '@/views/Transfers/index.vue'),
  beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next),
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      parents: [
        {
          name: 'breadcrumb.financial.transfers',
          routeName: 'transfers'
        }
      ],
      current: 'breadcrumb.financial.detail'
    }
  }
})

const routeBalance = store => ({
  path: 'balance/:pg_recipient_id?',
  alias: ['withdraw/:pg_recipient_id?'],
  name: 'balance',
  component: () =>
    import(/* webpackChunkName: "balance" */ '@/views/Balance/index.vue'),
  beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next),
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      current: 'breadcrumb.financial.balance'
    }
  }
})

const routeSettlement = store => ({
  path: 'settlements/:pg_recipient_id?',
  name: 'settlements',
  component: () =>
    import(
      /* webpackChunkName: "balance" */ '@/views/Recipients/RecipientDetail/RecipientSettlements/index.vue'
    ),
  beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next),
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      current: 'breadcrumb.financial.settlements'
    }
  }
})

const routeBalanceDetails = store => ({
  path: 'balance/:pg_recipient_id/details',
  name: 'balance',
  component: () =>
    import(/* webpackChunkName: "balance" */ '@/views/Balance/index.vue'),
  beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next),
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      parents: [
        {
          name: 'breadcrumb.financial.balance',
          routeName: 'balance'
        }
      ],
      current: 'breadcrumb.financial.detail'
    }
  }
})

const routeAnticipations = store => ({
  path: 'anticipations/:pg_recipient_id?',
  name: 'anticipations',
  component: () =>
    import(
      /* webpackChunkName: "anticipations" */ '@/views/Anticipations/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      current: 'breadcrumb.financial.anticipations'
    }
  }
})

const routeAnticipationRequest = store => ({
  path: 'anticipation/:pg_recipient_id?',
  name: 'anticipationRequest',
  component: () =>
    import(
      /* webpackChunkName: "anticipation-request" */ '@/views/Anticipations/Create/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      parents: [
        {
          name: 'breadcrumb.financial.balance',
          routeName: 'balance'
        }
      ],
      current: 'breadcrumb.financial.request'
    }
  }
})

const routeAnticipationDetails = store => ({
  path: 'anticipations/:pg_recipient_id/:anticipation_id/details',
  name: 'AnticipationsDetails',
  component: () =>
    import(
      /* webpackChunkName: "anticipations-details" */ '@/views/Anticipations/Details/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.financial.root',
      parents: [
        {
          name: 'breadcrumb.financial.anticipations',
          routeName: 'anticipations'
        }
      ],
      current: 'breadcrumb.financial.detail'
    }
  }
})

export default [
  routeTransfers,
  routeTransfersRequest,
  routeTransfersDetail,
  routeBalance,
  routeBalanceDetails,
  routeAnticipations,
  routeAnticipationDetails,
  routeAnticipationRequest,
  routeSettlement
]
