export const USERS_ACTIONS_ENUM = {
  get: 'account_staff:get',
  edit: 'account_staff:edit',
  remove: 'account_staff:remove',
  search: 'account_staff:search'
}

const usersGetEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(USERS_ACTIONS_ENUM.get)
}

const usersEditEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(USERS_ACTIONS_ENUM.edit)
}

const usersRemoveEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(USERS_ACTIONS_ENUM.remove)
}

const usersSearchEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(USERS_ACTIONS_ENUM.search)
}

export {
  usersGetEnabled,
  usersEditEnabled,
  usersSearchEnabled,
  usersRemoveEnabled
}
