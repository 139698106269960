import { ActivitiesInstance, catchError } from '@/store'

const log = {
  state: {
    logActions: { items: [], pagination: {} },
    lastLog: { key: null },
    loadingList: true,
    actions: null,
    filterOptions: [
      {
        name: 'log-actions.filter.action',
        type: 'suggest',
        name_query: 'action',
        select_options: []
      },
      {
        name: 'log-actions.filter.email',
        type: 'email',
        name_query: 'user_email'
      },
      {
        name: 'log-actions.filter.creation-period',
        type: 'select',
        select_options: [
          {
            id: 'specific_date',
            text: 'log-actions.filter.period-specific'
          },
          {
            id: 'interval',
            text: 'log-actions.filter.period-interval'
          }
        ],
        name_query: 'period_type'
      },
      {
        name: 'log-actions.filter.specific-date',
        type: 'date',
        name_query: 'specific_date',
        period_type: 'specific_date'
      },
      {
        name: 'log-actions.filter.initial-date',
        type: 'date',
        name_query: 'since',
        period_type: 'interval'
      },
      {
        name: 'log-actions.filter.final-date',
        type: 'date',
        name_query: 'until',
        period_type: 'interval'
      }
    ]
  },
  mutations: {
    toggleLoadingList (state, status) {
      state.loadingList = status
    },
    SET_LOG_ACTIONS (state, logActions) {
      state.logActions = logActions
    },
    SET_ACTIONS (state, actions) {
      state.actions = actions.sort()
    },
    SET_FILTER_ACTION_OPTIONS (state) {
      const option = state.filterOptions.find(
        option => option.name_query === 'action'
      )
      option.select_options = state.actions
    },
    SET_LAST_LOG (state, lastLog) {
      state.lastLog = lastLog
    }
  },
  getters: {
    logActions (state) {
      return state.logActions
    },
    lastLog (state) {
      return state.lastLog
    },
    loadingListLogs (state) {
      return state.loadingList
    },
    filterOptionsLogs (state) {
      return state.filterOptions
    }
  },
  actions: {
    GET_LOG_ACTIONS (context, params = {}) {
      if (params.query.size) params.query.size = parseInt(params.query.size)
      context.commit('toggleLoadingList', true)
      return ActivitiesInstance.graphql
        .getActionLogs(params.merchantId, params.accountId, params.query)
        .then(res => {
          context.commit('SET_LOG_ACTIONS', res.data.logs)
          context.commit('toggleLoadingList', false)
          Promise.resolve(res.data.logs)
        })
        .catch(err => {
          return catchError(err, context)
        })
    },
    GET_ACTIONS (context) {
      if (!context.state.actions) {
        return ActivitiesInstance.graphql
          .getActions()
          .then(res => {
            const actions = res.data.actions.map(action => action.value)
            context.commit('SET_ACTIONS', actions)
            context.commit('SET_FILTER_ACTION_OPTIONS')
            Promise.resolve(actions)
          })
          .catch(err => catchError(err, context))
      } else {
        Promise.resolve(context.state.actions)
      }
    },
    GET_LAST_LOG (context, params = {}) {
      if (
        context.state.lastLog === null ||
        context.state.lastLog.key !== params.entityId ||
        params.refresh
      ) {
        return ActivitiesInstance.graphql
          .getLastLog(params.entityId)
          .then(res => {
            context.commit('SET_LAST_LOG', res.data.last)
            Promise.resolve(res.data.last)
          })
          .catch(() => {
            const error = {
              key: params.entityId,
              error: true
            }
            context.commit('SET_LAST_LOG', error)
          })
      } else {
        Promise.resolve(context.state.lastLog)
      }
    },
    toggleLoadingList ({ commit }, status) {
      commit('toggleLoadingList', status)
    }
  }
}

export default log
