import {
  invitesSearchEnabled,
  invitesCreateEnabled,
  invitesGetEnabled
} from '@/actions-permission/invites.permissions'

const routeInvites = store => ({
  path: 'invites',
  name: 'invites',
  component: () =>
    import(/* webpackChunkName: "invites" */ '@/views/Team/Invites/Index.vue'),
  beforeEnter: (to, from, next) => {
    if (!invitesSearchEnabled(store.getters.permissionIsEnabled)) {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }

    store.dispatch('GET_INVITES')
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.team.root',
      current: 'breadcrumb.team.invites'
    }
  }
})

const routeInvitesCreate = store => ({
  path: 'invites/create',
  name: 'invites-create',
  component: () =>
    import(
      /* webpackChunkName: "invitescreate" */ '@/views/Team/Invites/InvitesCreate.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (!invitesCreateEnabled(store.getters.permissionIsEnabled)) {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.team.root',
      current: 'breadcrumb.team.invites-create',
      parents: [
        {
          name: 'breadcrumb.team.invites',
          routeName: 'invites'
        }
      ]
    }
  }
})

const routeInvitesDetail = store => ({
  path: 'invites/:id',
  name: 'invitesDetail',
  component: () =>
    import(
      /* webpackChunkName: "invitesdetail" */ '@/views/Team/Invites/InvitesDetail.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (!invitesGetEnabled(store.getters.permissionIsEnabled)) {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.team.root',
      current: 'breadcrumb.team.invites-detail',
      parents: [
        {
          name: 'breadcrumb.team.invites',
          routeName: 'invites'
        }
      ]
    }
  }
})

export default [routeInvites, routeInvitesCreate, routeInvitesDetail]
