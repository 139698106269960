import routeAccountConfig from './account-config.route'
import routeGateways from './gateways.route'
import routeFees from './fees.route'
import routeFunctionalities from './functionalities.route'
import routePaymentMethods from './payment-methods.route'
import routeRecurrence from './recurrence.route'
import routeOrderConfig from './order-config.route'
import routeKeys from './keys.route'
import routeWebhookConfig from './webhook-config.route'
import routeAffiliationsConfig from './affiliations.route'
import { isSettingsSectionEnabled } from '@/actions-permission/settings.permissions'

const routeSettingsRoot = store => ({
  path: '',
  name: 'settings',
  redirect: { name: 'account-config' },
  beforeEnter: (to, from, next) => {
    if (
      !isSettingsSectionEnabled(
        store.getters.permissionIsEnabled,
        store.getters.componentIsEnabled
      )
    ) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  }
})

const routeSettings = store => ({
  path: 'settings/',
  beforeEnter: (to, from, next) => {
    if (
      !isSettingsSectionEnabled(
        store.getters.permissionIsEnabled,
        store.getters.componentIsEnabled
      )
    ) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  component: () => import('@/views/Settings/Settings.vue'),
  children: [
    routeSettingsRoot,
    routeAccountConfig,
    routeGateways,
    routeFees,
    routeFunctionalities,
    routePaymentMethods,
    routeRecurrence,
    routeOrderConfig,
    routeKeys,
    ...routeWebhookConfig,
    routeAffiliationsConfig
  ].map(item => item(store))
})

export default routeSettings
