import { everyActionsPermissionIsEnabled } from '.'

const PHYSICAL_WORLD_ORDERS_ACTIONS_ENUM = {
  search: 'physical_world.orders:search',
  get: 'physical_world.orders:get',
  create: 'physical_world.Orders:create',
  createSwap: 'physical_world.Orders:create_swap'
}

const PHYSICAL_WORLD_TERMINALS_ACTIONS_ENUM = {
  search: 'physical_world.terminals:search',
  get: 'physical_world.terminals:get'
}

const PHYSICAL_WORLD_LIST_ACTIONS = [
  PHYSICAL_WORLD_TERMINALS_ACTIONS_ENUM.search,
  PHYSICAL_WORLD_ORDERS_ACTIONS_ENUM.search
]

const physicalWorldEnabled = permissionIsEnabledFn => {
  return everyActionsPermissionIsEnabled(
    PHYSICAL_WORLD_LIST_ACTIONS,
    permissionIsEnabledFn
  )
}

const physicalWorldCreateOrderEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(PHYSICAL_WORLD_ORDERS_ACTIONS_ENUM.create)
}

// TODO v2
const physicalWorldCreateSwapOrderEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(PHYSICAL_WORLD_ORDERS_ACTIONS_ENUM.createSwap)
}

const physicalWorldGetTerminalEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(PHYSICAL_WORLD_TERMINALS_ACTIONS_ENUM.get)
}

const physicalWorldGetOrderEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(PHYSICAL_WORLD_ORDERS_ACTIONS_ENUM.get)
}

export {
  PHYSICAL_WORLD_ORDERS_ACTIONS_ENUM,
  PHYSICAL_WORLD_TERMINALS_ACTIONS_ENUM,
  physicalWorldEnabled,
  physicalWorldCreateOrderEnabled,
  physicalWorldCreateSwapOrderEnabled,
  physicalWorldGetTerminalEnabled,
  physicalWorldGetOrderEnabled
}
