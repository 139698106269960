import { DashInstance, catchError } from '@/store'

const paymentLinks = {
  state: {
    isLoading: true,
    data: { items: [], pagination: {} },
    isLoadingDetail: true,
    dataDetail: {},
    cancelLoading: false
  },
  mutations: {
    SET_LOADING (state, payload) {
      state.isLoading = payload
    },
    SET_CANCEL_LOADING (state, payload) {
      state.cancelLoading = payload
    },
    SET_PAYMENTS_LINKS (state, payload) {
      state.data = payload
    },
    SET_LOADING_DETAIL (state, payload) {
      state.isLoadingDetail = payload
    },
    SET_PAYMENTS_LINKS_DETAIL (state, payload) {
      state.dataDetail = payload
    }
  },
  getters: {
    PAYMENTS_LINKS_IS_LOADING (state) {
      return state.isLoading
    },
    PAYMENT_LINK_CANCEL_LOADING (state) {
      return state.cancelLoading
    },
    PAYMENTS_LINKS (state) {
      return state.data
    },
    PAYMENTS_LINKS_DETAIL_IS_LOADING (state) {
      return state.isLoadingDetail
    },
    PAYMENTS_LINKS_DETAIL (state) {
      return state.dataDetail
    }
  },
  actions: {
    GET_PAYMENTS_LINKS (context, params) {
      context.commit('SET_LOADING', true)

      if (params?.query?.any_ids) {
        params.query.name = params.query.any_ids
      }

      return DashInstance.account
        .getPaymentsLinks(
          context.getters.merchantId,
          context.getters.accountId,
          params.query
        )
        .then(res => {
          context.commit('SET_PAYMENTS_LINKS', res)
          context.commit('SET_LOADING', false)
          return Promise.resolve(res)
        })
        .catch(err => {
          context.commit('SET_LOADING', false)
          return catchError(err, context)
        })
    },

    GET_PAYMENTS_LINKS_DETAIL (context, id) {
      context.commit('SET_LOADING_DETAIL', true)

      return DashInstance.account
        .getPaymentsLinksDetails(
          context.getters.merchantId,
          context.getters.accountId,
          id
        )
        .then(res => {
          context.commit('SET_PAYMENTS_LINKS_DETAIL', res)
          context.commit('SET_LOADING_DETAIL', false)
          return Promise.resolve(res)
        })
        .catch(err => {
          context.commit('SET_LOADING_DETAIL', false)
          return catchError(err, context)
        })
    },

    CANCEL_PAYMENT_LINK (context, { id, body }) {
      context.commit('SET_CANCEL_LOADING', true)

      return DashInstance.account
        .cancelPaymentLink(
          context.getters.merchantId,
          context.getters.accountId,
          id,
          body
        )
        .then(res => {
          context.commit('SET_CANCEL_LOADING', false)
          return Promise.resolve(res)
        })
        .catch(err => {
          context.commit('SET_CANCEL_LOADING', false)
          return catchError(err, context)
        })
    }
  }
}

export default paymentLinks
