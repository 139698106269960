const routeInvoices = store => ({
  path: 'invoices/',
  name: 'invoices',
  component: () => import('@/views/Recurrences/Invoices/Invoices.vue'),
  beforeEnter: (to, from, next) => {
    if (
      store.getters.permissionIsEnabled('invoice:search') &&
      store.getters.componentIsEnabled('sidemenu', 'invoices')
    ) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.recurrence.root',
      current: 'breadcrumb.recurrence.invoices'
    }
  }
})

const routeInvoicesDetail = store => ({
  path: 'invoices/:id',
  name: 'invoicesDetail',
  component: () =>
    import(
      /* webpackChunkName: "invoicesdetail" */ '@/views/Recurrences/Invoices/InvoicesDetail.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (
      store.getters.permissionIsEnabled('invoice:get') &&
      store.getters.componentIsEnabled('sidemenu', 'invoices')
    ) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.recurrence.root',
      current: 'breadcrumb.recurrence.invoices-detail',
      parents: [
        { name: 'breadcrumb.recurrence.invoices', routeName: 'invoices' }
      ]
    }
  }
})

export default [routeInvoices, routeInvoicesDetail]
