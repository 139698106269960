import packagejson from '../../package.json'
import { datadogRum } from '@datadog/browser-rum'

/**
 * Create Datadog DataLayer
 *
 * @param {number} id user Id
 * @param {string} name user first name & user last name
 * @param {string} email user email
 * @param {string} role user role
 * @param {string} businessModel acc business model
 * @param {string} accountId account id
 * @param {string} merchantId merchant id
 */

export function datadogInitialize () {
  if (process.env.VUE_APP_DATADOG_ENABLE !== 'false') {
    datadogRum.setUser(JSON.parse(localStorage.getItem('_dd_data_layer')))
    datadogRum.init({
      applicationId: process.env.VUE_APP_DATADOG_APP_ID,
      clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
      service: 'dash-webapp',
      env: process.env.VUE_APP_AMBIENCE,
      version: packagejson.version,
      sessionSampleRate: 10,
      sessionReplaySampleRate: 5,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input'
    })
    datadogRum.startSessionReplayRecording()
  }
}
