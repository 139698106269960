import { isSettingsFunctionalitiesEnabled } from '@/actions-permission/settings.permissions'

const routeFunctionalities = store => ({
  path: 'functionalities',
  name: 'functionalities',
  component: () =>
    import(
      /* webpackChunkName: "functionalities" */ '@/views/Settings/Functionalities.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (!isSettingsFunctionalitiesEnabled(store.getters.permissionIsEnabled)) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.settings.root',
      current: 'breadcrumb.settings.functionalities'
    }
  }
})

export default routeFunctionalities
