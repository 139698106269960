import {
  physicalWorldCreateOrderEnabled,
  physicalWorldGetTerminalEnabled,
  physicalWorldGetOrderEnabled,
  physicalWorldEnabled
} from '@/actions-permission/physical-world.permissions'

import { TERMINAL_ROUTES_ENUM } from '../enums/physical-world.enum'

const routeServiceOrders = store => [
  {
    path: 'service-orders',
    name: 'service-orders',
    component: () =>
      import(
        /* webpackChunkName: "example" */ '@/views/PhysicalWorld/ServiceOrders/ServiceOrders.vue'
      ),
    beforeEnter: (to, from, next) => {
      if (!physicalWorldEnabled(store.getters.permissionIsEnabled)) {
        return store.commit('toggleGlobalError', { status: true, code: 404 })
      }
      const params = {
        accountId: to.params.account_id,
        merchantId: to.params.merchant_id
      }
      store.dispatch('GET_PHYSICAL_WORLD_ORDERS', params).then(() => {
        next()
      })
    },
    meta: {
      navigationBar: {
        label: 'breadcrumb.physical-world.service-orders'
      }
    }
  },
  {
    path: 'service-orders/:id',
    name: 'serviceOrdersDetail',
    component: () =>
      import(
        /* webpackChunkName: "ServiceOrderDetails" */ '@/views/PhysicalWorld/ServiceOrders/ServiceOrdersDetails.vue'
      ),
    beforeEnter: (to, from, next) => {
      if (!physicalWorldGetOrderEnabled(store.getters.permissionIsEnabled)) {
        return store.commit('toggleGlobalError', { status: true, code: 404 })
      }
      const params = {
        id: to.params.id,
        accountId: to.params.account_id,
        merchantId: to.params.merchant_id
      }
      store
        .dispatch('GET_PHYSICAL_WORLD_ORDER_BY_ID', params)
        .then(() => next())
    },
    meta: {
      navigationBar: {
        label: 'breadcrumb.physical-world.service-orders-detail',
        navigateTo: () => ({
          name: 'service-orders'
        })
      }
    }
  }
]

const routePhysicalWorld = store => ({
  path: 'physical-world/',
  beforeEnter: (to, from, next) => {
    if (!physicalWorldEnabled(store.getters.permissionIsEnabled)) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  component: () =>
    import(
      /* webpackChunkName: "physical-world/index" */ '@/views/PhysicalWorld/index.vue'
    ),
  children: [
    {
      path: '',
      name: 'physical-world',
      redirect: { name: TERMINAL_ROUTES_ENUM.TERMINALS },
      beforeEnter: (to, from, next) => {
        if (!physicalWorldEnabled(store.getters.permissionIsEnabled)) {
          return store.commit('toggleGlobalError', { status: true, code: 404 })
        }
        next()
      }
    },
    {
      path: 'terminals',
      name: TERMINAL_ROUTES_ENUM.TERMINALS,
      component: () =>
        import(
          /* webpackChunkName: "example" */ '@/views/PhysicalWorld/Terminals/Terminals.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (!physicalWorldEnabled(store.getters.permissionIsEnabled)) {
          return store.commit('toggleGlobalError', { status: true, code: 404 })
        }
        next()
      },
      meta: {
        navigationBar: {
          label: 'breadcrumb.physical-world.terminals'
        }
      }
    },
    {
      path: 'terminals/request',
      name: TERMINAL_ROUTES_ENUM.TERMINAL_REQUEST,
      component: () =>
        import(
          /* webpackChunkName: "terminalDetails" */ '@/views/PhysicalWorld/Terminals/TerminalRequest.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (
          !physicalWorldCreateOrderEnabled(store.getters.permissionIsEnabled)
        ) {
          return store.commit('toggleGlobalError', { status: true, code: 404 })
        }
        next()
      },
      meta: {
        navigationBar: {
          label: 'breadcrumb.physical-world.terminals-request',
          navigateTo: () => ({
            name: TERMINAL_ROUTES_ENUM.TERMINALS
          })
        }
      }
    },
    {
      path: 'terminals/roll-request',
      name: TERMINAL_ROUTES_ENUM.TERMINAL_ROLL_REQUEST,
      component: () =>
        import(
          /* webpackChunkName: "terminalDetails" */ '@/views/PhysicalWorld/Terminals/TerminalRollRequest.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (
          !physicalWorldCreateOrderEnabled(store.getters.permissionIsEnabled)
        ) {
          return store.commit('toggleGlobalError', { status: true, code: 404 })
        }
        next()
      },
      meta: {
        breadcrumb: {
          refer: 'breadcrumb.physical-world.root',
          parents: [
            {
              name: 'breadcrumb.physical-world.terminals',
              routeName: TERMINAL_ROUTES_ENUM.TERMINALS
            }
          ],
          current: 'breadcrumb.physical-world.terminals-rollrequest'
        }
      }
    },
    {
      path: 'terminals/:id',
      name: TERMINAL_ROUTES_ENUM.TERMINAL_DETAILS,
      component: () =>
        import(
          /* webpackChunkName: "terminalDetails" */ '@/views/PhysicalWorld/Terminals/TerminalDetails.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (
          !physicalWorldGetTerminalEnabled(store.getters.permissionIsEnabled)
        ) {
          return store.commit('toggleGlobalError', { status: true, code: 404 })
        }
        const params = {
          query: {
            serialNumber: to.params.id,
            accountId: to.params.account_id,
            merchantId: to.params.merchant_id
          }
        }
        store.dispatch('GET_TERMINAL_BY_SERIAL_NUMBER', params).then(() => {
          next()
        })
      },
      meta: {
        navigationBar: {
          label: 'breadcrumb.physical-world.terminals-details',
          navigateTo: () => ({
            name: TERMINAL_ROUTES_ENUM.TERMINALS
          })
        }
      }
    },
    {
      path: 'terminals/uninstall/:serialNumber',
      name: TERMINAL_ROUTES_ENUM.TERMINAL_UNINSTALL,
      component: () =>
        import(
          /* webpackChunkName: "terminalDetails" */ '@/views/PhysicalWorld/Terminals/TerminalUninstallRequest.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (
          !physicalWorldCreateOrderEnabled(store.getters.permissionIsEnabled)
        ) {
          return store.commit('toggleGlobalError', { status: true, code: 404 })
        }
        const params = {
          query: {
            serialNumber: to.params.serialNumber,
            accountId: to.params.account_id,
            merchantId: to.params.merchant_id
          }
        }
        store.dispatch('GET_TERMINAL_BY_SERIAL_NUMBER', params).then(() => {
          next()
        })
      },
      meta: {
        breadcrumb: {
          refer: 'breadcrumb.physical-world.root',
          parents: [
            {
              name: 'breadcrumb.physical-world.terminals-details',
              routeName: TERMINAL_ROUTES_ENUM.TERMINAL_DETAILS
            }
          ],
          current: 'breadcrumb.physical-world.terminals-uninstall'
        }
      }
    },
    ...routeServiceOrders(store)
  ]
})

export default routePhysicalWorld
