import {
  paymentsLinksViewEnabled,
  paymentsLinksDetailEnabled
} from '@/actions-permission/payment-links.permissions'

const routePaymentsLinks = store => ({
  path: 'payment-links',
  name: 'paymentLinks',
  component: () =>
    import(
      /* webpackChunkName: "paymentLinks" */ '@/views/PaymentLinks/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (
      !paymentsLinksViewEnabled(
        store.getters.permissionIsEnabled,
        store.getters.isCheckoutV2Enabled
      )
    ) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.sales.root',
      current: 'breadcrumb.sales.payment-links'
    }
  }
})

const routePaymentsLinksDetail = store => ({
  path: 'payment-links/:id',
  name: 'paymentLinksDetail',
  component: () =>
    import(
      /* webpackChunkName: "paymentLinksDetail" */ '@/views/PaymentLinks/Details/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (
      !paymentsLinksDetailEnabled(
        store.getters.permissionIsEnabled,
        store.getters.isCheckoutV2Enabled
      )
    ) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }

    store.dispatch('GET_PAYMENTS_LINKS_DETAIL', to.params.id)
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.sales.root',
      current: 'breadcrumb.sales.payment-links-detail',
      parents: [
        {
          name: 'breadcrumb.sales.payment-links',
          routeName: 'paymentLinks'
        }
      ]
    }
  }
})

export default [routePaymentsLinks, routePaymentsLinksDetail]
