import { isSettingsFeesEnabled } from '@/actions-permission/settings-account.permission'

const routeFees = store => ({
  path: 'fees',
  name: 'fees',
  component: () =>
    import(/* webpackChunkName: "webhookconfig" */ '@/views/Settings/Fees'),
  beforeEnter: (to, from, next) => {
    if (
      !isSettingsFeesEnabled(
        store.getters.permissionIsEnabled,
        store.getters.businessModel,
        store.getters.account.account_type.value
      )
    ) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.settings.root',
      current: 'breadcrumb.settings.fees'
    }
  }
})

export default routeFees
