import insightsMerchantEnabled from '@/actions-permission/insights.permissions'
import { isSellerUserRole, isSalesUserRole } from '@/enums/user-role.enum'

const routeHome = store => ({
  path: '/',
  name: 'home',
  component: () =>
    import(/* webpackChunkName: "home" */ '@/views/Home/index.vue'),
  beforeEnter: (to, from, next) => {
    const { permissionIsEnabled, componentIsEnabled } = store.getters
    const { merchant_id, account_id } = to.params

    if (isSellerUserRole(store.getters.userRole.key)) {
      // eslint-disable-next-line
      next({ path: `/${merchant_id}/${account_id}/payment-links` })
      return
    }

    if (isSalesUserRole(store.getters.userRole.key)) {
      // eslint-disable-next-line
      next({ path: `/${merchant_id}/${account_id}/orders` })
      return
    }

    if (!insightsMerchantEnabled(permissionIsEnabled, componentIsEnabled)) {
      // eslint-disable-next-line
      next({ path: `/${merchant_id}/${account_id}/charges` })
      return
    }

    const params = {
      merchant_id,
      account_id,
      query: to?.query ?? {}
    }

    // O next não é feito no then da request, pois aumenta o tempo da primeira interação do usuário.
    store.dispatch('GET_DATA_HOME', params)

    next()
  },
  meta: {
    navigationBar: {
      label: 'breadcrumb.home',
      navigationAction: () => ({
        component: () => import('@/views/Home/HomeNavigationBarActions.vue')
      })
    }
  }
})

export default routeHome
