function handlePathParams (pathParams = '') {
  if (!pathParams) return pathParams

  return `/${pathParams}`
}

function buildQueryString (queryString = '') {
  if (!Object.keys(queryString).length) return queryString

  return `?${new URLSearchParams(queryString)}`
}

export default function buildUrl ({
  url = '',
  pathParams = '',
  queryString = ''
}) {
  if (!url) throw new Error('Missing URL')

  return `${url}${handlePathParams(pathParams)}${buildQueryString(queryString)}`
}
