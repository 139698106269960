import { DashInstance, catchError } from '@/store'

const trf = {
  actions: {
    GET_TRFS (context, params = {}) {
      return DashInstance.trf
        .getAll(params.merchantId, params.accountId, params.query)
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    CREATE_TRF (context, params = {}) {
      return DashInstance.trf
        .create(params.merchantId, params.accountId, params.body)
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    }
  }
}

export default trf
