export const CREDIT_CARD_ENUM = {
  struct: {
    CARTAO_DE_CREDITO: 'Cartão de crédito',
    CREDIT_CARD: 'Credit card'
  },
  TYPE: 'credit_card',
  list: () => Object.values(CREDIT_CARD_ENUM.struct)
}

export const DEBIT_CARD_ENUM = {
  struct: {
    CARTAO_DE_DEBITO: 'Cartão de débito',
    DEBIT_CARD: 'Debit card'
  },
  TYPE: 'debit',
  list: () => Object.values(DEBIT_CARD_ENUM.struct)
}

export const BANK_TRANSFER_ENUM = {
  struct: {
    TRANSFERENCIA_BANCARIA: 'Transferência Bancária',
    BANK_TRANSFER: 'Bank Transfer'
  },
  TYPE: 'bank_transfer',
  list: () => Object.values(BANK_TRANSFER_ENUM.struct)
}

export const SAFETY_PAY_ENUM = {
  struct: {
    SAFETY_PAY: 'SafetyPay'
  },
  TYPE: 'safety_pay',
  list: () => Object.values(SAFETY_PAY_ENUM.struct)
}

export const PRIVATE_LABEL_ENUM = {
  struct: {
    PRIVATE_LABEL: 'Private Label'
  },
  TYPE: 'private_label',
  list: () => Object.values(PRIVATE_LABEL_ENUM.struct)
}

export const PREPAID_ENUM = {
  struct: {
    PREPAID: 'prepaid',
    PREPAGO: 'Pré-pago'
  },
  list: () => Object.values(PREPAID_ENUM.struct)
}

export const PAYMENT_METHOD_ENUM = {
  ...CREDIT_CARD_ENUM.struct,
  ...DEBIT_CARD_ENUM.struct,
  ...BANK_TRANSFER_ENUM.struct,
  SAFETY_PAY: SAFETY_PAY_ENUM.struct.SAFETY_PAY,
  PRIVATE_LABEL: PRIVATE_LABEL_ENUM.struct.PRIVATE_LABEL,
  VOUCHER: 'Voucher',
  BOLETO: 'Boleto',
  CASH: 'Cash',
  PIX: 'Pix'
}

export const isBoleto = paymentMethod =>
  paymentMethod.toLowerCase() === PAYMENT_METHOD_ENUM.BOLETO.toLowerCase()

export const isPix = paymentMethod =>
  paymentMethod.toLowerCase() === PAYMENT_METHOD_ENUM.PIX.toLowerCase()

export const isCash = paymentMethod =>
  paymentMethod.toLowerCase() === PAYMENT_METHOD_ENUM.CASH.toLowerCase()

export const isSafetyPay = paymentMethod =>
  paymentMethod.toLowerCase() === PAYMENT_METHOD_ENUM.SAFETY_PAY.toLowerCase()

export const isPrivateLabel = paymentMethod =>
  paymentMethod.toLowerCase() ===
  PAYMENT_METHOD_ENUM.PRIVATE_LABEL.toLowerCase()

export const isVoucher = paymentMethod =>
  paymentMethod.toLowerCase() === PAYMENT_METHOD_ENUM.VOUCHER.toLowerCase()

export const isCreditCard = paymentMethod =>
  CREDIT_CARD_ENUM.list().includes(paymentMethod)

export const isDebitCard = paymentMethod =>
  DEBIT_CARD_ENUM.list().includes(paymentMethod)

export const isBankTransfer = paymentMethod =>
  BANK_TRANSFER_ENUM.list().includes(paymentMethod)

export const isPrepaid = charge => {
  if (!charge) return false
  return PREPAID_ENUM.list().some(el => Object.values(charge).includes(el))
}

export const isPixByType = paymentMethod =>
  paymentMethod.includes(PAYMENT_METHOD_ENUM.PIX.toLowerCase())

export const isBoletoByType = paymentMethod =>
  paymentMethod.includes(PAYMENT_METHOD_ENUM.BOLETO.toLowerCase())

export const isDebitByType = paymentMethod =>
  paymentMethod.includes(DEBIT_CARD_ENUM.TYPE)

export const isCreditCardByType = paymentMethod =>
  paymentMethod.includes(CREDIT_CARD_ENUM.TYPE)

// Cartão de Crédito | Cartão de Débito | Voucher | Private Label
export const isCardsOrVoucher = paymentMethod =>
  [
    ...CREDIT_CARD_ENUM.list(), //
    ...DEBIT_CARD_ENUM.list(),
    PAYMENT_METHOD_ENUM.VOUCHER,
    PAYMENT_METHOD_ENUM.PRIVATE_LABEL
  ].includes(paymentMethod)

export default {
  PAYMENT_METHOD_ENUM,
  CREDIT_CARD_ENUM,
  DEBIT_CARD_ENUM,
  BANK_TRANSFER_ENUM,
  isBoleto,
  isPix,
  isCash,
  isSafetyPay,
  isPrivateLabel,
  isVoucher,
  isCreditCard,
  isDebitCard,
  isBankTransfer,
  isCardsOrVoucher,
  isPrepaid,
  isCreditCardByType,
  isPixByType,
  isDebitByType
}
