import { isSettingsAccountEnabled } from '@/actions-permission/settings-account.permission'

const routeAccountConfig = store => ({
  path: 'account-config',
  name: 'account-config',
  component: () => import('@/views/Settings/AccountConfig/index.vue'),
  beforeEnter: (to, from, next) => {
    if (!isSettingsAccountEnabled(store.getters.permissionIsEnabled)) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    const params = {
      accountId: to.params.account_id,
      merchantId: to.params.merchant_id
    }
    store.dispatch('GET_ACCOUNT', params).then(() => {
      next()
    })
  },
  meta: {
    navigationBar: {
      label: 'breadcrumb.settings.account',
      AppNavigationBar: () => ({
        component: () =>
          import('@/components/project/ui/AppNavigationBar/index.vue')
      })
    }
  }
})

export default routeAccountConfig
