import { DashInstance, catchError } from '@/store'

const shop = {
  state: {
    orderIsEnabled: null,
    subscriptionIsEnabled: null,
    hasShopUnavailable: null,
    isCheckoutV2Enabled: false
  },
  getters: {
    orderIsEnabled (state) {
      return state.orderIsEnabled
    },
    subscriptionIsEnabled (state) {
      return state.subscriptionIsEnabled
    },
    hasShopUnavailable (state) {
      return state.hasShopUnavailable
    },
    isCheckoutV2Enabled (state) {
      return state.isCheckoutV2Enabled
    }
  },
  mutations: {
    SET_ORDER_ENABLED (state, value) {
      state.orderIsEnabled = value
    },
    SET_SUBSCRIPTION_ENABLED (state, value) {
      state.subscriptionIsEnabled = value
    },
    SET_SHOP_UNAVAILABLE (state, value) {
      state.hasShopUnavailable = value
    },
    SET_CHECKOUT_V2_ENABLED (state, value) {
      state.isCheckoutV2Enabled = value
    }
  },
  actions: {
    // GET SHOP INFOS
    GET_ACCOUNT_SUMMARY (context, params) {
      return DashInstance.shop
        .getAccountSummary(params.merchant_id, params.account_id)
        .then(res => {
          context.commit('SET_SHOP_UNAVAILABLE', false)
          context.commit('SET_ORDER_ENABLED', res.has_open_orders_enabled)
          context.commit('SET_CHECKOUT_V2_ENABLED', res.checkout_v2_enabled)
          context.commit(
            'SET_SUBSCRIPTION_ENABLED',
            res.has_subscription_enabled
          )
          Promise.resolve(res)
        })
        .catch(err => {
          if (err.status === 428 || err.status === 403) {
            context.commit('SET_SHOP_UNAVAILABLE', true)
          } else if (!err.status || err.status === 500 || err.status === 400) {
            // tratamento paliativo para o erro causado no shop
            // eslint-disable-next-line
            console.error('err status: ', err.status)
            context.commit('SET_SHOP_UNAVAILABLE', true)
          } else {
            return catchError(err, context)
          }
        })
    },
    RESET_ACCOUNT_ENABLED (context) {
      context.commit('SET_ORDER_ENABLED', null)
      context.commit('SET_SUBSCRIPTION_ENABLED', null)
    }
  }
}
export default shop
