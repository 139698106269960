import { isGatewayOrHybrid } from '@/enums/business-model.enum'

export const AFFILIATION_ACTIONS_ENUM = {
  create: 'settings.payments.acquirer:create',
  edit: 'settings.payments.acquirer.enable:edit',
  get: 'settings.payments.acquirer:get'
}

const affiliationSettingsEnabled = (permissionIsEnabledFn, businessModel) => {
  return (
    permissionIsEnabledFn(AFFILIATION_ACTIONS_ENUM.get) &&
    isGatewayOrHybrid(businessModel)
  )
}

const affiliationSettingsCreateEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(AFFILIATION_ACTIONS_ENUM.create)
}

const affiliationSettingsEditEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(AFFILIATION_ACTIONS_ENUM.edit)
}

export {
  affiliationSettingsEnabled,
  affiliationSettingsCreateEnabled,
  affiliationSettingsEditEnabled
}
