const filters = [
  // {
  //   name: 'order.filter.id',
  //   type: 'text',
  //   name_query: 'ids'
  // },
  // {
  //   name: 'order.filter.code',
  //   type: 'text',
  //   name_query: 'codes'
  // },
  {
    name: 'order.filter.status',
    type: 'select',
    select_options: [
      {
        id: 'pending',
        text: 'order.filter.pending'
      },
      {
        id: 'paid',
        text: 'order.filter.paid'
      },
      {
        id: 'canceled',
        text: 'order.filter.canceled'
      },
      {
        id: 'failed',
        text: 'order.filter.failed'
      }
    ],
    name_query: 'status'
  },
  {
    name: 'order.filter.amount',
    type: 'currency',
    name_query: 'min_amount'
  } //,
  // {
  //   name: 'order.filter.creation-period',
  //   type: 'select',
  //   select_options: [{
  //     id: 'specific_date',
  //     text: 'order.filter.period-specific'
  //   },
  //   {
  //     id: 'date_filters',
  //     text: 'order.filter.period-predefined'
  //   },
  //   {
  //     id: 'interval',
  //     text: 'order.filter.period-interval'
  //   }],
  //   name_query: 'period_type'
  // },
  // {
  //   name: 'order.filter.specific-date',
  //   type: 'date',
  //   name_query: 'specific_date',
  //   period_type: 'specific_date'
  // },
  // {
  //   name: 'order.filter.predefined-date',
  //   type: 'select',
  //   select_options: [{
  //     id: 'Today',
  //     text: 'order.filter.predefined-today'
  //   },
  //   {
  //     id: 'Yesterday',
  //     text: 'order.filter.predefined-yesterday'
  //   },
  //   {
  //     id: 'SevenDays',
  //     text: 'order.filter.predefined-7-days'
  //   },
  //   {
  //     id: 'FifteenDays',
  //     text: 'order.filter.predefined-15-days'
  //   },
  //   {
  //     id: 'OneMonth',
  //     text: 'order.filter.predefined-30-days'
  //   }],
  //   name_query: 'date_filters',
  //   period_type: 'date_filters'
  // },
  // {
  //   name: 'order.filter.initial-date',
  //   type: 'date',
  //   name_query: 'since',
  //   period_type: 'interval'
  // },
  // {
  //   name: 'order.filter.final-date',
  //   type: 'date',
  //   name_query: 'until',
  //   period_type: 'interval'
  // }
]
export default filters
