import { affiliationSettingsEnabled } from '@/actions-permission/affiliations-settings.permissions'

const routeAffiliationsConfig = store => ({
  path: 'affiliations',
  name: 'affiliations',
  component: () => import('@/views/Settings/Affiliations/index.vue'),
  beforeEnter: (to, from, next) => {
    if (
      !affiliationSettingsEnabled(
        store.getters.permissionIsEnabled,
        store.getters.businessModel
      )
    ) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }

    next()
  },
  meta: {
    breadcrumb: {
      refer: 'breadcrumb.settings.root',
      current: 'breadcrumb.settings.affiliations'
    }
  }
})

export default routeAffiliationsConfig
