export const TODAY = {
  id: 'Today',
  text: 'today'
}

export const YESTERDAY = {
  id: 'Yesterday',
  text: 'yesterday'
}

export const SEVEN_DAYS = {
  id: 'SevenDays',
  text: 'last-7-days'
}

export const FIFTEEN_DAYS = {
  id: 'FifteenDays',
  text: 'last-15-days'
}

export const ONE_MONTH = {
  id: 'OneMonth',
  text: 'last-30-days'
}

export const INTERVAL = {
  id: 'interval',
  text: 'interval'
}

export const SPECIFIC_DATE = {
  id: 'specific_date',
  text: 'specific'
}

export const ALL_TIME = {
  id: null,
  text: 'all-time'
}

export const DATE_PICKER_OPTIONS = {
  TODAY,
  YESTERDAY,
  SEVEN_DAYS,
  FIFTEEN_DAYS,
  ONE_MONTH,
  ALL_TIME,
  INTERVAL,
  SPECIFIC_DATE,

  DEFAULT: [
    TODAY,
    YESTERDAY,
    SEVEN_DAYS,
    FIFTEEN_DAYS,
    ONE_MONTH,
    ALL_TIME,
    INTERVAL,
    SPECIFIC_DATE
  ],

  NON_ALL_TIME: [
    TODAY,
    YESTERDAY,
    SEVEN_DAYS,
    FIFTEEN_DAYS,
    ONE_MONTH,
    INTERVAL,
    SPECIFIC_DATE
  ]
}

export default {
  TODAY,
  YESTERDAY,
  SEVEN_DAYS,
  FIFTEEN_DAYS,
  ONE_MONTH,
  ALL_TIME,
  INTERVAL,
  SPECIFIC_DATE,

  DATE_PICKER_OPTIONS
}
