import Vue from 'vue'
import VueGtag from 'vue-gtag'
import VueTheMask from 'vue-the-mask'
import Vuelidate from 'vuelidate'
import VueCookies from 'vue-cookies'
import money from 'v-money'
import VueClipboard from 'vue-clipboard2'
import * as FullStory from '@fullstory/browser'
import { sanitizeUrl } from '@braintree/sanitize-url'

import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import getGoogleAnalyticsSettings from './utils/googleAnalytics.utils'
import { dateFormat } from './filters'
import './plugins/click-outside.plugins'
import './plugins/scroll-lock.plugins'

import './styles.global.css'
// Jade
import './components/jade/css/index.css'
import '@stone-payments/jade-design-tokens/pagarme/theme.css'
// import '@stone-payments/jade-design-tokens/pagarme/fonts.css'

// datepicker
import 'vanillajs-datepicker/css/datepicker.css'

// emerald
import 'emerald-workbench/dist/emerald.pagarme.min.css'

if (['pagarme'].includes(process.env.VUE_APP_THEME)) {
  // Vue requires the use of a polyfill to correctly handle the shadow dow,
  // which is required to encapsulate styles in the micro frontend architecture
  const shadow = require('vue-shadow-dom')
  Vue.use(shadow)

  // start listening for route changes in order to render micro-frontend bundles
  const { start } = require('single-spa')
  start()
}

Vue.config.productionTip = false

if (['production'].includes(process.env.VUE_APP_CORE_ENV)) {
  FullStory.init({ orgId: 'BKP05' })
  Vue.prototype.$FullStory = FullStory
  const googleAnalyticsSettings = getGoogleAnalyticsSettings()
  Vue.use(VueGtag, googleAnalyticsSettings, router)
}

Vue.use(VueTheMask)
Vue.use(Vuelidate)
Vue.use(VueCookies)
Vue.use(money, { precision: 4 })
Vue.filter('dateFormat', dateFormat)
Vue.use(VueClipboard)
Vue.prototype.$sanitize = sanitizeUrl

const signalR = require('@aspnet/signalr/dist/browser/signalr.min.js')
Vue.prototype.$signalR = signalR

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
