const routeCustomer = store => ({
  path: 'customers/',
  name: 'customers',
  component: () =>
    import(
      /* webpackChunkName: "Customers" */ '@/views/Customers/Customers.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (!store.getters.permissionIsEnabled('customer:search')) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      current: 'breadcrumb.customers.root'
    }
  }
})

const routeCustomerDetail = store => ({
  path: 'customers/:id/:tab',
  name: 'customersDetail',
  component: () =>
    import(
      /* webpackChunkName: "customersdetail" */ '@/views/Customers/CustomersDetail.vue'
    ),
  beforeEnter: (to, from, next) => {
    const permissions = {
      info: 'customer:get',
      orders: 'order:search',
      charges: 'charge:search',
      invoices: 'invoice:search',
      subscriptions: 'subscription:search',
      cards: 'customer.cards:get'
    }
    if (!store.getters.permissionIsEnabled(permissions[to.params.tab])) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    breadcrumb: {
      parents: [
        {
          name: 'breadcrumb.customers.root',
          routeName: 'customers'
        }
      ],
      current: 'breadcrumb.customers.detail'
    }
  }
})

export default [routeCustomer, routeCustomerDetail]
