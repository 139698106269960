function everyActionsPermissionIsEnabled (
  actions = [],
  permissionIsEnabledFn = undefined
) {
  if (!permissionIsEnabledFn) {
    throw new Error('Missing function permissionIsEnabled')
  }

  return actions.every(permissionIsEnabledFn)
}

function someActionsPermissionIsEnabled (
  actions = [],
  permissionIsEnabledFn = undefined
) {
  if (!permissionIsEnabledFn) {
    throw new Error('Missing function permissionIsEnabled')
  }

  return actions.some(permissionIsEnabledFn)
}

export { everyActionsPermissionIsEnabled, someActionsPermissionIsEnabled }
